import axios from 'axios'
import * as Config from '../../config'
import Cookies from 'js-cookie'

const state = {
  ubicationevent: {},
  ubicationevents: {}
}

const actions = {

    loadUbicationEvents ({ commit, dispatch }) {
    commit('LOAD_UBICATIONS')

    return new Promise((resolve, reject) => {
      axios.get(Config.apiPath + 'ubicationevent/active')
      .then(
        response => {
          if (response.data.code !== 500) {
            commit('LOAD_UBICATIONS_OK', response.data.data)
            resolve(response.data.data)
          } else {
            commit('LOAD_UBICATIONS_FAIL')
            reject()
          }
        })
      .catch(error => {
        commit('LOAD_UBICATIONS_FAIL')
        reject()
      })
    })
  },
  storeUbicationEvent ({ commit, dispatch }, form) {
    commit('STORE_UBICATION')

    return new Promise((resolve, reject) => {
      axios.post(Config.apiPath + 'ubicationevent', form)
      .then(
        response => {
          commit('STORE_UBICATION_OK')
          resolve(response.data)
        })
      .catch(error => {
        commit('STORE_UBICATION_FAIL')
        reject(error.response.data)
      })
    })
  },
  updateUbicationEvent ({ commit, dispatch }, form) {
    commit('UPDATE_UBICATION')

    return new Promise((resolve, reject) => {
      axios.put(Config.apiPath + 'ubicationevent/' + form.id, form)
      .then(
        response => {
          commit('UPDATE_UBICATION_OK')
          resolve(response.data)
        })
      .catch(error => {
        commit('UPDATE_UBICATION_FAIL')
        reject(error.response.data)
      })
    })
  },
  deleteUbicationEvent ({ commit, dispatch }, form) {
    commit('DELETE_UBICATION')

    return new Promise((resolve, reject) => {
      axios.delete(Config.apiPath + 'ubicationevent/delete/' + form.id, form)
      .then(
        response => {
          commit('DELETE_UBICATION_OK')
          resolve(response.data)
        })
      .catch(error => {
        commit('DELETE_UBICATION_FAIL')
        reject(error.response.data)
      })
    })
  },
  loadUbicationEvent ({ commit, dispatch }, form) {
    commit('LOAD_UBICATION')

    return new Promise((resolve, reject) => {
      axios.get(Config.apiPath + 'ubicationevent/' + form, { form })
      .then(
        response => {
          if (response.data.code !== 500) {
            commit('LOAD_UBICATION_OK', response.data.data)
            resolve(response.data.data)
          } else {
            commit('LOAD_UBICATION_FAIL')
            reject()
          }
        })
      .catch(error => {
        commit('LOAD_UBICATION_FAIL')
        reject()
      })
    })
  },
  loadUbicationEventByEvent ({ commit, dispatch }, idevent) {
    commit('LOAD_UBICATIONS_BY_EVENT')

    return new Promise((resolve, reject) => {
      axios.get(Config.apiPath + 'ubicationevent/filterby/id_event/' + idevent)
      .then(
        response => {
          if (response.data.code !== 500) {
            commit('LOAD_UBICATIONS_BY_EVENT_OK', response.data.data)
            resolve(response.data.data)
          } else {
            commit('LOAD_UBICATIONS_BY_EVENT_FAIL')
            reject()
          }
        })
      .catch(error => {
        commit('LOAD_UBICATIONS_BY_EVENT_FAIL')
        reject()
      })
    })
  },
  loadTypeUbicationEvent ({ commit, dispatch }) {
    commit('LOAD_TYPE_UBICATION')

    return new Promise((resolve, reject) => {
      axios.get(Config.apiPath + 'type/ubicationevent/active')
      .then(
        response => {
          if (response.data.code !== 500) {
            commit('LOAD_TYPE_UBICATION_OK')
            resolve(response.data.data)
          } else {
            commit('LOAD_TYPE_UBICATION_FAIL')
            reject()
          }
        })
      .catch(error => {
        commit('LOAD_TYPE_UBICATION_FAIL')
        reject()
      })
    })
  },
  activeChangeUbicationEvent({commit, dispatch}, id) {
    commit('CHANGUE_UBICATION')
    return new Promise((resolve, reject) => {
      axios.post(Config.apiPath + 'ubicationevent/change/active/' + id)
      .then(
        response => {
          commit('CHANGUE_UBICATION_OK')
          resolve(response.data)
        })
      .catch(error => {
        commit('CHANGUE_UBICATION_FAIL')
        reject(error.response.data)
      })
    })
  }

}

const mutations = {
  LOAD_UBICATION_OK (state, ubicationevents) {
    state.ubicationevent = ubicationevents
  },
  LOAD_UBICATIONS_OK (state, ubicationevents) {
    state.ubicationevents = ubicationevents
  },
  LOAD_UBICATIONS_BY_COMPANY_OK (state, ubicationevents) {
    state.ubicationevents = ubicationevents
  }

}

export default {
  state,
  actions,
  mutations
}
