import axios from 'axios'
import * as Config from '../../config'
import Cookies from "js-cookie"

const state = {
    typektag: {},   
    typektags:{}
}

const actions = {

   
    loadTypektags({commit, dispatch}, params) {
        commit('LOAD_TYPE_KTAGS')

        return new Promise((resolve, reject) => {
            axios.get(Config.apiPath + 'type/ktag', {params})
                .then(
                    response => {
                        if (response.data.code !== 500) {
                            commit('LOAD_TYPE_KTAGS_OK', response.data.data)
                            resolve(response.data.data)
                        } else {
                            commit('LOAD_TYPE_KTAGS_FAIL')
                            reject()
                        }

                    })
                .catch(error => {
                    commit('LOAD_TYPE_KTAGS_FAIL')
                    reject()
                })
        })
    },
    loadTypektagsActive({commit, dispatch}, params) {
        commit('LOAD_TYPE_KTAGS_ACTIVE')

        return new Promise((resolve, reject) => {
            axios.get(Config.apiPath + 'type/ktag/active', {params})
                .then(
                    response => {
                        if (response.data.code !== 500) {
                            commit('LOAD_TYPE_KTAGS_ACTIVE_OK', response.data.data)
                            resolve(response.data.data)
                        } else {
                            commit('LOAD_TYPE_KTAGS_ACTIVE_FAIL')
                            reject()
                        }

                    })
                .catch(error => {
                    commit('LOAD_TYPE_KTAGS_ACTIVE_FAIL')
                    reject()
                })
        })
    },
    storeTypektag({commit, dispatch}, form) {
        commit('STORE_TYPE_KTAG')

        return new Promise((resolve, reject) => {
            axios.post(Config.apiPath + 'type/ktag/save', form)
                .then(
                    response => {
                        
                        commit('STORE_TYPE_KTAG_OK', response.data.data)                       
                        resolve(response.data)
                    })
                .catch(error => {
                   
                    commit('STORE_TYPE_KTAG_FAIL')
                    reject(error.response.data)
                })
        })

    },
    updateTypektag({commit, dispatch}, form) {
        commit('UPDATE_TYPE_KTAG')

        return new Promise((resolve, reject) => {
            axios.put(Config.apiPath + 'type/ktag/update/'+form.id,form)
                .then(
                    response => {
                        commit('UPDATE_TYPE_KTAG_OK', response.data.data)                       
                        resolve(response.data)
                    })
                .catch(error => {
                    commit('UPDATE_TYPE_KTAG_FAIL')
                    reject(error.response.data)
                })
        })


    },
    deleteTypektag({commit, dispatch}, form) {
        commit('DELETE_TYPE_KTAG')

        return new Promise((resolve, reject) => {
           
            axios.delete(Config.apiPath + 'type/ktag/delete/'+form.id, form)
                .then(
                    response => {
                        
                        commit('DELETE_TYPE_KTAG_OK', response.data.data)                       
                        resolve(response.data)
                    })
                .catch(error => {
                    commit('DELETE_TYPE_KTAG_FAIL')
                    reject(error.response.data)
                })
        })

    },
    loadTypektag({commit, dispatch}, form) {
        commit('LOAD_TYPE_KTAG')

        return new Promise((resolve, reject) => {
            axios.get(Config.apiPath + 'type/ktag/find/' + form, {form})
                .then(
                    response => {
                        if (response.data.code !== 500) {
                            commit('LOAD_TYPE_KTAG_OK', response.data.data)
                            resolve(response.data.data)
                        } else {
                            commit('LOAD_TYPE_KTAG_FAIL')
                            reject()
                        }

                    })
                .catch(error => {
                    commit('LOAD_TYPE_KTAG_FAIL')
                    reject()
                })
        })
    }
}

const mutations = {
   
    STORE_TYPE_KTAG_OK(state, typektag) {
       // state.KTAG = KTAG;       
      //  state.KTAG.push(KTAG);
    },
    UPDATE_TYPE_KTAG_OK(state, typektag) {
        state.typektag = typektag;      
    },
    DELETE_TYPE_KTAG_OK(state, typektag) {
        state.typektag = typektag;      
    },
    LOAD_TYPE_KTAGS_OK(state,typektags)
    {
        state.typektags = typektags;
    },
    LOAD_TYPE_KTAG_OK(state,typektag)
    {
        state.typektag = typektag;
    }

}

export default {
    state,
    actions,
    mutations
}
