import axios from 'axios'
import * as Config from '../../config'
import Cookies from 'js-cookie'

const state = {
    typestation: {},
    station:     {},
    stations:    {}
}

const actions = {

    loadStations({commit, dispatch}, params) {
        commit('LOAD_STATIONS')

        return new Promise((resolve, reject) => {
            axios.get(Config.apiPath + 'station', {params})
                .then(
                    response => {
                        if (response.data.code !== 500) {
                            commit('LOAD_STATIONS_OK', response.data.data)
                            resolve(response.data.data)
                        } else {
                            commit('LOAD_STATIONS_FAIL')
                            reject()
                        }
                    })
                .catch(error => {
                    commit('LOAD_STATIONS_FAIL')
                    reject()
                })
        })
    },
    loadStationsByEvent({commit, dispatch}, form) {
        commit('LOAD_STATIONS_BY_EVENT')

        return new Promise((resolve, reject) => {
            axios.get(Config.apiPath + 'station/filterby/id_event/' + form.id)
                .then(
                    response => {
                        if (response.data.code !== 500) {
                            commit('LOAD_STATIONS_BY_EVENT_OK', response.data.data)
                            resolve(response.data.data)
                        } else {
                            commit('LOAD_STATIONS_BY_EVENT_FAIL')
                            reject()
                        }
                    })
                .catch(error => {
                    commit('LOAD_STATIONS_BY_EVENT_FAIL')
                    reject()
                })
        })
    },

    loadStationsByCategoryVendor({commit, dispatch}, form) {
        commit('LOAD_STATIONS_BY_CATEGORY_VENDOR')

        return new Promise((resolve, reject) => {
            axios.get(Config.apiPath + 'station/filterbycategoryvendor/' + form.idcategory + '/' + form.idevent)
                .then(
                    response => {
                        if (response.data.code !== 500) {
                            commit('LOAD_STATIONS_BY_CATEGORY_VENDOR_OK', response.data.data)
                            resolve(response.data.data)
                        } else {
                            commit('LOAD_STATIONS_BY_CATEGORY_VENDOR_FAIL')
                            reject()
                        }
                    })
                .catch(error => {
                    commit('LOAD_STATIONS_BY_CATEGORY_VENDOR_FAIL')
                    reject()
                })
        })
    },
    loadStationsByCategoryVendor2({commit, dispatch}, params) {
        commit('LOAD_STATIONS_BY_CATEGORY_TOPUP')

        return new Promise((resolve, reject) => {
               //let path = Config.apiPath + 'station/filterbycategoryvendor/' + form.idcategory + '/' + form.idevent;
               let path = Config.apiPath + 'station/all';
              

               axios.get(path,{params:params})
                .then(
                    response => {
                        if (response.data.code !== 500) {
                            commit('LOAD_STATIONS_BY_CATEGORY_VENDOR_OK', response.data.data)
                            resolve(response.data.data)
                        } else {
                            commit('LOAD_STATIONS_BY_CATEGORY_VENDOR_FAIL')
                            reject()
                        }
                    })
                .catch(error => {
                    commit('LOAD_STATIONS_BY_CATEGORY_VENDOR_FAIL')
                    reject()
                })
        })
    },
  
    loadStationsByCategoryProductFree({commit, dispatch}, form) {
      commit('LOAD_STATIONS_BY_CATEGORY_PRODUCT_FREE')

      return new Promise((resolve, reject) => {
          axios.get(Config.apiPath + 'station/filterbycategoryproductfree/' + form.idcategory + '/' + form.idevent)
              .then(
                  response => {
                      if (response.data.code !== 500) {
                          commit('LOAD_STATIONS_BY_CATEGORY_PRODUCT_FREE_OK', response.data.data)
                          resolve(response.data.data)
                      } else {
                          commit('LOAD_STATIONS_BY_CATEGORY_PRODUCT_FREE_FAIL')
                          reject()
                      }
                  })
              .catch(error => {
                  commit('LOAD_STATIONS_BY_CATEGORY_VENDOR_FAIL')
                  reject()
              })
      })
  },
    loadStationsByCategoryTopup({commit, dispatch}, form) {
        commit('LOAD_STATIONS_BY_CATEGORY_TOPUP')

        return new Promise((resolve, reject) => {
            axios.get(Config.apiPath + 'station/filterbycategorytopup/' + form.idcategory + '/' + form.idevent)
                .then(
                    response => {
                        if (response.data.code !== 500) {
                            commit('LOAD_STATIONS_BY_CATEGORY_TOPUP_OK', response.data.data)
                            resolve(response.data.data)
                        } else {
                            commit('LOAD_STATIONS_BY_CATEGORY_TOPUP_FAIL')
                            reject()
                        }
                    })
                .catch(error => {
                    commit('LOAD_STATIONS_BY_CATEGORY_TOPUP_FAIL')
                    reject()
                })
        })
    },
    loadStationsByCategoryTopup2({commit, dispatch}, params) {
        commit('LOAD_STATIONS_BY_CATEGORY_TOPUP')
        console.log('Recibiendo parametros')
        console.log(params)

        return new Promise((resolve, reject) => {
               //let path = Config.apiPath + 'station/filterbycategorytopup/' + form.idcategory + '/' + form.idevent;
               let path = Config.apiPath + 'station/all';
              

               axios.get(path,{params:params})
                .then(
                    response => {
                        if (response.data.code !== 500) {
                            commit('LOAD_STATIONS_BY_CATEGORY_TOPUP_OK', response.data.data)
                            resolve(response.data.data)
                        } else {
                            commit('LOAD_STATIONS_BY_CATEGORY_TOPUP_FAIL')
                            reject()
                        }
                    })
                .catch(error => {
                    commit('LOAD_STATIONS_BY_CATEGORY_TOPUP_FAIL')
                    reject()
                })
        })
    },
    loadStationsActive({commit, dispatch}, params) {
        commit('LOAD_STATIONS_ACTIVE')

        return new Promise((resolve, reject) => {
            axios.get(Config.apiPath + 'station/active', {params})
                .then(
                    response => {
                        if (response.data.code !== 500) {
                            commit('LOAD_STATIONS_ACTIVE_OK', response.data.data)
                            resolve(response.data.data)
                        } else {
                            commit('LOAD_STATIONS_ACTIVE_FAIL')
                            reject()
                        }
                    })
                .catch(error => {
                    commit('LOAD_STATIONS_ACTIVE_FAIL')
                    reject()
                })
        })
    },
    loadSalesByStation({commit, dispatch}, station) {
        commit('LOAD_SALES_STATIONS')

        return new Promise((resolve, reject) => {
            axios.get(Config.apiPath + 'product_transaction/station/' + station)
                .then(
                    response => {
                        if (response.data.code !== 500) {
                            commit('LOAD_SALES_STATIONS_OK', response.data.data)
                            resolve(response.data.data)
                        } else {
                            commit('LOAD_SALES_STATIONS_FAIL')
                            reject()
                        }
                    })
                .catch(error => {
                    commit('LOAD_SALES_STATIONS_FAIL')
                    reject()
                })
        })
    },
    loadSalesByStation2({commit, dispatch}, params) {
        commit('LOAD_SALES_STATIONS')

        return new Promise((resolve, reject) => {
            axios.get(Config.apiPath + 'product_transaction/station/' + params.id_station,{params:params})
                .then(
                    response => {
                        if (response.data.code !== 500) {
                            commit('LOAD_SALES_STATIONS_OK', response.data.data)
                            resolve(response.data.data)
                        } else {
                            commit('LOAD_SALES_STATIONS_FAIL')
                            reject()
                        }
                    })
                .catch(error => {
                    commit('LOAD_SALES_STATIONS_FAIL')
                    reject()
                })
        })
    },

    loadSalesByStationDetails({commit, dispatch}, sale) {
        commit('LOAD_SALES_STATIONS_DETAIL')

        return new Promise((resolve, reject) => {
            axios.get(Config.apiPath + 'product_transaction/details/' + sale)
                .then(
                    response => {
                        if (response.data.code !== 500) {
                            commit('LOAD_SALES_STATIONS_DETAIL_OK')
                            resolve(response.data.data)
                        } else {
                            commit('LOAD_SALES_STATIONS_DETAIL_FAIL')
                            reject()
                        }
                    })
                .catch(error => {
                    commit('LOAD_SALES_STATIONS_DETAIL_FAIL')
                    reject()
                })
        })
    },
    loadProductsAnalyticByStation({commit, dispatch}, station) {
        commit('LOAD_PRODUCTS_STATIONS')

        return new Promise((resolve, reject) => {
            axios.get(Config.apiPath + 'product_transaction/products/station/' + station)
                .then(
                    response => {
                        if (response.data.code !== 500) {
                            commit('LOAD_PRODUCTS_STATIONS_OK', response.data.data)
                            resolve(response.data.data)
                        } else {
                            commit('LOAD_PRODUCTS_STATIONS_FAIL')
                            reject()
                        }
                    })
                .catch(error => {
                    commit('LOAD_PRODUCTS_STATIONS_FAIL')
                    reject()
                })
        })
    },
    loadTopupAnalyticByStation({commit, dispatch}, station) {
        commit('LOAD_TOPUP_ANALYTIC_STATIONS')

        return new Promise((resolve, reject) => {
            axios.get(Config.apiPath + 'topuptransaction/topups/station/' + station)
                .then(
                    response => {
                        if (response.data.code !== 500) {
                            commit('LOAD_TOPUP_ANALYTIC_STATIONS_OK', response.data.data)
                            resolve(response.data.data)
                        } else {
                            commit('LOAD_TOPUP_ANALYTIC_STATIONS_FAIL')
                            reject()
                        }
                    })
                .catch(error => {
                    commit('LOAD_TOPUP_ANALYTIC_STATIONS_FAIL')
                    reject()
                })
        })
    },
    loadDevicesByStation({commit, dispatch}, station) {
        commit('LOAD_DEVICES_STATIONS')

        return new Promise((resolve, reject) => {
            axios.get(Config.apiPath + 'product_transaction/devices/station/' + station)
                .then(
                    response => {
                        if (response.data.code !== 500) {
                            commit('LOAD_DEVICES_STATIONS_OK', response.data.data)
                            resolve(response.data.data)
                        } else {
                            commit('LOAD_DEVICES_STATIONS_FAIL')
                            reject()
                        }
                    })
                .catch(error => {
                    commit('LOAD_DEVICES_STATIONS_FAIL')
                    reject()
                })
        })
    },

    storeStation({commit, dispatch}, form) {
        commit('STORE_STATION')

        return new Promise((resolve, reject) => {
            axios.post(Config.apiPath + 'station', form)
                .then(
                    response => {
                        commit('STORE_STATION_OK', response.data.data)
                        resolve(response.data)
                    })
                .catch(error => {
                    commit('STORE_STATION_FAIL')
                    reject(error.response.data)
                })
        })
    },
    updateStation({commit, dispatch}, form) {
        commit('UPDATE_STATION')

        return new Promise((resolve, reject) => {
            axios.put(Config.apiPath + 'station/' + form.id, form)
                .then(
                    response => {
                        commit('UPDATE_STATION_OK', response.data.data)
                        resolve(response.data)
                    })
                .catch(error => {
                    commit('UPDATE_STATION_FAIL')
                    reject(error.response.data)
                })
        })
    },
    activeChangeStation({commit, dispatch}, id) {

        return new Promise((resolve, reject) => {
            axios.post(Config.apiPath + 'station/change/active/' + id)
                .then(
                    response => {
                        resolve(response.data)
                    })
                .catch(error => {
                    reject(error.data)
                })
        })
    },
    deleteStation({commit, dispatch}, form) {
        commit('DELETE_STATION')

        return new Promise((resolve, reject) => {
            axios.delete(Config.apiPath + 'station/' + form.id, form)
                .then(
                    response => {

                        commit('DELETE_STATION_OK', response.data.data)
                        resolve(response.data)
                    })
                .catch(error => {
                    commit('DELETE_STATION_FAIL')
                    reject(error.response.data)
                })
        })
    },
    loadStation({commit, dispatch}, form) {
        commit('LOAD_STATION')

        return new Promise((resolve, reject) => {
            axios.get(Config.apiPath + 'station/' + form, {form})
                .then(
                    response => {
                        if (response.data.code !== 500) {
                            commit('LOAD_STATION_OK', response.data.data)
                            resolve(response.data.data)
                        } else {
                            commit('LOAD_STATION_FAIL')
                            reject()
                        }
                    })
                .catch(error => {
                    commit('LOAD_STATION_FAIL')
                    reject()
                })
        })
    },
    copyStation({commit, dispatch}, form) {
        commit('COPY_STATION')

        return new Promise((resolve, reject) => {
            axios.get(Config.apiPath + 'station/copy/' + form, {form})
                .then(
                    response => {
                        if (response.data.code !== 500) {
                            commit('COPY_STATION_OK')
                            resolve(response.data)
                        } else {
                            commit('COPY_STATION_FAIL')
                            reject(response.data)
                        }
                    })
                .catch(error => {
                    commit('COPY_STATION_FAIL')
                    reject(error.data)
                })
        })
    },
    //CONFIG STATION
    loadConfigStations({commit, dispatch}, idstation) {
        commit('LOAD_CONFIG_STATION')

        return new Promise((resolve, reject) => {
            axios.get(Config.apiPath + 'station/config/' + idstation)
                .then(
                    response => {
                        if (response.data.code !== 500) {
                            commit('LOAD_CONFIG_STATION_OK', response.data.data)
                            resolve(response.data.data)
                        } else {
                            commit('LOAD_CONFIG_STATION_FAIL')
                            reject()
                        }
                    })
                .catch(error => {
                    commit('LOAD_CONFIG_STATION_FAIL')
                    reject()
                })
        })
    },
    storeConfigStation({commit, dispatch}, form) {
        commit('STORE_CONFIG_STATION')

        return new Promise((resolve, reject) => {
            axios.post(Config.apiPath + 'station/config/' + form.id, form)
                .then(
                    response => {
                        commit('STORE_CONFIG_STATION_OK', response.data.data)
                        resolve(response.data)
                    })
                .catch(error => {
                    commit('STORE_CONFIG_STATION_FAIL')
                    reject(error.response.data)
                })
        })
    },
    updateConfigStation({commit, dispatch}, form) {
        commit('UPDATE_CONFIG_STATION')

        return new Promise((resolve, reject) => {
            axios.put(Config.apiPath + 'station/config/' + form.id, form)
                .then(
                    response => {
                        commit('UPDATE_CONFIG_STATION_OK', response.data.data)
                        resolve(response.data)
                    })
                .catch(error => {
                    commit('UPDATE_CONFIG_STATION_FAIL')
                    reject(error.response.data)
                })
        })
    }
}


const mutations = {

    STORE_STATION_OK(state, station) {
        // state.station = station;
        //  state.station.push(station);
    },
    UPDATE_STATION_OK(state, station) {
        state.station = station
    },
    DELETE_STATION_OK(state, station) {
        state.station = station
    },
    LOAD_STATIONS_OK(state, stations) {
        state.stations = stations
    },
    LOAD_STATION_OK(state, station) {
        state.station = station
    },
    //CONFIG STATION
    LOAD_CONFIG_STATION_OK(state, config) {
        state.config = config
    },
    STORE_CONFIG_STATION_OK(state, config) {
        state.config = config;
        //  state.station.push(station);
    },
    UPDATE_CONFIG_STATION_OK(state, config) {
        state.config = config
    },
    LOAD_SALES_STATIONS_OK(state, sales) {
        state.sales = sales
    },
    LOAD_PRODUCTS_STATIONS_OK(state, products) {
        state.products = products
    },
    LOAD_DEVICES_STATIONS_OK(state, devices) {
        state.devices = devices
    },


}

export default {
    state,
    actions,
    mutations
}
