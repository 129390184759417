import axios from 'axios'
import * as Config from '../../config'
import Cookies from 'js-cookie'

const state = {
  logaccess: {},
  logaccesss: {}
}

const actions = {

    loadLogAccesss ({ commit, dispatch }) {
    commit('LOAD_LOGACCESSS')

    return new Promise((resolve, reject) => {
      axios.get(Config.apiPath + 'logaccess')
      .then(
        response => {
          if (response.data.code !== 500) {
            commit('LOAD_LOGACCESSS_OK', response.data.data)
            resolve(response.data.data)
          } else {
            commit('LOAD_LOGACCESSS_FAIL')
            reject()
          }
        })
      .catch(error => {
        commit('LOAD_LOGACCESSS_FAIL')
        reject()
      })
    })
  },
  loadZoneLogs ({ commit, dispatch }, idzone) {
    commit('LOAD_ZONEEVENTS_BY_EVENT')

    return new Promise((resolve, reject) => {
      axios.get(Config.apiPath + 'logaccess/all_by_zone/' + idzone)
      .then(
        response => {
          if (response.data.code !== 500) {
            commit('LOAD_ZONEEVENTS_BY_EVENT_OK', response.data)
            resolve(response.data)
          } else {
            commit('LOAD_ZONEEVENTS_BY_EVENT_FAIL')
            reject()
          }
        })
      .catch(error => {
        commit('LOAD_ZONEEVENTS_BY_EVENT_FAIL')
        reject()
      })
    })
  },
  storeLogAccess ({ commit, dispatch }, form) {
    commit('STORE_LOGACCESS')

    return new Promise((resolve, reject) => {
      axios.post(Config.apiPath + 'logaccess/save', form)
      .then(
        response => {
          commit('STORE_LOGACCESS_OK')
          resolve(response.data)
        })
      .catch(error => {
        commit('STORE_LOGACCESS_FAIL')
        reject(error.response.data)
      })
    })
  },
  updateLogAccess ({ commit, dispatch }, form) {
    commit('UPDATE_LOGACCESS')

    return new Promise((resolve, reject) => {
      axios.put(Config.apiPath + 'logaccess/' + form.id, form)
      .then(
        response => {
          commit('UPDATE_LOGACCESS_OK')
          resolve(response.data)
        })
      .catch(error => {
        commit('UPDATE_LOGACCESS_FAIL')
        reject(error.response.data)
      })
    })
  },
  deleteLogAccess ({ commit, dispatch }, form) {
    commit('DELETE_LOGACCESS')

    return new Promise((resolve, reject) => {
      axios.delete(Config.apiPath + 'logaccess/delete/' + form.id, form)
      .then(
        response => {
          commit('DELETE_LOGACCESS_OK')
          resolve(response.data)
        })
      .catch(error => {
        commit('DELETE_LOGACCESS_FAIL')
        reject(error.response.data)
      })
    })
  },
  loadLogAccess ({ commit, dispatch }, form) {
    commit('LOAD_LOGACCESS')

    return new Promise((resolve, reject) => {
      axios.get(Config.apiPath + 'logaccess/' + form, { form })
      .then(
        response => {
          if (response.data.code !== 500) {
            commit('LOAD_LOGACCESS_OK', response.data.data)
            resolve(response.data.data)
          } else {
            commit('LOAD_LOGACCESS_FAIL')
            reject()
          }
        })
      .catch(error => {
        commit('LOAD_LOGACCESS_FAIL')
        reject()
      })
    })
  },
  loadLogAccessByEvent ({ commit, dispatch }, idevent) {
    commit('LOAD_LOGACCESSS_BY_EVENT')

    return new Promise((resolve, reject) => {
      axios.get(Config.apiPath + 'logaccess/filterby/id_event/' + idevent)
      .then(
        response => {
          if (response.data.code !== 500) {
            commit('LOAD_LOGACCESSS_BY_EVENT_OK', response.data.data)
            resolve(response.data.data)
          } else {
            commit('LOAD_LOGACCESSS_BY_EVENT_FAIL')
            reject()
          }
        })
      .catch(error => {
        commit('LOAD_LOGACCESSS_BY_EVENT_FAIL')
        reject()
      })
    })
  },
  loadTypeLogAccess ({ commit, dispatch }) {
    commit('LOAD_TYPE_LOGACCESS')

    return new Promise((resolve, reject) => {
      axios.get(Config.apiPath + 'type/logaccess/active')
      .then(
        response => {
          if (response.data.code !== 500) {
            commit('LOAD_TYPE_LOGACCESS_OK')
            resolve(response.data.data)
          } else {
            commit('LOAD_TYPE_LOGACCESS_FAIL')
            reject()
          }
        })
      .catch(error => {
        commit('LOAD_TYPE_LOGACCESS_FAIL')
        reject()
      })
    })
  },
  activeChangeLogAccess({commit, dispatch}, id) {

    return new Promise((resolve, reject) => {
      axios.post(Config.apiPath + 'logaccess/change/active/' + id)
      .then(
        response => {
          resolve(response.data)
        })
      .catch(error => {
        reject(error.data)
      })
    })
  }

}

const mutations = {
  LOAD_LOGACCESS_OK (state, logaccesss) {
    state.logaccess = logaccesss
  },
  LOAD_LOGACCESSS_OK (state, logaccesss) {
    state.logaccesss = logaccesss
  },
  LOAD_LOGACCESSS_BY_COMPANY_OK (state, logaccesss) {
    state.logaccesss = logaccesss
  }

}

export default {
  state,
  actions,
  mutations
}
