import axios from 'axios'
import * as Config from '../../config'
import Cookies from 'js-cookie'
import store from '../index'

const state   = {
    token:  Cookies.get('access_token') || '',
    status: '',
    company: {},
    permissions: [],
}
const getters = {
    isAuthenticated: state => !!state.token,
    authStatus:      state => state.status,
}

const actions = {

    checkLogin({commit, dispatch}) {
        commit('CHECK_LOGIN')
        const accessToken = Cookies.get('access_token')
        return new Promise((resolve, reject) => {
            if (!accessToken) {
                commit('CHECK_LOGIN_FAIL')
                return reject(new Error('No access token stored'))
            }
            axios.get(Config.apiPath + 'auth/user')
                .then(response => {
                    commit('CHECK_LOGIN_OK', response.data)
                })
                .catch(error => {
                    Cookies.remove('access_token')
                    commit('CHECK_LOGIN_FAIL')
                    reject(error.response.data)
                })
            resolve()
        })
    },

    checkLoginSpa({commit, dispatch}) {
        commit('CHECK_LOGIN_SPA')
        const accessToken = Cookies.get('access_token')
        return new Promise((resolve, reject) => {
            if (!accessToken) {
                commit('CHECK_LOGIN_FAIL')
                return reject(new Error('No access token stored'))
            }
            return resolve()
        })
    },

    login({commit, dispatch}, form) {
        commit('LOGIN')
        return new Promise((resolve, reject) => {
            axios.post(Config.apiPath + 'auth/login', form)
                .then(
                    response => {
                        const accessToken = response.data.access_token
                        Cookies.set('access_token', accessToken)

                        commit('LOGIN_OK', response.data)
                        resolve(response.data)
                    })
                .catch(error => {
                    commit('LOGIN_FAIL')
                    reject(error.response.data)
                })
        })
    },

    logout({commit,rootState}) {
        // remove the axios default header
        // delete axios.defaults.headers.common['Authorization']
        // resolve()

        return new Promise((resolve, reject) => {
            commit('LOGOUT')
            axios.post(Config.apiPath + 'auth/logout')
                .then(
                    response => {
                        commit('LOGOUT_OK')
                        Object.keys(Cookies.get()).forEach(function(cookieName) {
                            var neededAttributes = {
                                // Here you pass the same attributes that were used when the cookie was created
                                // and are required when removing the cookie
                            };
                            Cookies.remove(cookieName, neededAttributes);
                        });
                        commit('LOGOUT_OK')
                        rootState.event.event = {}
                        rootState.event.manage = false
                        resolve(response.data)
                    })
                .catch(error => {

                })
        })
    },

    register({commit, dispatch}, form) {
        commit('REGISTER')

        return new Promise((resolve, reject) => {
            axios.post(Config.apiPath + 'auth/register', form)
                .then(
                    response => {
                        const accessToken = response.data.access_token
                        Cookies.set('access_token', accessToken)

                        commit('REGISTER_OK', response.data.user)
                        resolve()
                    })
                .catch(error => {
                    commit('REGISTER_FAIL')
                    reject(error.response.data)
                })
        })
    },

    updateProfile({commit, dispatch}, {id, form}) {
        commit('UPDATE_PROFILE')

        return new Promise((resolve, reject) => {
            axios.post(Config.apiPath + 'user/' + id, {_method: 'PUT', ...form})
                .then(
                    response => {
                        commit('UPDATE_PROFILE_OK', response.data.user)
                        resolve()
                    })
                .catch(error => {
                    commit('UPDATE_PROFILE_FAIL')
                    reject(error.response.data)
                })
        })
    },

}

const mutations = {

    CHECK_LOGIN_OK(state, data) {
        state.me          = data.user
        state.permissions = data.permissions
        state.status      = 'success'
        // state.token = data.access_token
    },
    LOGIN(state) {
        state.status = 'loading'
    },

    LOGIN_OK(state, data) {
        state.me          = data.user
        state.company     = data.company
        state.permissions = data.permissions
        state.token       = data.access_token
        state.status      = 'success'
    },

    LOGOUT_OK(state) {
        state.me          = null
        state.company     = null
        state.permissions = []
        state.token       = null
        state.status      = 'error'

    },
    CHECK_LOGIN_FAIL(state) {
        state.me          = null
        state.company     = null
        state.permissions = []
        state.token       = null
        state.status      = 'error'
    },

    REGISTER_OK(state, user) {
        state.me = user
    },

    UPDATE_PROFILE_OK(state, user) {
        state.me = user
    },


}

export default {
    state,
    // getters,
    actions,
    mutations
}
