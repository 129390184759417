import Vue from "vue";
import Router from "vue-router";
import store from "./../store";
import {sync} from "vuex-router-sync";
import Cookies from "js-cookie";
import Permission from "./../permission";
import AppLayout from "../components/views/admin/AppLayout";

import Stations from "../components/views/station/Stations";
// AUTH
import AuthLayout from "../components/auth/AuthLayout";
import lazyLoadingViews from "./lazyLoadingViews";
import lazyLoadingAuth from "./lazyLoadingAuth";
import lazyLoadingPages from "./lazyLoadingPages";

Vue.use(Router);

const EmptyParentComponent = {
    template: "<router-view></router-view>"
};

const ifNotAuthenticated = (to, from, next) => {
    if (!store.getters.isAuthenticated) {
        next();
        return;
    }
    next("/");
};

const ifAuthenticated = (to, from, next) => {
    if (store.getters.isAuthenticated) {
        next();
        return;
    }
    next("/auth/login");
};

const routes = [
  {
    path:      "/:slug/saldo",
    name:      "Public_Money_Ktag",
    component: lazyLoadingPages("public/publicmoneyktag"),
    meta:      {permission: Permission.LOGIN}
},
  {
    path:      "/:slug/registro",
    name:      "Public_Register",
    component: lazyLoadingPages("public/publicregister"),
    meta:      {permission: Permission.LOGIN}
},
  /*  {
        path:      "/register/:code",
        default:     true,
        name:      "Public_Register",
        component: lazyLoadingPages("public/publicregister"),
        meta:      {permission: Permission.LOGIN}
    },
    */
   {
    path:      "/registered/:code",
    name:      "Public_Registered",
    component: lazyLoadingPages("public/publicregistered"),
    meta:      {permission: Permission.LOGIN}
},
    {
        path:      "*",
        name:      "not-found-simple",
        component: lazyLoadingPages("404-pages/VuesticPageNotFoundSimple"),
        meta:      {permission: Permission.LOGIN}
    },
    {
        path:      "/403",
        name:      "Page403",
        component: lazyLoadingPages("404-pages/VuesticPageNotFoundSearch"),
        meta:      {permission: Permission.LOGIN}
    },
    {
        path:      "/dronena/analiticas/:idevent",
        name:      "Analitics",
        component: lazyLoadingPages("public/analitics"),
        meta:      {permission: Permission.LOGIN}
    },
    {
        path:      "/auth",
        component: AuthLayout,
        children:  [
            {
                name:        "login",
                path:        "login",
                component:   lazyLoadingAuth("login/Login"),
                beforeEnter: ifNotAuthenticated,
                meta:        {permission: Permission.LOGIN}
            },
            {
                name:        "signup",
                path:        "signup",
                beforeEnter: ifNotAuthenticated,
                component:   lazyLoadingAuth("signup/Signup"),
                meta:        {permission: Permission.LOGIN}
            },
            {
                path:     "",
                redirect: {name: "login"}
            }
        ]
    },
    {
        name:      "Admin",
        path:      "/",
        redirect:  "/dashboard",
        meta:      {requiresAuth: true, permission: Permission.LOGIN},
        component: AppLayout,
        children:  [
            {
                name:        "dashboard",
                path:        "/dashboard",
                component:   lazyLoadingViews("dashboard/Dashboard"),
                default:     true,
                beforeEnter: ifAuthenticated,
                meta:        {permission: Permission.LOGIN}
            },
            {
                name:        "team",
                path:        "team",
                component:   lazyLoadingViews("team/Team"),
                default:     true,
                beforeEnter: ifAuthenticated,
                meta:        {permission: Permission.COMPANY}
            },
            {
                name:        "newteam",
                path:        "team/new",
                component:   lazyLoadingViews("team/NewTeam"),
                default:     true,
                beforeEnter: ifAuthenticated,
                meta:        {permission: Permission.COMPANY}
            },
            {
                name:        "updateteam",
                path:        "team/update/:id",
                component:   lazyLoadingViews("team/UpdateTeam"),
                default:     true,
                beforeEnter: ifAuthenticated,
                meta:        {permission: Permission.COMPANY}
            },
            {
                name:        "user",
                path:        "user",
                component:   lazyLoadingViews("user/User"),
                default:     true,
                beforeEnter: ifAuthenticated,
                meta:        {permission: Permission.COMPANY}
            },
            {
                name:        "newuser",
                path:        "user/new",
                component:   lazyLoadingViews("user/NewUser"),
                default:     true,
                beforeEnter: ifAuthenticated,
                meta:        {permission: Permission.COMPANY}
            },
            {
                name:        "updateuser",
                path:        "user/update/:id",
                component:   lazyLoadingViews("user/UpdateUser"),
                default:     true,
                beforeEnter: ifAuthenticated,
                meta:        {permission: Permission.COMPANY}
            },
            {
                name:        "workteam",
                path:        "workteam",
                component:   lazyLoadingViews("workteam/all"),
                default:     true,
                beforeEnter: ifAuthenticated,
                meta:        {permission: Permission.COMPANY}
            },
            {
                name:        "newworkteam",
                path:        "workteam/new",
                component:   lazyLoadingViews("workteam/new"),
                default:     true,
                beforeEnter: ifAuthenticated,
                meta:        {permission: Permission.COMPANY}
            },
            {
                name:        "updateworkteam",
                path:        "workteam/update/:id",
                component:   lazyLoadingViews("workteam/update"),
                default:     true,
                beforeEnter: ifAuthenticated,
                meta:        {permission: Permission.COMPANY}
            },
            {
                name:        "configevent",
                path:        "config/event",
                component:   lazyLoadingViews("event/ConfigEvent"),
                default:     true,
                beforeEnter: ifAuthenticated,
                meta:        {permission: Permission.COMPANY}
            },
            {
                name:        "station",
                path:        "station",
                component:   lazyLoadingViews("station/Stations"),
                default:     true,
                beforeEnter: ifAuthenticated,
                meta:        {permission: Permission.COMPANY}
            },
            {
                name:        "detailstation",
                path:        "station/detail/:idstation",
                component:   lazyLoadingViews("station/DetailStation"),
                default:     true,
                beforeEnter: ifAuthenticated,
                meta:        {permission: Permission.COMPANY}
            },
            {
                name:        "stationsaledetail",
                path:        "station/sale/detail/:idproducttrasaction",
                component:   lazyLoadingViews("station/StationSaleDetail"),
                default:     true,
                beforeEnter: ifAuthenticated,
                meta:        {permission: Permission.COMPANY}
            },
            {
                name:        "newstation",
                path:        "station/new",
                component:   lazyLoadingViews("station/NewStation"),
                default:     true,
                beforeEnter: ifAuthenticated,
                meta:        {permission: Permission.COMPANY}
            },
            {
                name:        "updatestation",
                path:        "station/update/:id",
                component:   lazyLoadingViews("station/UpdateStation"),
                default:     true,
                beforeEnter: ifAuthenticated,
                meta:        {permission: Permission.COMPANY}
            },
            //UBICATIONS
            {
              name:        "ubication",
              path:        "ubication",
              component:   lazyLoadingViews("ubications/Ubications"),
              default:     true,
              beforeEnter: ifAuthenticated,
              meta:        {permission: Permission.COMPANY}
          },
          {
              name:        "newubication",
              path:        "ubication/new",
              component:   lazyLoadingViews("ubications/NewUbication"),
              default:     true,
              beforeEnter: ifAuthenticated,
              meta:        {permission: Permission.COMPANY}
          },
          {
              name:        "updateubication",
              path:        "ubication/update/:id",
              component:   lazyLoadingViews("ubications/UpdateUbication"),
              default:     true,
              beforeEnter: ifAuthenticated,
              meta:        {permission: Permission.COMPANY}
          },
           //DAYS
           {
            name:        "day",
            path:        "day",
            component:   lazyLoadingViews("days/Days"),
            default:     true,
            beforeEnter: ifAuthenticated,
            meta:        {permission: Permission.COMPANY}
        },
        {
            name:        "newday",
            path:        "day/new",
            component:   lazyLoadingViews("days/NewDay"),
            default:     true,
            beforeEnter: ifAuthenticated,
            meta:        {permission: Permission.COMPANY}
        },
        {
            name:        "updateday",
            path:        "day/update/:id",
            component:   lazyLoadingViews("days/UpdateDay"),
            default:     true,
            beforeEnter: ifAuthenticated,
            meta:        {permission: Permission.COMPANY}
        },

            //PRODUCTS BY STATION
            {
                name:        "productbystation",
                path:        "station/:idstation/product",
                component:   lazyLoadingViews("station/ProductsByStation"),
                default:     true,
                beforeEnter: ifAuthenticated,
                meta:        {permission: Permission.COMPANY}
            },
            {
                name:        "newproductbystation",
                path:        "station/:idstation/product/new",
                component:   lazyLoadingViews("station/NewProductByStation"),
                default:     true,
                beforeEnter: ifAuthenticated,
                meta:        {permission: Permission.COMPANY}
            },
            {
                name:        "updateproductbystation",
                path:        "station/:idstation/product/update/:idproduct",
                component:   lazyLoadingViews("station/UpdateProductByStation"),
                default:     true,
                beforeEnter: ifAuthenticated,
                meta:        {permission: Permission.COMPANY}
            },

            {
                name:        "event",
                path:        "event",
                component:   lazyLoadingViews("event/Events"),
                default:     true,
                beforeEnter: ifAuthenticated,
                meta:        {permission: Permission.COMPANY}
            },
            {
                name:        "event_dashboard",
                path:        "event/dashboard",
                component:   lazyLoadingViews("dashboard_event/Dashboard"),
                default:     true,
                beforeEnter: ifAuthenticated,
                meta:        {permission: Permission.COMPANY}
            },
            {
                name:        "new_event",
                path:        "event/new",
                component:   lazyLoadingViews("event/NewEvent"),
                default:     true,
                beforeEnter: ifAuthenticated,
                meta:        {permission: Permission.COMPANY}
            },
            {
                name:        "update_event",
                path:        "event/update/:id",
                component:   lazyLoadingViews("event/UpdateEvent"),
                default:     true,
                beforeEnter: ifAuthenticated,
                meta:        {permission: Permission.COMPANY}
            },
            {
                name:        "profile",
                path:        "profile",
                component:   lazyLoadingViews("user/Profile"),
                default:     true,
                beforeEnter: ifAuthenticated,
                meta:        {permission: Permission.LOGIN}
            },
            {
                name:        "devices",
                path:        "devices",
                component:   lazyLoadingViews("device/Device"),
                default:     true,
                beforeEnter: ifAuthenticated,
                meta:        {permission: Permission.ADMIN}
            },
            {
                name:        "NewDevice",
                path:        "device/new",
                component:   lazyLoadingViews("device/NewDevice"),
                default:     true,
                beforeEnter: ifAuthenticated,
                meta:        {permission: Permission.ADMIN}
            },
            {
                name:        "UpdateDevice",
                path:        "device/update/:id",
                component:   lazyLoadingViews("device/UpdateDevice"),
                default:     true,
                beforeEnter: ifAuthenticated,
                meta:        {permission: Permission.ADMIN}
            },
            {
                name:        "companies",
                path:        "companies",
                component:   lazyLoadingViews("company/Companies"),
                default:     true,
                beforeEnter: ifAuthenticated,
                meta:        {permission: Permission.ADMIN}
            },
            {
                name:        "newcompany",
                path:        "companies/new",
                component:   lazyLoadingViews("company/NewCompany"),
                default:     true,
                beforeEnter: ifAuthenticated,
                meta:        {permission: Permission.ADMIN}
            },
            {
                name:        "updatecompany",
                path:        "companies/update/:id",
                component:   lazyLoadingViews("company/UpdateCompany"),
                default:     true,
                beforeEnter: ifAuthenticated,
                meta:        {permission: Permission.ADMIN}
            },
            {
                name:        "audits",
                path:        "audits",
                component:   lazyLoadingViews("audit/Audits"),
                default:     true,
                beforeEnter: ifAuthenticated,
                meta:        {permission: Permission.ADMIN}
            },
            {
                name:        "staff",
                path:        "staff",
                component:   lazyLoadingViews("staff/Staffs"),
                default:     true,
                beforeEnter: ifAuthenticated,
                meta:        {permission: Permission.COMPANY}
            },
            {
                name:        "newstaff",
                path:        "staff/new",
                component:   lazyLoadingViews("staff/NewStaff"),
                default:     true,
                beforeEnter: ifAuthenticated,
                meta:        {permission: Permission.COMPANY}
            },
            {
                name:        "updatestaff",
                path:        "staff/update/:id",
                component:   lazyLoadingViews("staff/UpdateStaff"),
                default:     true,
                beforeEnter: ifAuthenticated,
                meta:        {permission: Permission.COMPANY}
            },

            //DEVICES ON EVENT
            {
                name:        "deviceevent",
                path:        "device/event",
                component:   lazyLoadingViews("device/DeviceEvent"),
                default:     true,
                beforeEnter: ifAuthenticated,
                meta:        {permission: Permission.COMPANY}
            },
            {
                name:        "deviceeventdetails",
                path:        "device/event/detail/:iddevice",
                component:   lazyLoadingViews("device/DetailDeviceEvent"),
                default:     true,
                beforeEnter: ifAuthenticated,
                meta:        {permission: Permission.COMPANY}
            },
            {
                name:        "newdeviceevent",
                path:        "deviceevent/new",
                component:   lazyLoadingViews("device/NewDeviceEvent"),
                default:     true,
                beforeEnter: ifAuthenticated,
                meta:        {permission: Permission.COMPANY}
            },
            {
                name:        "updatedeviceevent",
                path:        "deviceevent/update/:id",
                component:   lazyLoadingViews("device/UpdateDeviceEvent"),
                default:     true,
                beforeEnter: ifAuthenticated,
                meta:        {permission: Permission.COMPANY}
            },

            {
                name:        "customer",
                path:        "customer",
                component:   lazyLoadingViews("customer/Customers"),
                default:     true,
                beforeEnter: ifAuthenticated,
                meta:        {permission: Permission.COMPANY}
            },
            {
                name:        "newcustomer",
                path:        "customer/new",
                component:   lazyLoadingViews("customer/NewCustomer"),
                default:     true,
                beforeEnter: ifAuthenticated,
                meta:        {permission: Permission.COMPANY}
            },
            {
                name:        "updatecustomer",
                path:        "customer/update/:id",
                component:   lazyLoadingViews("customer/UpdateCustomer"),
                default:     true,
                beforeEnter: ifAuthenticated,
                meta:        {permission: Permission.COMPANY}
            },
            {
              name:        "customerregister",
              path:        "customerregister",
              component:   lazyLoadingViews("customer/CustomersRegistered"),
              default:     true,
              beforeEnter: ifAuthenticated,
              meta:        {permission: Permission.COMPANY}
          },
              {
                name:        "customerdetail",
                path:        "customer/detail/:code",
                component:   lazyLoadingViews("customer/CustomerDetailRegistered"),
                default:     true,
                beforeEnter: ifAuthenticated,
                meta:        {permission: Permission.COMPANY}
            },
            //ACCESS ROUTES
            {
                name:        "membership",
                path:        "membership",
                component:   lazyLoadingViews("access/Memberships"),
                default:     true,
                beforeEnter: ifAuthenticated,
                meta:        {permission: Permission.COMPANY}
            },
            {
                name:        "membershipnew",
                path:        "membership/new",
                component:   lazyLoadingViews("access/MembershipNew"),
                default:     true,
                beforeEnter: ifAuthenticated,
                meta:        {permission: Permission.COMPANY}
            },
            {
                name:        "membershipupdate",
                path:        "membership/update/:id",
                component:   lazyLoadingViews("access/MembershipUpdate"),
                default:     true,
                beforeEnter: ifAuthenticated,
                meta:        {permission: Permission.COMPANY}
            },
            //Zone Access
            {
                name:        "zoneaccess",
                path:        "zoneaccess",
                component:   lazyLoadingViews("access/ZoneAccess"),
                default:     true,
                beforeEnter: ifAuthenticated,
                meta:        {permission: Permission.COMPANY}
            },
            {
                name:        "zoneaccessnew",
                path:        "zoneaccess/new",
                component:   lazyLoadingViews("access/ZoneAccessNew"),
                default:     true,
                beforeEnter: ifAuthenticated,
                meta:        {permission: Permission.COMPANY}
            },
            {
                name:        "zoneaccessupdate",
                path:        "zoneaccess/update/:id",
                component:   lazyLoadingViews("access/ZoneAccessUpdate"),
                default:     true,
                beforeEnter: ifAuthenticated,
                meta:        {permission: Permission.COMPANY}
            },
          
            //Zone Event
            {
                name:        "zoneevent",
                path:        "zoneevent",
                component:   lazyLoadingViews("access/ZoneEvents"),
                default:     true,
                beforeEnter: ifAuthenticated,
                meta:        {permission: Permission.COMPANY}
            },
            {
                name:        "zoneeventnew",
                path:        "zoneevent/new",
                component:   lazyLoadingViews("access/ZoneEventNew"),
                default:     true,
                beforeEnter: ifAuthenticated,
                meta:        {permission: Permission.COMPANY}
            },
            {
                name:        "zoneeventupdate",
                path:        "zoneevent/update/:id",
                component:   lazyLoadingViews("access/ZoneEventUpdate"),
                default:     true,
                beforeEnter: ifAuthenticated,
                meta:        {permission: Permission.COMPANY}
            },
            {
                name:        "zoneeventlog",
                path:        "zoneevent/log/:id",
                component:   lazyLoadingViews("access/ZoneEventLog"),
                default:     true,
                beforeEnter: ifAuthenticated,
                meta:        {permission: Permission.COMPANY}
            },
             //Access Log
             {
                name:        "accesslog",
                path:        "accesslog",
                component:   lazyLoadingViews("access/AccessLog"),
                default:     true,
                beforeEnter: ifAuthenticated,
                meta:        {permission: Permission.COMPANY}
            },



            //END ACCESS ROUTES

            {
                name:      "analytics",
                path:      "analytics",
                component: lazyLoadingViews("analytics/AnalyticSelection"),
                default:   true,
                meta:      {permission: Permission.COMPANY}
            },
            {
                name:      "analytics_dashboard",
                path:      "analytics/dashboard",
                component: lazyLoadingViews("analytics/Dashboard"),
                default:   true,
                meta:      {permission: Permission.COMPANY}
            },
            {
                name:      "analytics_money",
                path:      "analytics/money",
                component: lazyLoadingViews("analytics/Money"),
                default:   true,
                meta:      {permission: Permission.COMPANY}
            },
            {
                name:      "analytics_credit",
                path:      "analytics/credit",
                component: lazyLoadingViews("analytics/Credits"),
                default:   true,
                meta:      {permission: Permission.COMPANY}
            },
            {
                name:      "analytics_download",
                path:      "analytics/download",
                component: lazyLoadingViews("analytics/Downloads"),
                default:   true,
                meta:      {permission: Permission.COMPANY}
            },
            {
                name:      "analytics_ubication",
                path:      "analytics/ubication",
                component: lazyLoadingViews("analytics/Ubications"),
                default:   true,
                meta:      {permission: Permission.COMPANY}
            },
              {
                name:      "analytics_day",
                path:      "analytics/day",
                component: lazyLoadingViews("analytics/Days"),
                default:   true,
                meta:      {permission: Permission.COMPANY}
              },
              {
                name:      "analytics_ubication_download",
                path:      "analytics/ubication/download",
                component: lazyLoadingViews("analytics/DownloadByUbication"),
                default:   true,
                meta:      {permission: Permission.COMPANY}
            },

            {
                name:        "products",
                path:        "products",
                component:   lazyLoadingViews("product/Product"),
                default:     true,
                beforeEnter: ifAuthenticated,
                meta:        {permission: Permission.COMPANY}
            },
            {
                name:        "newproduct",
                path:        "product/new",
                component:   lazyLoadingViews("product/NewProduct"),
                default:     true,
                beforeEnter: ifAuthenticated,
                meta:        {permission: Permission.COMPANY}
            },
            {
                name:        "updateproduct",
                path:        "product/update/:id",
                component:   lazyLoadingViews("product/UpdateProduct"),
                default:     true,
                beforeEnter: ifAuthenticated,
                meta:        {permission: Permission.COMPANY}
            },
            {
                name:        "ktags",
                path:        "ktags",
                component:   lazyLoadingViews("ktag/Ktags"),
                default:     true,
                beforeEnter: ifAuthenticated,
                meta:        {permission: Permission.COMPANY}
            },

            {
                name:        "ktag_customer",
                path:        "ktag/customer/:id",
                component:   lazyLoadingViews("ktag/DetailCustomerKtag"),
                default:     true,
                beforeEnter: ifAuthenticated,
                meta:        {permission: Permission.COMPANY}
            },

            {
                name:        "ktag_staff",
                path:        "ktag/staff/:id",
                component:   lazyLoadingViews("ktag/DetailStaffKtag"),
                default:     true,
                beforeEnter: ifAuthenticated,
                meta:        {permission: Permission.COMPANY}
            },
            {
                name:        "transactions",
                path:        "transactions",
                component:   Stations,
                default:     true,
                beforeEnter: ifAuthenticated,
                meta:        {permission: Permission.COMPANY}
            },

            //TYPES == MASTERS

            //TYPE COMPANY
            {
                name:        "typecompanys",
                path:        "type/company",
                component:   lazyLoadingViews("type_company/TypeCompany"),
                default:     true,
                beforeEnter: ifAuthenticated,
                meta:        {permission: Permission.ADMIN}
            },
            {
                name:        "newtypecompany",
                path:        "type/company/new",
                component:   lazyLoadingViews("type_company/NewTypeCompany"),
                default:     true,
                beforeEnter: ifAuthenticated,
                meta:        {permission: Permission.ADMIN}
            },
            {
                name:        "updatetypecompany",
                path:        "type/company/update/:id",
                component:   lazyLoadingViews("type_company/UpdateTypeCompany"),
                default:     true,
                beforeEnter: ifAuthenticated,
                meta:        {permission: Permission.ADMIN}
            },
            //TYPE CREDIT
            {
                name:        "typecredits",
                path:        "type/credit",
                component:   lazyLoadingViews("type_credit/TypeCredit"),
                default:     true,
                beforeEnter: ifAuthenticated,
                meta:        {permission: Permission.ADMIN}
            },
            {
                name:        "newtypecredit",
                path:        "type/credit/new",
                component:   lazyLoadingViews("type_credit/NewTypeCredit"),
                default:     true,
                beforeEnter: ifAuthenticated,
                meta:        {permission: Permission.ADMIN}
            },
            {
                name:        "updatetypecredit",
                path:        "type/credit/update/:id",
                component:   lazyLoadingViews("type_credit/UpdateTypeCredit"),
                default:     true,
                beforeEnter: ifAuthenticated,
                meta:        {permission: Permission.ADMIN}
            },
            //TYPE CUSTOMER
            {
                name:        "typecustomers",
                path:        "type/customer",
                component:   lazyLoadingViews("type_customer/TypeCustomer"),
                default:     true,
                beforeEnter: ifAuthenticated,
                meta:        {permission: Permission.ADMIN}
            },
            {
                name:        "new_typecustomer",
                path:        "type/customer/new",
                component:   lazyLoadingViews("type_customer/NewTypeCustomer"),
                default:     true,
                beforeEnter: ifAuthenticated,
                meta:        {permission: Permission.ADMIN}
            },
            {
                name:        "update_typecustomer",
                path:        "type/customer/update/:id",
                component:   lazyLoadingViews("type_customer/UpdateTypeCustomer"),
                default:     true,
                beforeEnter: ifAuthenticated,
                meta:        {permission: Permission.ADMIN}
            },
            //TYPE EVENTS
            {
                name:        "typeevents",
                path:        "type/event",
                component:   lazyLoadingViews("type_event/TypeEvent"),
                default:     true,
                beforeEnter: ifAuthenticated,
                meta:        {permission: Permission.ADMIN}
            },
            {
                name:        "new_typeevent",
                path:        "type/event/new",
                component:   lazyLoadingViews("type_event/NewTypeEvent"),
                default:     true,
                beforeEnter: ifAuthenticated,
                meta:        {permission: Permission.ADMIN}
            },
            {
                name:        "update_typeevent",
                path:        "type/event/update/:id",
                component:   lazyLoadingViews("type_event/UpdateTypeEvent"),
                default:     true,
                beforeEnter: ifAuthenticated,
                meta:        {permission: Permission.ADMIN}
            },

            //TYPE KTAGS
            {
                name:        "typektags",
                path:        "type/ktag",
                component:   lazyLoadingViews("type_ktag/TypeKtag"),
                default:     true,
                beforeEnter: ifAuthenticated,
                meta:        {permission: Permission.ADMIN}
            },
            {
                name:        "new_typektag",
                path:        "type/ktag/new",
                component:   lazyLoadingViews("type_ktag/NewTypeKtag"),
                default:     true,
                beforeEnter: ifAuthenticated,
                meta:        {permission: Permission.ADMIN}
            },
            {
                name:        "update_typektag",
                path:        "type/ktag/update/:id",
                component:   lazyLoadingViews("type_ktag/UpdateTypeKtag"),
                default:     true,
                beforeEnter: ifAuthenticated,
                meta:        {permission: Permission.ADMIN}
            },

            //TYPE PAYS
            {
                name:        "typepays",
                path:        "type/pay",
                component:   lazyLoadingViews("type_pay/TypePay"),
                default:     true,
                beforeEnter: ifAuthenticated,
                meta:        {permission: Permission.ADMIN}
            },
            {
                name:        "new_typepay",
                path:        "type/pay/new",
                component:   lazyLoadingViews("type_pay/NewTypePay"),
                default:     true,
                beforeEnter: ifAuthenticated,
                meta:        {permission: Permission.ADMIN}
            },
            {
                name:        "update_typepay",
                path:        "type/pay/update/:id",
                component:   lazyLoadingViews("type_pay/UpdateTypePay"),
                default:     true,
                beforeEnter: ifAuthenticated,
                meta:        {permission: Permission.ADMIN}
            },

            //TYPE PRODUCT
            {
                name:        "typeproducts",
                path:        "type/product",
                component:   lazyLoadingViews("type_product/TypeProduct"),
                default:     true,
                beforeEnter: ifAuthenticated,
                meta:        {permission: Permission.ADMIN}
            },
            {
                name:        "newtypeproduct",
                path:        "type/product/new",
                component:   lazyLoadingViews("type_product/NewTypeProduct"),
                default:     true,
                beforeEnter: ifAuthenticated,
                meta:        {permission: Permission.ADMIN}
            },
            {
                name:        "updatetypeproduct",
                path:        "type/product/update/:id",
                component:   lazyLoadingViews("type_product/UpdateTypeProduct"),
                default:     true,
                beforeEnter: ifAuthenticated,
                meta:        {permission: Permission.ADMIN}
            },
            //TYPE STAFF
            {
                name:        "typestaffs",
                path:        "type/staff",
                component:   lazyLoadingViews("type_staff/TypeStaff"),
                default:     true,
                beforeEnter: ifAuthenticated,
                meta:        {permission: Permission.ADMIN}
            },
            {
                name:        "new_typestaff",
                path:        "type/staff/new",
                component:   lazyLoadingViews("type_staff/NewTypeStaff"),
                default:     true,
                beforeEnter: ifAuthenticated,
                meta:        {permission: Permission.ADMIN}
            },
            {
                name:        "update_typestaff",
                path:        "type/staff/update/:id",
                component:   lazyLoadingViews("type_staff/UpdateTypeStaff"),
                default:     true,
                beforeEnter: ifAuthenticated,
                meta:        {permission: Permission.ADMIN}
            },
            //TYPE STATIONS
            {
                name:        "typestations",
                path:        "type/station",
                component:   lazyLoadingViews("type_station/TypeStation"),
                default:     true,
                beforeEnter: ifAuthenticated,
                meta:        {permission: Permission.ADMIN}
            },
            {
                name:        "new_typestation",
                path:        "type/station/new",
                component:   lazyLoadingViews("type_station/NewTypeStation"),
                default:     true,
                beforeEnter: ifAuthenticated,
                meta:        {permission: Permission.ADMIN}
            },
            {
                name:        "update_typestation",
                path:        "type/station/update/:id",
                component:   lazyLoadingViews("type_station/UpdateTypeStation"),
                default:     true,
                beforeEnter: ifAuthenticated,
                meta:        {permission: Permission.ADMIN}
            },

            {
                name:      "statistics",
                path:      "statistics",
                component: EmptyParentComponent,
                children:  [
                    {
                        name:      "charts",
                        path:      "charts",
                        component: lazyLoadingViews("statistics/charts/Charts"),
                        meta:      {
                            wikiLink:
                                        "https://github.com/epicmaxco/vuestic-admin/wiki/Charts",
                            permission: Permission.ADMIN
                        }
                    },
                    {
                        name:      "progress-bars",
                        path:      "progress-bars",
                        component: lazyLoadingViews(
                            "statistics/progress-bars/ProgressBars"
                        ),
                        meta:      {
                            wikiLink:
                                        "https://github.com/epicmaxco/vuestic-admin/wiki/Progress-Bars",
                            permission: Permission.COMPANY
                        }
                    }
                ]
            }
        ]
    }
];
const router = new Router({
    routes,
    // mode:                 'hash',
    mode: "history"
    // linkExactActiveClass: 'nav-item active'
});

sync(store, router);

router.beforeEach((to, from, next) => {
    store
        .dispatch("checkLoginSpa")
        .then(data => {
          //  console.log("checkLoginSpa");
        })
        .catch(() => {});
    const accessToken = Cookies.get("access_token");
    console.log(accessToken);
    if (
        to.matched.some(record => record.meta.requiresAuth) &&
        !store.state.auth.me &&
        !accessToken
    ) {

       
        console.log('ENVIANDO A LOGIN')
        next("/auth/login");
    } else if (
        store.state.auth.me &&
        accessToken &&
        !store.state.auth.permissions.includes(to.meta.permission)
    ) {
        console.log('SIN PERMISOS')
        next("/403");
    } else {
        console.log('PERMITENDO ACCESO')

        if(accessToken == 'undefined'){
            console.log('ENVIANDO A LOGIN')
            document.cookie.replace(/(?<=^|;).+?(?=\=|;|$)/g, name => location.hostname.split('.').reverse().reduce(domain => (domain=domain.replace(/^\.?[^.]+/, ''),document.cookie=`${name}=;max-age=0;path=/;domain=${domain}`,domain), location.hostname));

            next("/auth/login");
        }else{
            next();
        }
       

       
    }
});

export default router;
