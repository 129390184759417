import axios from 'axios'
import * as Config from '../../config'
import Cookies from 'js-cookie'

const state = {
    company:            {},
    companies:          {},
    TypeCompanyActions: {}
}

const actions = {

    loadTypeCompanies({commit, dispatch}, params) {
        commit('LOAD_TYPE_COMPANIES')

        return new Promise((resolve, reject) => {
            axios.get(Config.apiPath + 'type/company', {params})
            .then(
                response => {
                    if (response.data.code !== 500) {
                        commit('LOAD_TYPE_COMPANIES_OK', response.data.data)
                        resolve(response.data.data)
                    } else {
                        commit('LOAD_TYPE_COMPANIES_FAIL')
                        reject(response.data)
                    }
                })
            .catch(error => {
                commit('LOAD_TYPE_COMPANIES_FAIL')
                reject(error.data)
            })
        })
    },

    loadCompanies({commit, dispatch}, params) {
        commit('LOAD_COMPANIES')

        return new Promise((resolve, reject) => {
            axios.get(Config.apiPath + 'company', {params})
            .then(
                response => {
                    if (response.data.code !== 500) {
                        commit('LOAD_COMPANIES_OK', response.data.data)
                        resolve(response.data.data)
                    } else {
                        commit('LOAD_COMPANIES_FAIL')
                        reject()
                    }
                })
            .catch(error => {
                commit('LOAD_COMPANIES_FAIL')
                reject()
            })
        })
    },
    storeCompany({commit, dispatch}, form) {
        commit('STORE_COMPANY')

        return new Promise((resolve, reject) => {
            axios.post(Config.apiPath + 'company', form)
            .then(
                response => {
                    commit('STORE_COMPANY_OK')
                    resolve(response.data)
                })
            .catch(error => {
                commit('STORE_COMPANY_FAIL')
                reject(error.data)
            })
        })
    },
    updateCompany({commit, dispatch}, form) {
        commit('UPDATE_COMPANY')

        return new Promise((resolve, reject) => {
            axios.put(Config.apiPath + 'company/' + form.id, form)
            .then(
                response => {
                    commit('UPDATE_COMPANY_OK')
                    resolve(response.data)
                })
            .catch(error => {
                commit('UPDATE_COMPANY_FAIL')
                reject(error.response.data)
            })
        })
    },
    deleteCompany({commit, dispatch}, form) {
        commit('DELETE_COMPANY')

        return new Promise((resolve, reject) => {
            axios.delete(Config.apiPath + 'company/' + form.id, form)
            .then(
                response => {

                    commit('DELETE_COMPANY_OK', response.data.data)
                    resolve()
                })
            .catch(error => {
                commit('DELETE_COMPANY_FAIL')
                reject(error.response.data)
            })
        })
    },
    loadCompany({commit, dispatch}, form) {
        commit('LOAD_COMPANY')
        return new Promise((resolve, reject) => {
            axios.get(Config.apiPath + 'company/' + form)
            .then(
                response => {
                    if (response.data.code !== 500) {
                        commit('LOAD_COMPANY_OK', response.data.data)
                        resolve(response.data.data)
                    } else {
                        commit('LOAD_COMPANY_FAIL')
                        reject()
                    }
                })
            .catch(error => {
                commit('LOAD_COMPANY_FAIL')
                reject()
            })
        })
    },
    loadCompanyDashboard({commit, rootState}, form) {
        commit('LOAD_COMPANY_DASHBOARD')
        return new Promise((resolve, reject) => {
            axios.post(Config.apiPath + 'company/dashboard', form)
            .then(
                response => {
                    if (response.data.code !== 500) {
                        commit('LOAD_COMPANY_DASHBOARD_OK')
                        rootState.auth.permissions = response.data.permissions
                        rootState.auth.company     = response.data.company
                        resolve(response.data)
                    } else {
                        commit('LOAD_COMPANY_DASHBOARD_FAIL')
                        reject(response.data)
                    }
                })
            .catch(error => {
                commit('LOAD_COMPANY_DASHBOARD_FAIL')
                reject(error.data)
            })
        })
    },
    loadCompanyDashboardLogout({commit, rootState}) {
        commit('LOAD_COMPANY_DASHBOARD')
        return new Promise((resolve, reject) => {
            axios.post(Config.apiPath + 'company/dashboard/logout')
            .then(
                response => {
                    if (response.data.code !== 500) {
                        commit('LOAD_COMPANY_DASHBOARD_OK')
                        rootState.auth.permissions = response.data.permissions
                        rootState.auth.company     = {}
                        resolve(response.data)
                    } else {
                        commit('LOAD_COMPANY_DASHBOARD_FAIL')
                        reject(response.data)
                    }
                })
            .catch(error => {
                commit('LOAD_COMPANY_DASHBOARD_FAIL')
                reject(error.data)
            })
        })
    },
    activeChangeCompany({commit, dispatch}, id) {

        return new Promise((resolve, reject) => {
          axios.post(Config.apiPath + 'company/change/active/' + id)
          .then(
            response => {
              resolve(response.data)
          })
          .catch(error => {
            reject(error.data)
        })
      })
    }
}

const mutations = {

    // STORE_COMPANY_OK(state, company) {
    //     state.company = company
    //     state.companies.push(company)
    // },
    // UPDATE_COMPANY_OK(state, company) {
    //     state.company = company
    // },
    DELETE_COMPANY_OK(state, company) {
        state.company = company
    },
    LOAD_COMPANIES_OK(state, companies) {
        state.companies = companies
    },
    LOAD_COMPANY_OK(state, company) {
        state.company = company
    },
    LOAD_TYPE_COMPANIES_OK(state, TypeCompany) {
        state.TypeCompanyActions = TypeCompany
    },
    // LOAD_COMPANY_DASHBOARD_OK(state, data, rootState) {
    //
    // }


}

export default {
    state,
    actions,
    mutations
}
