import axios from 'axios'
import * as Config from '../../config'
import Cookies from "js-cookie"

const state = {
    typepay: {},   
    typepays:{}
}

const actions = {

   
    loadTypepays({commit, dispatch}, params) {
        commit('LOAD_TYPE_PAYS')

        return new Promise((resolve, reject) => {
            axios.get(Config.apiPath + 'type/pay', {params})
                .then(
                    response => {
                        if (response.data.code !== 500) {
                            commit('LOAD_TYPE_PAYS_OK', response.data.data)
                            resolve(response.data.data)
                        } else {
                            commit('LOAD_TYPE_PAYS_FAIL')
                            reject()
                        }

                    })
                .catch(error => {
                    commit('LOAD_TYPE_PAYS_FAIL')
                    reject()
                })
        })
    },
    loadTypepaysActive({commit, dispatch}, params) {
        commit('LOAD_TYPE_PAYS_ACTIVE')

        return new Promise((resolve, reject) => {
            axios.get(Config.apiPath + 'type/pay/active', {params})
                .then(
                    response => {
                        if (response.data.code !== 500) {
                            commit('LOAD_TYPE_PAYS_ACTIVE_OK', response.data.data)
                            resolve(response.data.data)
                        } else {
                            commit('LOAD_TYPE_PAYS_ACTIVE_FAIL')
                            reject()
                        }

                    })
                .catch(error => {
                    commit('LOAD_TYPE_PAYS_ACTIVE_FAIL')
                    reject()
                })
        })
    },
    storeTypepay({commit, dispatch}, form) {
        commit('STORE_TYPE_PAY')

        return new Promise((resolve, reject) => {
            axios.post(Config.apiPath + 'type/pay/save', form)
                .then(
                    response => {
                        
                        commit('STORE_TYPE_PAY_OK', response.data.data)                       
                        resolve(response.data)
                    })
                .catch(error => {
                   
                    commit('STORE_TYPE_PAY_FAIL')
                    reject(error.response.data)
                })
        })

    },
    updateTypepay({commit, dispatch}, form) {
        commit('UPDATE_TYPE_PAY')

        return new Promise((resolve, reject) => {
            axios.put(Config.apiPath + 'type/pay/update/'+form.id,form)
                .then(
                    response => {
                        commit('UPDATE_TYPE_PAY_OK', response.data.data)                       
                        resolve(response.data)
                    })
                .catch(error => {
                    commit('UPDATE_TYPE_PAY_FAIL')
                    reject(error.response.data)
                })
        })


    },
    deleteTypepay({commit, dispatch}, form) {
        commit('DELETE_TYPE_PAY')

        return new Promise((resolve, reject) => {
           
            axios.delete(Config.apiPath + 'type/pay/delete/'+form.id, form)
                .then(
                    response => {
                        
                        commit('DELETE_TYPE_PAY_OK', response.data.data)                       
                        resolve(response.data)
                    })
                .catch(error => {
                    commit('DELETE_TYPE_PAY_FAIL')
                    reject(error.response.data)
                })
        })

    },
    loadTypepay({commit, dispatch}, form) {
        commit('LOAD_TYPE_PAY')

        return new Promise((resolve, reject) => {
            axios.get(Config.apiPath + 'type/pay/find/' + form, {form})
                .then(
                    response => {
                        if (response.data.code !== 500) {
                            commit('LOAD_TYPE_PAY_OK', response.data.data)
                            resolve(response.data.data)
                        } else {
                            commit('LOAD_TYPE_PAY_FAIL')
                            reject()
                        }

                    })
                .catch(error => {
                    commit('LOAD_TYPE_PAY_FAIL')
                    reject()
                })
        })
    }
}

const mutations = {
   
    STORE_TYPE_PAY_OK(state, typepay) {
       // state.PAY = PAY;       
      //  state.PAY.push(PAY);
    },
    UPDATE_TYPE_PAY_OK(state, typepay) {
        state.typepay = typepay;      
    },
    DELETE_TYPE_PAY_OK(state, typepay) {
        state.typepay = typepay;      
    },
    LOAD_TYPE_PAYS_OK(state,typepays)
    {
        state.typepays = typepays;
    },
    LOAD_TYPE_PAY_OK(state,typepay)
    {
        state.typepay = typepay;
    }

}

export default {
    state,
    actions,
    mutations
}
