<template>
  <div class="content">
    <div class="row">
      <div class="col-md-12">
        <vuestic-widget :headerText="$t('view.station.title')">
          <p>{{$t('view.station.description')}}</p>
          <div class="row">
            <div class="col-md-3">
              <button
              type="button"
              v-on:click="back"
              class="btn btn-primary btn-sm"
              >{{$t('view.station.back')}}</button>
            </div>

            <div class="col-md-3 offset-md-6"  v-show="statusEvent != 3">
              <button
              type="button"
              v-on:click="newStation"
              class="btn btn-primary btn-sm"
              >{{$t('view.station.new')}}</button>
            </div>
          </div>
        </vuestic-widget>
      </div>
      <div class="col-md-12">
        <vuestic-tabs class="tabs" :names="['Estaciones de Venta','Estaciones de Recarga', 'Manejo de Evento', 'Promociones']">
          <div slot="Estaciones de Venta">
            <TableStationMoneyVendor></TableStationMoneyVendor>
          </div>
           <div slot="Estaciones de Recarga">
           <TableStationMoneyTopup></TableStationMoneyTopup>
          </div>
          <div slot="Manejo de Evento">
            <TableStationEventManager></TableStationEventManager>
          </div>
           <div slot="Promociones">
            <TableStationProductFree></TableStationProductFree>
          </div>
        </vuestic-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import TableStation from '../tables/TableStation/TableStation.vue'
import TableStationMoneyVendor from '../tables/TableStation/TableStationMoneyVendor.vue'
import TableStationMoneyTopup from '../tables/TableStation/TableStationMoneyTopup.vue'
import TableStationEventManager from '../tables/TableStation/TableStationEventManager.vue'
import TableStationProductFree from '../tables/TableStation/TableStationProductFree.vue'
import { SpringSpinner } from 'epic-spinners'

import { mapState, mapActions } from 'vuex'

export default {
  name: 'Table',
  components: {
    SpringSpinner,
    TableStation,
    TableStationMoneyVendor,
    TableStationMoneyTopup,
    TableStationEventManager,
    TableStationProductFree
  },
  data () {
    return {}
  },
  computed: {
    ...mapState({
      statusEvent: state => state.event.event.status
    }),
  },
  methods: {
    back () {
      // this.$router.go(-1);
      this.$router.push('/')
    },
    newStation () {
      this.$router.push('station/new')
    }
  }
}
</script>

<style lang="scss">
.color-icon-label-table {
  td:first-child {
    width: 1rem;
  }
}
</style>
