import axios from "axios";
import * as Config from "../../config";
import Cookies from "js-cookie";

const state = {
 // typeproductstation: {},
  refundtransaction: {},
  refundtransactions: {}
};

const actions = {
  loadRefundTransactions({ commit, dispatch }, form) {
    commit("LOAD_REFUNDTRANSACTION");

    return new Promise((resolve, reject) => {
      axios
        .get(
          Config.apiPath + "refundtransaction/all",{params:form}
        )
        .then(response => {
          if (response.data.code !== 500) {
            commit("LOAD_REFUNDTRANSACTION_OK", response.data.data);
            resolve(response.data.data);
          } else {
            commit("LOAD_REFUNDTRANSACTION_FAIL");
            reject();
          }
        })
        .catch(error => {
          commit("LOAD_REFUNDTRANSACTION_FAIL");
          reject();
        });
    });
  },
 
};

const mutations = {
  STORE_REFUNDTRANSACTION_OK(state, refundtransaction) {
    state.refundtransaction = refundtransaction;
  
    
  },
  LOAD_REFUNDTRANSACTION_OK(state, refundtransaction) {
    state.refundtransactions = refundtransaction;
  }
};

export default {
  state,
  actions,
  mutations
};
