// Polyfills
// import 'es6-promise/auto'
// import 'babel-polyfill'

// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import axios from 'axios'
import VeeValidate from 'vee-validate'
import App from './App'
import store from './store'
import router from './router'
import Cookies from 'js-cookie'
import VuesticPlugin from '@/vuestic-theme/vuestic-plugin'
import './i18n'
import YmapPlugin from 'vue-yandex-maps'

window.axios = require('axios');


Vue.use(VuesticPlugin)
Vue.use(YmapPlugin)

// NOTE: workaround for VeeValidate + vuetable-2
Vue.use(VeeValidate, {fieldsBagName: 'formFields'})


// Authorization header
axios.interceptors.request.use(function (config) {
    config['headers'] = {
        Authorization: 'Bearer ' + Cookies.get('access_token'),
        Accept:        'application/json',
    }
    return config
}, error => Promise.reject(error))

// Show toast with message for non OK responses
axios.interceptors.response.use(response => response, error => {

    if (typeof error.response.data.data === 'object' && error.response.data.code === 400) {

        for (var key in error.response.data.data) {
            store.dispatch('addToastMessage', {
                text: error.response.data.data[key][0] || 'Request error status: ' + error.response.status,
                type: 'danger'
            })
        }
    } else {
        store.dispatch('addToastMessage', {
            text: error.response.data.message || 'Request error status: ' + error.response.status,
            type: 'danger'
        })
    }
    return Promise.reject(error)
})

router.beforeEach((to, from, next) => {
    store.commit('SET_LOADING')
    next()
})

router.afterEach((to, from) => {
    store.commit('STOP_LOADING')
})

/* eslint-disable no-new */

new Vue({
    el:     '#app',
    router,
    store,
    render: h => h(App)
})

// store.dispatch('checkLogin').then(() => {
//     console.log('checkLogin')
//     // router.replace('/dashboard')
// }).catch(() => {})
