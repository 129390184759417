<template>
    <vuestic-sidebar :hidden="isOpen">
        <template slot="menu">
            <div v-if="!manage">
                <div v-if="auth.me.rol.id === 3">
                    <div v-for="(item, index) in nav.items">
                        <div v-if="itemById(item.permission) && itemByIdTeam(item.role_team)">
                            <sidebar-link v-if="prefixLength(item) === 0" :to="{ name: item.url }">
                        <span slot="title">
                          <span class="sidebar-menu-item-icon vuestic-icon" :class="item.icon"></span>
                          <span> {{ $t(`menu.${item.name}`) }}</span>
                        </span>
                            </sidebar-link>
                            <sidebar-link-group v-if="prefixLength(item) > 0">
                        <span slot="title">
                          <span class="sidebar-menu-item-icon vuestic-icon" :class="item.icon"></span>
                          <span>{{ $t(`menu.${item.name}`) }}</span>
                        </span>
                                <div v-for="(item_childern, index_children) in item.children">
                                    <sidebar-link :to="{ name: item_childern.url } " v-if="itemById(item_childern.permission) && itemByIdTeam(item_childern.role_team)">
                                  <span slot="title">
                                    <span>{{ $t(`menu.${item_childern.name}`) }}</span>
                                  </span>
                                    </sidebar-link>
                                </div>
                            </sidebar-link-group>
                        </div>
                    
                    </div>
                </div>
                <div v-if="auth.me.rol.id === 2">
                    <div v-for="(item, index) in nav.items">
                        <div v-if="itemById(item.permission)">
                            <sidebar-link v-if="prefixLength(item) === 0" :to="{ name: item.url }">
                        <span slot="title">
                          <span class="sidebar-menu-item-icon vuestic-icon" :class="item.icon"></span>
                          <span> {{ $t(`menu.${item.name}`) }}</span>
                        </span>
                            </sidebar-link>
                            <sidebar-link-group v-if="prefixLength(item) > 0">
                        <span slot="title">
                          <span class="sidebar-menu-item-icon vuestic-icon" :class="item.icon"></span>
                          <span>{{ $t(`menu.${item.name}`) }}</span>
                        </span>
                                <div v-for="(item_childern, index_children) in item.children">
                                    <sidebar-link :to="{ name: item_childern.url } " v-if="itemById(item_childern.permission)">
                                  <span slot="title">
                                    <span>{{ $t(`menu.${item_childern.name}`) }}</span>
                                  </span>
                                    </sidebar-link>
                                </div>
                            </sidebar-link-group>
                        </div>
                    
                    </div>
                </div>
            </div>
            <div v-if="manage">
                <div v-for="(item, index) in nav.items_event">
                    <div v-if="auth.me.rol.id === 3">
                        <div v-if="itemById(item.permission) && itemByIdTeam(item.role_team)">
                            <sidebar-link v-if="prefixLength(item) === 0" :to="{ name: item.url }">
                        <span slot="title">
                          <span class="sidebar-menu-item-icon vuestic-icon" :class="item.icon"></span>
                          <span> {{ $t(`menu.${item.name}`) }}</span>
                        </span>
                            </sidebar-link>
                            <sidebar-link-group v-if="prefixLength(item) > 0">
                        <span slot="title">
                          <span class="sidebar-menu-item-icon vuestic-icon" :class="item.icon"></span>
                          <span>{{ $t(`menu.${item.name}`) }}</span>
                        </span>
                                <div v-for="(item_childern, index_children) in item.children">
                                    <sidebar-link :to="{ name: item_childern.url } " v-if="itemById(item_childern.permission)  && itemByIdTeam(item_childern.role_team)">
                                  <span slot="title">
                                    <span>{{ $t(`menu.${item_childern.name}`) }}</span>
                                  </span>
                                    </sidebar-link>
                                </div>
                            </sidebar-link-group>
                        </div>
                    </div>
                    <div v-if="auth.me.rol.id === 2">
                        <div v-if="itemById(item.permission)">
                            <sidebar-link v-if="prefixLength(item) === 0" :to="{ name: item.url }">
                        <span slot="title">
                          <span class="sidebar-menu-item-icon vuestic-icon" :class="item.icon"></span>
                          <span> {{ $t(`menu.${item.name}`) }}</span>
                        </span>
                            </sidebar-link>
                            <sidebar-link-group v-if="prefixLength(item) > 0">
                        <span slot="title">
                          <span class="sidebar-menu-item-icon vuestic-icon" :class="item.icon"></span>
                          <span>{{ $t(`menu.${item.name}`) }}</span>
                        </span>
                                <div v-for="(item_childern, index_children) in item.children">
                                    <sidebar-link :to="{ name: item_childern.url } " v-if="itemById(item_childern.permission)">
                                  <span slot="title">
                                    <span>{{ $t(`menu.${item_childern.name}`) }}</span>
                                  </span>
                                    </sidebar-link>
                                </div>
                            </sidebar-link-group>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </vuestic-sidebar>
</template>

<script>

    import VuesticSidebar
        from '../../../../vuestic-theme/vuestic-components/vuestic-sidebar/VuesticSidebar'
    import SidebarLink from './components/SidebarLink'
    import SidebarLinkGroup from './components/SidebarLinkGroup'
    import {mapState} from 'vuex'
    import nav from '@/_nav'
    import Cookies from 'js-cookie'

    export default {
        name:       'app-sidebar',
        components: {
            VuesticSidebar,
            SidebarLink,
            SidebarLinkGroup
        },
        props:      {
            isOpen: {
                type:     Boolean,
                required: true
            }
        },
        data() {
            return {
                nav: nav,
            }
        },
        methods:    {
            itemById:     function (permission) {
                if (this.auth.permissions.length > 0) {
                    return this.auth.permissions.includes(permission)
                }

            },
            itemByIdTeam: function (roles) {
                if (this.auth.me.company_team.id_rol_team != '') {
                    return roles.includes(this.auth.me.company_team.id_rol_team)
                }

            },
            prefixLength: function (item) {
                return item.children.length
            }
        },
        computed:   {
            name() {
                return this.$route.name
            },
            list() {
                return this.$route.matched.filter((route) => route.name || route.meta.label)
            },
            ...mapState({
                auth:   state => state.auth,
                manage: state => state.event.manage,
            }),
        },

    }

</script>
