import axios from "axios";
import * as Config from "../../config";
import Cookies from "js-cookie";

const state = {
  typestation: {},
  typestations: {}
};

const actions = {
  loadTypestations({ commit, dispatch }, params) {
    commit("LOAD_TYPE_STATIONS");

    return new Promise((resolve, reject) => {
      axios
        .get(Config.apiPath + "type/station", { params })
        .then(response => {
          if (response.data.code !== 500) {
            commit("LOAD_TYPE_STATIONS_OK", response.data.data);
            resolve(response.data.data);
          } else {
            commit("LOAD_TYPE_STATIONS_FAIL");
            reject();
          }
        })
        .catch(error => {
          commit("LOAD_TYPE_STATIONS_FAIL");
          reject();
        });
    });
  },
  loadCategorystations({ commit, dispatch }, params) {
    commit("LOAD_CATEGORY_STATIONS");

    return new Promise((resolve, reject) => {
      axios
        .get(Config.apiPath + "type/station/categories")
        .then(response => {
          if (response.data.code !== 500) {
            commit("LOAD_CATEGORY_STATIONS_OK", response.data.data);
            resolve(response.data.data);
          } else {
            commit("LOAD_CATEGORY_STATIONS_FAIL");
            reject();
          }
        })
        .catch(error => {
          commit("LOAD_CATEGORY_STATIONS_FAIL");
          reject();
        });
    });
  },
  loadTypestationsActive({ commit, dispatch }) {
    commit("LOAD_TYPE_STATIONS_ACTIVE");

    return new Promise((resolve, reject) => {
      axios
        .get(Config.apiPath + "type/station/categories")
        .then(response => {
          if (response.data.code !== 500) {
            commit("LOAD_TYPE_STATIONS_ACTIVE_OK", response.data.data);
            resolve(response.data.data);
          } else {
            commit("LOAD_TYPE_STATIONS_ACTIVE_FAIL");
            reject();
          }
        })
        .catch(error => {
          commit("LOAD_TYPE_STATIONS_ACTIVE_FAIL");
          reject();
        });
    });
  },
  storeTypestation({ commit, dispatch }, form) {
    commit("STORE_TYPE_STATION");

    return new Promise((resolve, reject) => {
      axios
        .post(Config.apiPath + "type/station/save", form)
        .then(response => {
          commit("STORE_TYPE_STATION_OK", response.data.data);
          resolve(response.data);
        })
        .catch(error => {
          commit("STORE_TYPE_STATION_FAIL");
          reject(error.response.data);
        });
    });
  },
  updateTypestation({ commit, dispatch }, form) {
    commit("UPDATE_TYPE_STATION");

    return new Promise((resolve, reject) => {
      axios
        .put(Config.apiPath + "type/station/update/" + form.id, form)
        .then(response => {
          commit("UPDATE_TYPE_STATION_OK", response.data.data);
          resolve(response.data);
        })
        .catch(error => {
          commit("UPDATE_TYPE_STATION_FAIL");
          reject(error.response.data);
        });
    });
  },
  deleteTypestation({ commit, dispatch }, form) {
    commit("DELETE_TYPE_STATION");

    return new Promise((resolve, reject) => {
      axios
        .delete(Config.apiPath + "type/station/delete/" + form.id, form)
        .then(response => {
          commit("DELETE_TYPE_STATION_OK", response.data.data);
          resolve(response.data);
        })
        .catch(error => {
          commit("DELETE_TYPE_STATION_FAIL");
          reject(error.response.data);
        });
    });
  },
  loadTypestation({ commit, dispatch }, form) {
    commit("LOAD_TYPE_STATION");

    return new Promise((resolve, reject) => {
      axios
        .get(Config.apiPath + "type/station/find/" + form, { form })
        .then(response => {
          if (response.data.code !== 500) {
            commit("LOAD_TYPE_STATION_OK", response.data.data);
            resolve(response.data.data);
          } else {
            commit("LOAD_TYPE_STATION_FAIL");
            reject();
          }
        })
        .catch(error => {
          commit("LOAD_TYPE_STATION_FAIL");
          reject();
        });
    });
  }
};

const mutations = {
  STORE_TYPE_STATION_OK(state, typestation) {
    // state.STATION = STATION;
    //  state.STATION.push(STATION);
  },
  UPDATE_TYPE_STATION_OK(state, typestation) {
    state.typestation = typestation;
  },
  DELETE_TYPE_STATION_OK(state, typestation) {
    state.typestation = typestation;
  },
  LOAD_CATEGORY_STATIONS_OK(state, categorystations) {
    state.categorystations = categorystations;
  },
  LOAD_TYPE_STATIONS_OK(state, typestations) {     
    state.typestations = typestations;
  },
  LOAD_TYPE_STATIONS_ACTIVE_OK(state, typestations) {
    state.typestations = typestations;
  },
  LOAD_TYPE_STATION_OK(state, typestation) {
    state.typestation = typestation;
  }
};

export default {
  state,
  actions,
  mutations
};
