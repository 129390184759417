import axios from "axios";
import * as Config from "../../config";
import Cookies from "js-cookie";

const state = {
  user: {},
  users: {}
};

const actions = {
  loadUsers({ commit, dispatch }, id) {
    commit("LOAD_USERS");

    return new Promise((resolve, reject) => {
      axios
      .get(Config.apiPath + "companyteam/users/" + id + "")
      .then(response => {
        if (response.data.code !== 500) {
          commit("LOAD_USERS_OK", response.data.data);
          resolve(response.data.data);
        } else {
          commit("LOAD_USERS_FAIL");
          reject();
        }
      })
      .catch(error => {
        commit("LOAD_USERS_FAIL");
        reject();
      });
    });
  },
  storeUser({ commit, dispatch }, form) {
    commit("STORE_USER");

    return new Promise((resolve, reject) => {
      axios
      .post(Config.apiPath + "user", form)
      .then(response => {
        commit("STORE_USER_OK");
        resolve(response.data);
      })
      .catch(error => {
        commit("STORE_TUSER_FAIL");
        reject(error.response.data);
      });
    });
  },
  updateUser({ commit, dispatch }, form) {
    commit("UPDATE_USER");

    return new Promise((resolve, reject) => {
      axios
      .put(Config.apiPath + "user/" + form.id, form)
      .then(response => {

        commit("UPDATE_USER_OK");
        resolve(response.data);
      })
      .catch(error => {
        commit("UPDATE_USER_FAIL");
        reject(error.response.data);
      });
    });
  },
  updatePasswordAdminUser({ commit, dispatch }, form) {
    commit("UPDATE_USER_PASSWORD_ADMIN");

    return new Promise((resolve, reject) => {
      axios
      .put(Config.apiPath + "user/update_admin_password/" + form.id, form)
      .then(response => {
        commit("UPDATE_USER_PASSWORD_ADMIN_OK");
        resolve(response.data);
      })
      .catch(error => {
        commit("UPDATE_USER_PASSWORD_ADMIN_FAIL");
        reject(error.response.data);
      });
    });
  },
  deleteUser({ commit, dispatch }, form) {
    commit("DELETE_USER");

    return new Promise((resolve, reject) => {
      axios
      .delete(Config.apiPath + "user/" + form.id, form)
      .then(response => {
        commit("DELETE_USER_OK");
        resolve(response.data);
      })
      .catch(error => {
        commit("DELETE_USER_FAIL");
        reject(error.response.data);
      });
    });
  },
  loadUser({ commit, dispatch }, form) {
    commit("LOAD_USER");

    return new Promise((resolve, reject) => {
      axios
      .get(Config.apiPath + "user/" + form, { form })
      .then(response => {
        if (response.data.code !== 500) {
          commit("LOAD_USER_OK", response.data.data);
          resolve(response.data.data);
        } else {
          commit("LOAD_USER_FAIL");
          reject();
        }
      })
      .catch(error => {
        commit("LOAD_TYPE_USER_FAIL");
        reject();
      });
    });
  },
  loadRolesTeams({ commit, dispatch }) {
    //commit('LOAD_USER')

    return new Promise((resolve, reject) => {
      axios
      .get(Config.apiPath + "rol/team/active")
      .then(response => {
        if (response.data.code !== 500) {
            //commit('LOAD_USER_OK', response.data.data)
            resolve(response.data.data);
          } else {
            //commit('LOAD_USER_FAIL')
            reject();
          }
        })
      .catch(error => {
          //commit('LOAD_TYPE_USER_FAIL')
          reject();
        });
    });
  },
  updateName({ commit, dispatch }, form) {
    commit("UPDATE_USER_NAME");

    return new Promise((resolve, reject) => {
      axios
      .put(Config.apiPath + "user/update_name/" + form.id, form)
      .then(response => {

        commit("UPDATE_USER_NAME_OK");
        resolve(response.data);
      })
      .catch(error => {
        commit("UPDATE_USER_NAME_FAIL");
        reject(error.response.data);
      });
    });
  },
  updatePassword({ commit, dispatch }, form) {
    commit("UPDATE_USER_PASSWORD");

    return new Promise((resolve, reject) => {
      axios.put(Config.apiPath + "user/update_password/" + form.id, form)
      .then(response => {
        commit("UPDATE_USER_PASSWORD_OK");
        resolve(response.data)
      })
      .catch(error => {
        commit("UPDATE_USER_PASSWORD_FAIL");
        reject(error.response.data)
      });
    });
  },
  activeChangeUsers({commit, dispatch}, id) {

    return new Promise((resolve, reject) => {
      axios.post(Config.apiPath + 'user/change/active/' + id)
      .then(
        response => {
          resolve(response.data)
        })
      .catch(error => {
        reject(error.data)
      })
    })
  }

};

const mutations = {
  UPDATE_USER_OK(state, user) {
    state.user = user;
  },
  UPDATE_USER_NAME_OK(state, user) {
    state.user = user;
  },  
  USER(state, user) {
    state.user = user;
  },
  LOAD_USERS_OK(state, users) {
    state.users = users;
  },
  LOAD_USER_OK(state, user) {
    state.user = user;
  }
};

export default {
  state,
  actions,
  mutations
};
