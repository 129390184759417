<template>
    <div class="profile-dropdown">
    <span class="profile-dropdown__avatar-container">
      <slot />
    </span>
        <vuestic-dropdown v-model="isShown" position="bottom">
            <div v-for="option in options" :key="option.name" class="dropdown-item plain-link-item">
               <!-- <router-link :to="{name: option.redirectTo}" class="plain-link" href="#">
                    {{ $t(`user.${option.name}`) }}
                </router-link> -->
                 <a class="plain-link"  @click="goToProfile">
                    {{ $t(`user.${option.name}`) }}
                </a>
            </div>
            <div  class="dropdown-item plain-link-item">
                <a class="plain-link"  @click="logout_layout">
                    {{$t('user.logout')}}
                </a>
            </div>
            <div  class="dropdown-item plain-link-item">
                <a class="plain-link"  @click="register_public">
                    {{$t('user.registerpublic')}}
                </a>
            </div>
        </vuestic-dropdown>
    </div>
</template>

<script>
import {
  mapState,
  mapActions
} from 'vuex'

    export default {
        name:  'profile-section',
        data() {
            return {
                isShown: false,
            }
        },
        props: {
            options: {
                type:    Array,
                default: () => [
                    {
                        name:       'profile',
                        redirectTo: 'profile',
                    },
                ],
            },
        },
        methods:{
            ...mapActions([
                'logout',
                'addToastMessage',
            ]),
            goToProfile: function () {

               this.$router.replace('../profile');
            },
             register_public: function () {

              let routeData = this.$router.resolve({name: 'Public_Register',query: {slug: "Event"}});
              console.log(routeData.href);
              window.open('../'+this.slugevent+'/registro/', '_blank');
            },
            logout_layout: function () {
                this.logout()
                    .then((data) => {
                        this.addToastMessage({
                            text: data.message,
                            type: 'warning'
                        })
                        this.$router.replace('/auth/login')
                    })
                    .catch((data) => {
                        this.error  = data.message
                        //                    this.$router.go(-1)
                    })
            },
        },
         computed: {
    ...mapState({
      userktags: state => state.ktag.userktag,
      slugevent: state => state.event.event.config_event.slug
    })}
    }
</script>

<style lang="scss">
    @import '../../../../../../vuestic-theme/vuestic-sass/resources/resources';

    .profile-dropdown {
        @include flex-center();
        cursor: pointer;

        &__avatar-container {
            display: inline-block;
            width: 50px;
            height: 50px;
            background-color: white;
            border-radius: 50%;
            border: 2px solid $lighter-gray;
            overflow: hidden;

            img {
                height: 100%;
                width: 100%;
            }
        }
    }
</style>
