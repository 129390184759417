import axios from 'axios'
import * as Config from '../../config'
import Cookies from "js-cookie"

const state = {
    typeteam: {},
    team:     {},
    teams:    {}
}

const actions = {

    loadTypeTeam({commit, dispatch}, params) {
        commit('LOAD_TYPE_TEAMS')

        return new Promise((resolve, reject) => {
            axios.get(Config.apiPath + 'type/team', {params})
                .then(
                    response => {
                        if (response.data.code !== 500) {
                            commit('LOAD_TYPE_TEAM_OK', response.data.data)
                            resolve()
                        } else {
                            commit('LOAD_TYPE_TEAM_FAIL')
                            reject()
                        }

                    })
                .catch(error => {
                    commit('LOAD_TYPE_TEAM_FAIL')
                    reject()
                })
        })
    },
    loadTeams({commit, dispatch}, params) {
        commit('LOAD_TEAMS')

        return new Promise((resolve, reject) => {
            axios.get(Config.apiPath + 'team', {params})
                .then(
                    response => {
                        if (response.data.code !== 500) {
                            commit('LOAD_TEAMS_OK', response.data.data)
                            resolve(response.data.data)
                        } else {
                            commit('LOAD_TEAMS_FAIL')
                            reject()
                        }

                    })
                .catch(error => {
                    commit('LOAD_TEAMS_FAIL')
                    reject()
                })
        })
    },
    storeTeam({commit, dispatch}, form) {
        commit('STORE_TEAM')

        return new Promise((resolve, reject) => {
            axios.post(Config.apiPath + 'team', form)
                .then(
                    response => {
                        
                        commit('STORE_TEAM_OK')
                        resolve(response.data)
                    })
                .catch(error => {
                    commit('STORE_TEAM_FAIL')
                    reject(error.response.data)
                })
        })

    },
    updateTeam({commit, dispatch}, form) {
        commit('UPDATE_TEAM')

        return new Promise((resolve, reject) => {
            axios.put(Config.apiPath + 'team/' + form.id, form)
                .then(
                    response => {
                        
                        commit('UPDATE_TEAM_OK')
                        resolve(response.data)
                    })
                .catch(error => {
                    commit('UPDATE_TEAM_FAIL')
                    reject(error.response.data)
                })
        })


    },
    deleteTeam({commit, dispatch}, form) {
        commit('DELETE_TEAM')

        return new Promise((resolve, reject) => {

            axios.delete(Config.apiPath + 'team/' + form.id, form)
                .then(
                    response => {
                        
                        commit('DELETE_TEAM_OK')
                        resolve(response.data)
                    })
                .catch(error => {
                    commit('DELETE_TEAM_FAIL')
                    reject(error.response.data)
                })
        })

    },
    loadTeam({commit, dispatch}, form) {
        commit('LOAD_TEAM')

        return new Promise((resolve, reject) => {
            axios.get(Config.apiPath + 'team/' + form, {form})
                .then(
                    response => {
                        if (response.data.code !== 500) {
                            commit('LOAD_TEAM_OK', response.data.data)
                            resolve(response.data.data)
                        } else {
                            commit('LOAD_TEAM_FAIL')
                            reject()
                        }

                    })
                .catch(error => {
                    commit('LOAD_TYPE_TEAM_FAIL')
                    reject()
                })
        })
    }

}

const mutations = {
    LOAD_TYPE_TEAM_OK(state, typeteam) {
        state.typeteam = typeteam
    },
    // STORE_TEAM_OK(state, team) {
    //     state.team = team;
    //     //AGREGAR AQUI A TEAMS EL NUEVO TEAM
    //     state.teams.push(team);
    // },
    UPDATE_TEAM_OK(state, team) {
        state.team = team;
        //AGREGAR AQUI A TEAMS EL NUEVO TEAM
        // state.teams.push(team);
    },
    TEAM(state, team) {
        state.team = team;
        //AGREGAR AQUI A TEAMS EL NUEVO TEAM
        // state.teams.push(team);
    },
    LOAD_TEAMS_OK(state, teams) {
        state.teams = teams;
    },
    LOAD_TEAM_OK(state, team) {
        state.team = team;
    }

}

export default {
    state,
    actions,
    mutations
}
