import axios from "axios";
import * as Config from "../../config";
import Cookies from "js-cookie";

const state = {
  typeproductstation: {},
  productstation: {},
  productstations: {}
};

const actions = {
  loadProductsByStation({ commit, dispatch }, idstation) {
    commit("LOAD_PRODUCTS_BY_STATION");

    return new Promise((resolve, reject) => {
      axios
        .get(
          Config.apiPath + "product_station/filterby/id_station/" + idstation
        )
        .then(response => {
          if (response.data.code !== 500) {
            commit("LOAD_PRODUCTS_BY_STATION_OK", response.data.data);
            resolve(response.data.data);
          } else {
            commit("LOAD_PRODUCTS_BY_STATION_FAIL");
            reject();
          }
        })
        .catch(error => {
          commit("LOAD_PRODUCTS_BY_STATION_FAIL");
          reject();
        });
    });
  },
  storeProductStation({ commit, dispatch }, form) {
    commit("STORE_PRODUCT_BY_STATION");

    return new Promise((resolve, reject) => {
      axios
        .post(Config.apiPath + "product_station", form)
        .then(response => {
          commit("STORE_PRODUCT_BY_STATION_OK", response.data.data);
          resolve(response.data);
        })
        .catch(error => {
          commit("STORE_PRODUCT_BY_STATION_FAIL");
          reject(error.response.data);
        });
    });
  },
  updateProductStation({ commit, dispatch }, form) {
    commit("UPDATE_PRODUCT_BY_STATION");

    return new Promise((resolve, reject) => {
      axios
        .put(Config.apiPath + "product_station/" + form.id, form)
        .then(response => {
          commit("UPDATE_PRODUCT_BY_STATION_OK", response.data.data);
          resolve(response.data);
        })
        .catch(error => {
          commit("UPDATE_PRODUCT_BY_STATION_FAIL");
          reject(error.response.data);
        });
    });
  },
  deleteProductStation({ commit, dispatch }, form) {
    commit("DELETE_PRODUCT_BY_STATION");

    return new Promise((resolve, reject) => {
      axios
        .delete(Config.apiPath + "product_station/" + form.id, form)
        .then(response => {
          commit("DELETE_PRODUCT_BY_STATION_OK", response.data.data);
          resolve(response.data);
        })
        .catch(error => {
          commit("DELETE_PRODUCT_BY_STATION_FAIL");
          reject(error.response.data);
        });
    });
  },
  loadProductStation({ commit, dispatch }, id) {
    commit("LOAD_PRODUCT_BY_STATION");

    return new Promise((resolve, reject) => {
      axios
        .get(Config.apiPath + "product_station/" + id)
        .then(response => {
          if (response.data.code !== 500) {
            commit("LOAD_PRODUCT_BY_STATION_OK");
            resolve(response.data.data);
          } else {
            commit("LOAD_PRODUCT_BY_STATION_FAIL");
            reject(response.data);
          }
        })
        .catch(error => {
          commit("LOAD_PRODUCT_BY_STATION_FAIL");
          reject(error.data);
        });
    });
  }
};

const mutations = {
  STORE_PRODUCT_BY_STATION_OK(state, productstation) {
    
    if(productstation.length === 0){
    }else{
      state.productstation = productstation;
      state.productstations.push(productstation);
    }
    
  },
  UPDATE_PRODUCT_BY_STATION_OK(state, productstation) {
    state.productstation = productstation;
  },
  DELETE_PRODUCT_BY_STATION_OK(state, productstation) {
    state.productstation = productstation;
  },
  LOAD_PRODUCTS_BY_STATION_OK(state, productstations) {
    state.productstations = productstations;
  },
  LOAD_PRODUCT_BY_STATION_OK(state, productstation) {
    state.productstation = productstation;
  }
};

export default {
  state,
  actions,
  mutations
};
