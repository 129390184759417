import axios from 'axios'
import * as Config from '../../config'
import Cookies from "js-cookie"

const state = {
    typecredit: {},   
    typecredits:{}
}

const actions = {


    loadTypecredits({commit, dispatch}, params) {
        commit('LOAD_TYPE_CREDITS')

        return new Promise((resolve, reject) => {
            axios.get(Config.apiPath + 'type/credit', {params})
            .then(
                response => {
                    if (response.data.code !== 500) {
                        commit('LOAD_TYPE_CREDITS_OK', response.data.data)
                        resolve(response.data.data)
                    } else {
                        commit('LOAD_TYPE_CREDITS_FAIL')
                        reject()
                    }

                })
            .catch(error => {
                commit('LOAD_TYPE_CREDITS_FAIL')
                reject()
            })
        })
    },
    loadTypecreditsActive({commit, dispatch}, params) {
        commit('LOAD_TYPE_CREDITS_ACTIVE')

        return new Promise((resolve, reject) => {
            axios.get(Config.apiPath + 'type/credit/active', {params})
            .then(
                response => {
                    if (response.data.code !== 500) {
                        commit('LOAD_TYPE_CREDITS_ACTIVE_OK', response.data.data)
                        resolve(response.data.data)
                    } else {
                        commit('LOAD_TYPE_CREDITS_ACTIVE_FAIL')
                        reject()
                    }

                })
            .catch(error => {
                commit('LOAD_TYPE_CREDITS_ACTIVE_FAIL')
                reject()
            })
        })
    },
    storeTypecredit({commit, dispatch}, form) {
        commit('STORE_TYPE_CREDIT')

        return new Promise((resolve, reject) => {
            axios.post(Config.apiPath + 'type/credit/save', form)
            .then(
                response => {

                    commit('STORE_TYPE_CREDIT_OK', response.data.data)                       
                    resolve(response.data)
                })
            .catch(error => {

                commit('STORE_TYPE_CREDIT_FAIL')
                reject(error.response.data)
            })
        })

    },
    updateTypecredit({commit, dispatch}, form) {
        commit('UPDATE_TYPE_CREDIT')

        return new Promise((resolve, reject) => {
            axios.put(Config.apiPath + 'type/credit/update/'+form.id,form)
            .then(
                response => {
                    commit('UPDATE_TYPE_CREDIT_OK', response.data.data)                       
                    resolve(response.data)
                })
            .catch(error => {
                commit('UPDATE_TYPE_CREDIT_FAIL')
                reject(error.response.data)
            })
        })


    },
    deleteTypecredit({commit, dispatch}, form) {
        commit('DELETE_TYPE_CREDIT')

        return new Promise((resolve, reject) => {

            axios.delete(Config.apiPath + 'type/credit/delete/'+form.id, form)
            .then(
                response => {

                    commit('DELETE_TYPE_CREDIT_OK', response.data.data)                       
                    resolve(response.data)
                })
            .catch(error => {
                commit('DELETE_TYPE_CREDIT_FAIL')
                reject(error.response.data)
            })
        })

    },
    loadTypecredit({commit, dispatch}, form) {
        commit('LOAD_TYPE_CREDIT')

        return new Promise((resolve, reject) => {
            axios.get(Config.apiPath + 'type/credit/find/' + form, {form})
            .then(
                response => {
                    if (response.data.code !== 500) {
                        commit('LOAD_TYPE_CREDIT_OK', response.data.data)
                        resolve(response.data.data)
                    } else {
                        commit('LOAD_TYPE_CREDIT_FAIL')
                        reject()
                    }

                })
            .catch(error => {
                commit('LOAD_TYPE_CREDIT_FAIL')
                reject()
            })
        })
    },
    activeChangeTypeCredit({commit, dispatch}, id) {

        return new Promise((resolve, reject) => {
          axios.post(Config.apiPath + 'type/credit/change/active/' + id)
          .then(
            response => {
              resolve(response.data)
          })
          .catch(error => {
            reject(error.data)
        })
      })
    }
}

const mutations = {

    STORE_TYPE_CREDIT_OK(state, typecredit) {
       // state.CREDIT = CREDIT;       
      //  state.CREDIT.push(CREDIT);
  },
  UPDATE_TYPE_CREDIT_OK(state, typecredit) {
    state.typecredit = typecredit;      
},
DELETE_TYPE_CREDIT_OK(state, typecredit) {
    state.typecredit = typecredit;      
},
LOAD_TYPE_CREDITS_OK(state,typecredits)
{
    state.typecredits = typecredits;
},
LOAD_TYPE_CREDIT_OK(state,typecredit)
{
    state.typecredit = typecredit;
}

}

export default {
    state,
    actions,
    mutations
}
