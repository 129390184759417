import axios from 'axios'
import * as Config from '../../config'
import Cookies from 'js-cookie'

const state = {
  product: {},
  products: {}
}

const actions = {

  loadProducts ({ commit, dispatch }) {
    commit('LOAD_PRODUCTS')

    return new Promise((resolve, reject) => {
      axios.get(Config.apiPath + 'product')
      .then(
        response => {
          if (response.data.code !== 500) {
            commit('LOAD_PRODUCTS_OK', response.data.data)
            resolve(response.data.data)
          } else {
            commit('LOAD_PRODUCTS_FAIL')
            reject()
          }
        })
      .catch(error => {
        commit('LOAD_PRODUCTS_FAIL')
        reject()
      })
    })
  },
  storeProduct ({ commit, dispatch }, form) {
    commit('STORE_PRODUCT')

    return new Promise((resolve, reject) => {
      axios.post(Config.apiPath + 'product/save', form)
      .then(
        response => {
          commit('STORE_PRODUCT_OK')
          resolve(response.data)
        })
      .catch(error => {
        commit('STORE_PRODUCT_FAIL')
        reject(error.response.data)
      })
    })
  },
  updateProduct ({ commit, dispatch }, form) {
    commit('UPDATE_PRODUCT')

    return new Promise((resolve, reject) => {
      axios.put(Config.apiPath + 'product/' + form.id, form)
      .then(
        response => {
          commit('UPDATE_PRODUCT_OK')
          resolve(response.data)
        })
      .catch(error => {
        commit('UPDATE_PRODUCT_FAIL')
        reject(error.response.data)
      })
    })
  },
  loadProduct ({ commit, dispatch }, form) {
    commit('LOAD_PRODUCT')

    return new Promise((resolve, reject) => {
      axios.get(Config.apiPath + 'product/' + form, { form })
      .then(
        response => {
          if (response.data.code !== 500) {
            commit('LOAD_PRODUCT_OK', response.data.data)
            resolve(response.data.data)
          } else {
            commit('LOAD_PRODUCT_FAIL')
            reject()
          }
        })
      .catch(error => {
        commit('LOAD_PRODUCT_FAIL')
        reject()
      })
    })
  },
  loadProductsByCompany ({ commit, dispatch }, idcompany) {
    commit('LOAD_PRODUCTS_BY_COMPANY')

    return new Promise((resolve, reject) => {
      axios.get(Config.apiPath + 'product/filterby/id_company/' + idcompany)
      .then(
        response => {
          if (response.data.code !== 500) {
            commit('LOAD_PRODUCTS_BY_COMPANY_OK', response.data.data)
            resolve(response.data.data)
          } else {
            commit('LOAD_PRODUCTS_BY_COMPANY_FAIL')
            reject()
          }
        })
      .catch(error => {
        commit('LOAD_PRODUCTS_BY_COMPANY_FAIL')
        reject()
      })
    })
  },
  loadTypeProduct ({ commit, dispatch }) {
    commit('LOAD_TYPE_PRODUCT')

    return new Promise((resolve, reject) => {
      axios.get(Config.apiPath + 'type/product/active')
      .then(
        response => {
          if (response.data.code !== 500) {
            commit('LOAD_TYPE_PRODUCT_OK')
            resolve(response.data.data)
          } else {
            commit('LOAD_TYPE_PRODUCT_FAIL')
            reject()
          }
        })
      .catch(error => {
        commit('LOAD_TYPE_PRODUCT_FAIL')
        reject()
      })
    })
  },
  activeChangeProduct({commit, dispatch}, id) {

    return new Promise((resolve, reject) => {
      axios.post(Config.apiPath + 'product/change/active/' + id)
      .then(
        response => {
          resolve(response.data)
        })
      .catch(error => {
        reject(error.data)
      })
    })
  }

}

const mutations = {
  LOAD_PRODUCT_OK (state, products) {
    state.product = products
  },
  LOAD_PRODUCTS_OK (state, products) {
    state.products = products
  },
  LOAD_PRODUCTS_BY_COMPANY_OK (state, products) {
    state.products = products
  }
  
}

export default {
  state,
  actions,
  mutations
}
