/**
 * Base api path
 *
 * @type {string}
 */
const permission = {
    LOGIN:  1,
    ROOT:   2,
    ADMIN:  3,
    COMPANY: 4,
}

export default Object.freeze(permission)