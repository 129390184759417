import axios from 'axios'
import * as Config from '../../config'
import Cookies from "js-cookie"

const state = {
    typeproduct: {},   
    typeproducts:{}
}

const actions = {

   
    loadTypeproducts({commit, dispatch}, params) {
        commit('LOAD_TYPE_PRODUCTS')

        return new Promise((resolve, reject) => {
            axios.get(Config.apiPath + 'type/product', {params})
                .then(
                    response => {
                        if (response.data.code !== 500) {
                            commit('LOAD_TYPE_PRODUCTS_OK', response.data.data)
                            resolve(response.data.data)
                        } else {
                            commit('LOAD_TYPE_PRODUCTS_FAIL')
                            reject()
                        }

                    })
                .catch(error => {
                    commit('LOAD_TYPE_PRODUCTS_FAIL')
                    reject()
                })
        })
    },
    loadTypeProductsActive({commit, dispatch}, params) {
        commit('LOAD_TYPE_PRODUCTS_ACTIVE')

        return new Promise((resolve, reject) => {
            axios.get(Config.apiPath + 'type/product/active', {params})
                .then(
                    response => {
                        if (response.data.code !== 500) {
                            commit('LOAD_TYPE_PRODUCTS_ACTIVE_OK', response.data.data)
                            resolve(response.data.data)
                        } else {
                            commit('LOAD_TYPE_PRODUCTS_ACTIVE_FAIL')
                            reject()
                        }

                    })
                .catch(error => {
                    commit('LOAD_TYPE_PRODUCTS_ACTIVE_FAIL')
                    reject()
                })
        })
    },
    storeTypeproduct({commit, dispatch}, form) {
        commit('STORE_TYPE_PRODUCT')

        return new Promise((resolve, reject) => {
            axios.post(Config.apiPath + 'type/product/save', form)
                .then(
                    response => {
                        
                        commit('STORE_TYPE_PRODUCT_OK', response.data.data)                       
                        resolve(response.data)
                    })
                .catch(error => {
                   
                    commit('STORE_TYPE_PRODUCT_FAIL')
                    reject(error.response.data)
                })
        })

    },
    updateTypeproduct({commit, dispatch}, form) {
        commit('UPDATE_TYPE_PRODUCT')

        return new Promise((resolve, reject) => {
            axios.put(Config.apiPath + 'type/product/update/'+form.id,form)
                .then(
                    response => {
                        commit('UPDATE_TYPE_PRODUCT_OK', response.data.data)                       
                        resolve(response.data)
                    })
                .catch(error => {
                    commit('UPDATE_TYPE_PRODUCT_FAIL')
                    reject(error.response.data)
                })
        })


    },
    deleteTypeproduct({commit, dispatch}, form) {
        commit('DELETE_TYPE_PRODUCT')

        return new Promise((resolve, reject) => {
           
            axios.delete(Config.apiPath + 'type/product/delete/'+form.id, form)
                .then(
                    response => {
                        
                        commit('DELETE_TYPE_PRODUCT_OK', response.data.data)                       
                        resolve(response.data)
                    })
                .catch(error => {
                    commit('DELETE_TYPE_PRODUCT_FAIL')
                    reject(error.response.data)
                })
        })

    },
    loadTypeproduct({commit, dispatch}, form) {
        commit('LOAD_TYPE_PRODUCT')

        return new Promise((resolve, reject) => {
            axios.get(Config.apiPath + 'type/product/find/' + form, {form})
                .then(
                    response => {
                        if (response.data.code !== 500) {
                            commit('LOAD_TYPE_PRODUCT_OK', response.data.data)
                            resolve(response.data.data)
                        } else {
                            commit('LOAD_TYPE_PRODUCT_FAIL')
                            reject()
                        }

                    })
                .catch(error => {
                    commit('LOAD_TYPE_PRODUCT_FAIL')
                    reject()
                })
        })
    }
}

const mutations = {
   
    STORE_TYPE_PRODUCT_OK(state, typeproduct) {
       // state.PRODUCT = PRODUCT;       
      //  state.PRODUCT.push(PRODUCT);
    },
    UPDATE_TYPE_PRODUCT_OK(state, typeproduct) {
        state.typeproduct = typeproduct;      
    },
    DELETE_TYPE_PRODUCT_OK(state, typeproduct) {
        state.typeproduct = typeproduct;      
    },
    LOAD_TYPE_PRODUCTS_OK(state,typeproducts)
    {
        state.typeproducts = typeproducts;
    },
    LOAD_TYPE_PRODUCT_OK(state,typeproduct)
    {
        state.typeproduct = typeproduct;
    }

}

export default {
    state,
    actions,
    mutations
}
