import axios from 'axios'
import * as Config from '../../config'
import Cookies from "js-cookie"

const state = {
    typeevent: {},   
    typeevents:{}
}

const actions = {

   
    loadTypeevents({commit, dispatch}, params) {
        commit('LOAD_TYPE_EVENTS')

        return new Promise((resolve, reject) => {
            axios.get(Config.apiPath + 'type/event', {params})
                .then(
                    response => {
                        if (response.data.code !== 500) {
                            commit('LOAD_TYPE_EVENTS_OK', response.data.data)
                            resolve(response.data.data)
                        } else {
                            commit('LOAD_TYPE_EVENTS_FAIL')
                            reject()
                        }

                    })
                .catch(error => {
                    commit('LOAD_TYPE_EVENTS_FAIL')
                    reject()
                })
        })
    },
    loadTypeeventsActive({commit, dispatch}, params) {
        commit('LOAD_TYPE_EVENTS_ACTIVE')

        return new Promise((resolve, reject) => {
            axios.get(Config.apiPath + 'type/event/active', {params})
                .then(
                    response => {
                        if (response.data.code !== 500) {
                            commit('LOAD_TYPE_EVENTS_ACTIVE_OK', response.data.data)
                            resolve(response.data.data)
                        } else {
                            commit('LOAD_TYPE_EVENTS_ACTIVE_FAIL')
                            reject()
                        }

                    })
                .catch(error => {
                    commit('LOAD_TYPE_EVENTS_ACTIVE_FAIL')
                    reject()
                })
        })
    },
    storeTypeevent({commit, dispatch}, form) {
        commit('STORE_TYPE_EVENT')

        return new Promise((resolve, reject) => {
            axios.post(Config.apiPath + 'type/event/save', form)
                .then(
                    response => {
                        
                        commit('STORE_TYPE_EVENT_OK', response.data.data)                       
                        resolve(response.data)
                    })
                .catch(error => {
                   
                    commit('STORE_TYPE_EVENT_FAIL')
                    reject(error.response.data)
                })
        })

    },
    updateTypeevent({commit, dispatch}, form) {
        commit('UPDATE_TYPE_EVENT')

        return new Promise((resolve, reject) => {
            axios.put(Config.apiPath + 'type/event/update/'+form.id,form)
                .then(
                    response => {
                        commit('UPDATE_TYPE_EVENT_OK', response.data.data)                       
                        resolve(response.data)
                    })
                .catch(error => {
                    commit('UPDATE_TYPE_EVENT_FAIL')
                    reject(error.response.data)
                })
        })


    },
    deleteTypeevent({commit, dispatch}, form) {
        commit('DELETE_TYPE_EVENT')

        return new Promise((resolve, reject) => {
           
            axios.delete(Config.apiPath + 'type/event/delete/'+form.id, form)
                .then(
                    response => {
                        
                        commit('DELETE_TYPE_EVENT_OK', response.data.data)                       
                        resolve(response.data)
                    })
                .catch(error => {
                    commit('DELETE_TYPE_EVENT_FAIL')
                    reject(error.response.data)
                })
        })

    },
    loadTypeevent({commit, dispatch}, form) {
        commit('LOAD_TYPE_EVENT')

        return new Promise((resolve, reject) => {
            axios.get(Config.apiPath + 'type/event/find/' + form, {form})
                .then(
                    response => {
                        if (response.data.code !== 500) {
                            commit('LOAD_TYPE_EVENT_OK', response.data.data)
                            resolve(response.data.data)
                        } else {
                            commit('LOAD_TYPE_EVENT_FAIL')
                            reject()
                        }

                    })
                .catch(error => {
                    commit('LOAD_TYPE_EVENT_FAIL')
                    reject()
                })
        })
    }
}

const mutations = {
   
    STORE_TYPE_EVENT_OK(state, typeevent) {
       // state.EVENT = EVENT;       
      //  state.EVENT.push(EVENT);
    },
    UPDATE_TYPE_EVENT_OK(state, typeevent) {
        state.typeevent = typeevent;      
    },
    DELETE_TYPE_EVENT_OK(state, typeevent) {
        state.typeevent = typeevent;      
    },
    LOAD_TYPE_EVENTS_OK(state,typeevents)
    {
        state.typeevents = typeevents;
    },
    LOAD_TYPE_EVENT_OK(state,typeevent)
    {
        state.typeevent = typeevent;
    }

}

export default {
    state,
    actions,
    mutations
}
