import axios from 'axios'
import * as Config from '../../config'
import Cookies from "js-cookie"

const state = {
    typestaff: {},   
    typestaffs:{}
}

const actions = {

   
    loadTypestaffs({commit, dispatch}, params) {
        commit('LOAD_TYPE_STAFFS')

        return new Promise((resolve, reject) => {
            axios.get(Config.apiPath + 'type/staff', {params})
                .then(
                    response => {
                        if (response.data.code !== 500) {
                            commit('LOAD_TYPE_STAFFS_OK', response.data.data)
                            resolve(response.data.data)
                        } else {
                            commit('LOAD_TYPE_STAFFS_FAIL')
                            reject()
                        }

                    })
                .catch(error => {
                    commit('LOAD_TYPE_STAFFS_FAIL')
                    reject()
                })
        })
    },
    loadTypestaffsActive({commit, dispatch}, params) {
        commit('LOAD_TYPE_STAFFS_ACTIVE')

        return new Promise((resolve, reject) => {
            axios.get(Config.apiPath + 'type/staff/active', {params})
                .then(
                    response => {
                        if (response.data.code !== 500) {
                            commit('LOAD_TYPE_STAFFS_ACTIVE_OK', response.data.data)
                            resolve(response.data.data)
                        } else {
                            commit('LOAD_TYPE_STAFFS_ACTIVE_FAIL')
                            reject()
                        }

                    })
                .catch(error => {
                    commit('LOAD_TYPE_STAFFS_ACTIVE_FAIL')
                    reject()
                })
        })
    },
    storeTypestaff({commit, dispatch}, form) {
        commit('STORE_TYPE_STAFF')

        return new Promise((resolve, reject) => {
            axios.post(Config.apiPath + 'type/staff/save', form)
                .then(
                    response => {
                        
                        commit('STORE_TYPE_STAFF_OK', response.data.data)                       
                        resolve(response.data)
                    })
                .catch(error => {
                   
                    commit('STORE_TYPE_STAFF_FAIL')
                    reject(error.response.data)
                })
        })

    },
    updateTypestaff({commit, dispatch}, form) {
        commit('UPDATE_TYPE_STAFF')

        return new Promise((resolve, reject) => {
            axios.put(Config.apiPath + 'type/staff/update/'+form.id,form)
                .then(
                    response => {
                        commit('UPDATE_TYPE_STAFF_OK', response.data.data)                       
                        resolve(response.data)
                    })
                .catch(error => {
                    commit('UPDATE_TYPE_STAFF_FAIL')
                    reject(error.response.data)
                })
        })


    },
    deleteTypestaff({commit, dispatch}, form) {
        commit('DELETE_TYPE_STAFF')

        return new Promise((resolve, reject) => {
           
            axios.delete(Config.apiPath + 'type/staff/delete/'+form.id, form)
                .then(
                    response => {
                        
                        commit('DELETE_TYPE_STAFF_OK', response.data.data)                       
                        resolve(response.data)
                    })
                .catch(error => {
                    commit('DELETE_TYPE_STAFF_FAIL')
                    reject(error.response.data)
                })
        })

    },
    loadTypestaff({commit, dispatch}, form) {
        commit('LOAD_TYPE_STAFF')

        return new Promise((resolve, reject) => {
            axios.get(Config.apiPath + 'type/staff/find/' + form, {form})
                .then(
                    response => {
                        if (response.data.code !== 500) {
                            commit('LOAD_TYPE_STAFF_OK', response.data.data)
                            resolve(response.data.data)
                        } else {
                            commit('LOAD_TYPE_STAFF_FAIL')
                            reject()
                        }

                    })
                .catch(error => {
                    commit('LOAD_TYPE_STAFF_FAIL')
                    reject()
                })
        })
    }
}

const mutations = {
   
    STORE_TYPE_STAFF_OK(state, typestaff) {
       // state.STAFF = STAFF;       
      //  state.STAFF.push(STAFF);
    },
    UPDATE_TYPE_STAFF_OK(state, typestaff) {
        state.typestaff = typestaff;      
    },
    DELETE_TYPE_STAFF_OK(state, typestaff) {
        state.typestaff = typestaff;      
    },
    LOAD_TYPE_STAFFS_OK(state,typestaffs)
    {
        state.typestaffs = typestaffs;
    },
    LOAD_TYPE_STAFF_OK(state,typestaff)
    {
        state.typestaff = typestaff;
    }

}

export default {
    state,
    actions,
    mutations
}
