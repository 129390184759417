export default {
  root: {
    name: '/',
    displayName: 'Home'
  },
  routes: [
    {
      name: 'dashboard',
      displayName: 'menu.dashboard'
    },
    {
      name: 'event',
      displayName: 'menu.event',
        children: [
            {
                name: 'new_event',
                displayName: 'view.event.new'
            },
        ]
    }
  ]
}
