<template>
  <svg class="vuestic-icon-vuestic" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 129 15.4" style="enable-background:new 0 0 129 15.4;" xml:space="preserve">
    <g>
      <path class="st0" d="M0,0.5h3.7l2.9,6.9l2.9-6.9h3.7L6.8,15.4H6.4L0,0.5z"/>
      <path class="st0" d="M22.2,0.5h3.5v9c0,1.9,0.9,2.6,2.1,2.6c1.3,0,2.2-0.7,2.2-2.6v-9h3.5V10c0,3.4-2.5,5.3-5.7,5.3
        c-3.3,0-5.6-2-5.6-5.3V0.5z"/>
      <path class="st0" d="M43.9,0.5h9.5v3.1h-6v2.6H53v3.1h-5.5v2.6h6.3v3h-9.8V0.5z"/>
      <path class="st0" d="M66.7,10.7c0,0.8,0.7,1.5,1.5,1.5c0.9,0,1.4-0.5,1.4-1.2c0-1.1-1.4-1.4-2.6-1.8c-2.4-0.9-3.8-2-3.8-4.5
        c0-2.5,2.2-4.7,4.9-4.7c3.2,0,4.7,2,4.9,4.7h-3.3c0-0.8-0.5-1.5-1.5-1.5c-0.8,0-1.5,0.5-1.5,1.4c0,1.1,1.3,1.3,2.5,1.6
        C71.8,7,73,8.4,73,10.7c0,2.5-2.2,4.7-4.9,4.7c-3.1,0-4.9-2.1-4.9-4.7H66.7z"/>
      <path class="st0" d="M85.4,3.6h-3.2V0.5h10v3.1H89v11.3h-3.6V3.6z"/>
      <path class="st0" d="M101.5,0.5h3.5v14.4h-3.5V0.5z"/>
      <path class="st0" d="M122.8,0c2.7,0,4.6,1,6.2,2.6l-2.4,2.3c-1-1-2.3-1.6-3.7-1.6c-2.6,0-4.2,1.9-4.2,4.3s1.7,4.3,4.2,4.3
        c1.4,0,2.7-0.6,3.7-1.6l2.4,2.3c-1.4,1.5-3.4,2.6-6.1,2.6c-4.6,0-7.8-3.4-7.8-7.7S118.2,0,122.8,0z"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'vuestic-icon-vuestic'
}
</script>

<style lang="scss">
  .vuestic-icon-vuestic {
    display: inline-block;
    width: 129px;
    height: 15.4px;
    .st0 {
      fill: #4AE387;
    }
  }
</style>
