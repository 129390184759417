import Permission from './permission'
import Role_Teams from './role_teams'

export default {
    items:       [
        {
            name:       'dashboard',
            url:        'dashboard',
            icon:       'vuestic-icon-dashboard',
            permission: Permission.LOGIN,
            role_team:  [
                Role_Teams.ADMIN,
                Role_Teams.OWNER,
                Role_Teams.ASSISTANTS,
            ],
            children:   []
        },
        {
            name:       'event',
            url:        'event',
            icon:       'vuestic-icon-extras',
            permission: Permission.COMPANY,
            role_team:  [
                Role_Teams.ADMIN,
                Role_Teams.OWNER,
                Role_Teams.ASSISTANTS,
            ],
            children:   []
        },
        {
            name:       'user',
            url:        'user',
            icon:       'vuestic-icon-user',
            permission: Permission.COMPANY,
            role_team:  [
                Role_Teams.ADMIN,
                Role_Teams.OWNER,
            ],
            children:   []
        },
        {
            name:       'workteam',
            url:        'workteam',
            icon:       'vuestic-icon-components',
            permission: Permission.COMPANY,
            role_team:  [
                Role_Teams.ADMIN,
                Role_Teams.OWNER,
            ],
            children:   []
        },
        {
            name:       'products',
            url:        'products',
            icon:       'vuestic-icon-files',
            permission: Permission.COMPANY,
            role_team:  [
                Role_Teams.ADMIN,
                Role_Teams.OWNER,
            ],
            children:   []
        },
        {
            name:       'devices',
            url:        'devices',
            icon:       'vuestic-icon-components',
            permission: Permission.ADMIN,
            children:   []
        },
        {
            name:       'companies',
            url:        'companies',
            icon:       'vuestic-icon-dashboard',
            permission: Permission.ADMIN,
            children:   []
        },
      {
            name:       'types',
            url:        '#',
            icon:       'vuestic-icon-settings',
            permission: Permission.ADMIN,
            children:   [
                {
                    name:       'typecompanys',
                    url:        'typecompanys',
                    icon:       'vuestic-icon-extras',
                    permission: Permission.ADMIN,
                    children:   []
                },
              /*  {
                    name:       'typecredits',
                    url:        'typecredits',
                    icon:       'vuestic-icon-extras',
                    permission: Permission.ADMIN,
                    children:   []
                }, */
                {
                    name:       'typecustomers',
                    url:        'typecustomers',
                    icon:       'vuestic-icon-extras',
                    permission: Permission.ADMIN,
                    children:   []
                },
                {
                    name:       'typeevents',
                    url:        'typeevents',
                    icon:       'vuestic-icon-extras',
                    permission: Permission.ADMIN,
                    children:   []
                },
              /*  {
                    name:       'typektags',
                    url:        'typektags',
                    icon:       'vuestic-icon-extras',
                    permission: Permission.ADMIN,
                    children:   []
                }, */
                {
                    name:       'typepays',
                    url:        'typepays',
                    icon:       'vuestic-icon-extras',
                    permission: Permission.ADMIN,
                    children:   []
                },
                {
                    name:       'typeproducts',
                    url:        'typeproducts',
                    icon:       'vuestic-icon-extras',
                    permission: Permission.ADMIN,
                    children:   []
                },
            /*    {
                    name:       'typestaffs',
                    url:        'typestaffs',
                    icon:       'vuestic-icon-extras',
                    permission: Permission.ADMIN,
                    children:   []
                },
             /*   {
                    name:       'typestations',
                    url:        'typestations',
                    icon:       'vuestic-icon-extras',
                    permission: Permission.ADMIN,
                    children:   []
                },  */



            ]
        },
       /*   {
            name:       'statistics',
            url:        '#',
            icon:       'vuestic-icon-statistics',
            permission: Permission.ADMIN,
            children:   [
                {
                    name:       'charts',
                    url:        'charts',
                    permission: Permission.ADMIN,
                }, {
                    name:       'progressBars',
                    url:        'progress-bars',
                    permission: Permission.ADMIN,
                },
            ]
        }, */
        {
            name:       'audits',
            url:        'audits',
            icon:       'vuestic-icon-ui-elements',
            permission: Permission.ADMIN,
            children:   []
        },
    ],
    items_event: [
        {
            name:       'dashboard',
            url:        'event_dashboard',
            icon:       'vuestic-icon-dashboard',
            permission: Permission.LOGIN,
            role_team:  [
                Role_Teams.ADMIN,
                Role_Teams.OWNER,
                Role_Teams.ASSISTANTS,
            ],
            children:   []
        },
        {
          name:       'stations',
          url:        'station',
          icon:       'vuestic-icon-components',
          permission: Permission.COMPANY,
          role_team:  [
              Role_Teams.ADMIN,
              Role_Teams.OWNER,
              Role_Teams.ASSISTANTS,
          ],
          children:   []
      },
      {
        name:       'analytics',
        url:        'analytics',
        icon:       'vuestic-icon-graph',
        permission: Permission.COMPANY,
        role_team:  [
            Role_Teams.ADMIN,
            Role_Teams.OWNER,
            Role_Teams.ASSISTANTS,
        ],
        children:   []
    },
    {
      name:       'customer',
      url:        '',
      icon:       'vuestic-icon-user',
      permission: Permission.COMPANY,
      role_team:  [
          Role_Teams.ADMIN,
          Role_Teams.OWNER
      ],
      children:   [

        {
          name:       'customer_all',
          url:        'customer',
          icon:       'vuestic-icon-user',
          permission: Permission.COMPANY,
          role_team:  [
              Role_Teams.ADMIN,
              Role_Teams.OWNER
          ],
          children:   []
      },
      {
        name:       'customer_preregister',
        url:        'customerregister',
        icon:       'vuestic-icon-user',
        permission: Permission.COMPANY,
        role_team:  [
            Role_Teams.ADMIN,
            Role_Teams.OWNER
        ],
        children:   []
    },
      ]
  },
  {
    name:       'ktags',
    url:        'ktags',
    icon:       'vuestic-icon-files',
    permission: Permission.COMPANY,
    role_team:  [
        Role_Teams.ADMIN,
        Role_Teams.OWNER,
        Role_Teams.ASSISTANTS,
    ],
    children:   []
},
{
  name:       'access',
  url:        '#',
  icon:       'vuestic-icon-files',
  permission: Permission.COMPANY,
  role_team:  [
      Role_Teams.ADMIN,
      Role_Teams.OWNER,
  ],
  children:   [
      {
          name:       'membership',
          url:        'membership',
          icon:       'vuestic-icon-files',
          permission: Permission.COMPANY,
          role_team:  [
              Role_Teams.ADMIN,
              Role_Teams.OWNER,
          ],
      },
      {
          name:       'zoneaccess',
          url:        'zoneaccess',
          icon:       'vuestic-icon-files',
          permission: Permission.COMPANY,
          role_team:  [
              Role_Teams.ADMIN,
              Role_Teams.OWNER,
          ],
      },
      {
          name:       'zoneevent',
          url:        'zoneevent',
          icon:       'vuestic-icon-files',
          permission: Permission.COMPANY,
          role_team:  [
              Role_Teams.ADMIN,
              Role_Teams.OWNER,
          ],
      },
      {
          name:       'accesslog',
          url:        'accesslog',
          icon:       'vuestic-icon-files',
          permission: Permission.COMPANY,
          role_team:  [
              Role_Teams.ADMIN,
              Role_Teams.OWNER,
          ],
      },


  ]
},
        {
            name:       'config_event',
            url:        'configevent',
            icon:       'vuestic-icon-settings',
            permission: Permission.COMPANY,
            role_team:  [
                Role_Teams.ADMIN,
                Role_Teams.OWNER
            ],
            children:   []
        },

        {
          name:       'ubications',
          url:        'ubication',
          icon:       'vuestic-icon-components',
          permission: Permission.COMPANY,
          role_team:  [
              Role_Teams.ADMIN,
              Role_Teams.OWNER,
              Role_Teams.ASSISTANTS,
          ],
          children:   []
      },
       {
          name:       'days',
          url:        'day',
          icon:       'vuestic-icon-components',
          permission: Permission.COMPANY,
          role_team:  [
              Role_Teams.ADMIN,
              Role_Teams.OWNER,
              Role_Teams.ASSISTANTS,
          ],
          children:   []
      },
        {
            name:       'deviceevent',
            url:        'deviceevent',
            icon:       'vuestic-icon-forms',
            permission: Permission.COMPANY,
            role_team:  [
                Role_Teams.ADMIN,
                Role_Teams.OWNER
            ],
            children:   []
        },

        {
            name:       'staff',
            url:        'staff',
            icon:       'vuestic-icon-user',
            permission: Permission.COMPANY,
            role_team:  [
                Role_Teams.ADMIN,
                Role_Teams.OWNER,
                Role_Teams.ASSISTANTS,
            ],
            children:   []
        },





      /*  {
            name:       'transactions',
            url:        'transactions',
            icon:       'vuestic-icon-statistics',
            permission: Permission.COMPANY,
            role_team:  [
                Role_Teams.ADMIN,
                Role_Teams.OWNER
            ],
            children:   []
        }, */
    ]
}
