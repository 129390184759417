const toggleWithoutAnimation = state => state.app.sidebar.withoutAnimation
const config                 = state => state.app.config
const palette                = state => state.app.config.palette
const isLoading              = state => state.app.isLoading
const isAuthenticated        = state => !!state.auth.token
const authStatus             = state => state.auth.status

export {
    toggleWithoutAnimation,
    config,
    palette,
    isLoading,
    isAuthenticated,
    authStatus
}
