import axios from 'axios'
import * as Config from '../../config'
import Cookies from 'js-cookie'

const state = {
  activations: {},
}

const actions = {
  loadTotalExcel({commit, dispatch}, form) {
    commit('LOAD_EXCEL')
    return new Promise((resolve, reject) => {
      let config = {
        headers: {
          tokentap: 'Sb7qmGAxCrqdZz370F5cGSNrcQNidjQ2'
        }
      }
     
            axios.post(Config.apiPath3 + 'doc/general_report', form, config)
            .then(
                response => {
                   

                    commit('LOAD_EXCEL_OK', response.data)
                    resolve(response.data)
                })
            .catch(error => {
                commit('LOAD_EXCEL_FAIL')
                reject(error.response.data)
            })
    })
},
  loadDashboardEventResume ({ commit, dispatch }, company) {
    commit('LOAD_DASHBOARD_EVENT_RESUME')

    return new Promise((resolve, reject) => {
      axios
        .get(Config.apiPath + 'analytic/dashboard/event/resume/' + company)
        .then((response) => {
          if (response.data.code !== 500) {
            commit('LOAD_DASHBOARD_EVENT_RESUME_OK', response.data.data)
            resolve(response.data.data)
          } else {
            commit('LOAD_DASHBOARD_EVENT_RESUME_FAIL')
            reject(response.data)
          }
        })
        .catch((error) => {
          commit('LOAD_DASHBOARD_EVENT_RESUME_FAIL')
          reject(error.data)
        })
    })
  },
  loadActivations ({ commit, dispatch }, event) {
    commit('LOAD_ACTIVATIONS')

    return new Promise((resolve, reject) => {
      axios
        .get(Config.apiPath + 'analytic/activations/' + event)
        .then((response) => {
          if (response.data.code !== 500) {
            commit('LOAD_ACTIVATIONS_OK', response.data.data)
            resolve(response.data.data)
          } else {
            commit('LOAD_ACTIVATIONS_FAIL')
            reject(response.data)
          }
        })
        .catch((error) => {
          commit('LOAD_ACTIVATIONS_FAIL')
          reject(error.data)
        })
    })
  },
  loadEventAnalitics ({ commit, dispatch }, id_event) {
    commit('LOAD_EVENT_ANALITIC')

    return new Promise((resolve, reject) => {
      axios
        .get(Config.apiPath + 'analytic/clientAnalitics/' + id_event)
        .then((response) => {
          if (response.data.code !== 500) {
            commit('LOAD_EVENT_ANALITIC_OK', response.data.data)
            resolve(response.data.data)
          } else {
            commit('LOAD_EVENT_ANALITIC_FAIL')
            reject(response.data)
          }
        })
        .catch((error) => {
          commit('LOAD_EVENT_ANALITIC_FAIL')
          reject(error.data)
        })
    })
  },
  loadActivationsByDate ({ commit, dispatch }, form) {
    commit('LOAD_ACTIVATIONS')

    return new Promise((resolve, reject) => {
      axios
        .post(Config.apiPath + 'analytic/activations/date', form)
        .then((response) => {
          if (response.data.code !== 500) {
            commit('LOAD_ACTIVATIONS_OK', response.data.data)
            resolve(response.data.data)
          } else {
            commit('LOAD_ACTIVATIONS_FAIL')
            reject(response.data)
          }
        })
        .catch((error) => {
          commit('LOAD_ACTIVATIONS_FAIL')
          reject(error.data)
        })
    })
  },

  loadMoneyPerHour ({ commit, dispatch }, event) {
    commit('LOAD_MONEY_PERHOUR')

    return new Promise((resolve, reject) => {
      axios
        .get(Config.apiPath + 'analytic/moneyPerHour/' + event)
        .then((response) => {
          if (response.data.code !== 500) {
            commit('LOAD_MONEY_PERHOUR_OK', response.data.data)
            resolve(response.data.data)
          } else {
            commit('LOAD_MONEY_PERHOUR_FAIL')
            reject(response.data)
          }
        })
        .catch((error) => {
          commit('LOAD_MONEY_PERHOUR_FAIL')
          reject(error.data)
        })
    })
  },

  loadMoneyBalance ({ commit, dispatch }, event) {
    commit('LOAD_MONEYBALANCE')

    return new Promise((resolve, reject) => {
      axios
        .get(Config.apiPath + 'analytic/moneyBalance/' + event)
        .then((response) => {
          if (response.data.code !== 500) {
            commit('LOAD_MONEYBALANCE_OK', response.data.data)
            resolve(response.data.data)
          } else {
            commit('LOAD_MONEYBALANCE_FAIL')
            reject(response.data)
          }
        })
        .catch((error) => {
          commit('LOAD_MONEY_BALANCE_FAIL')
          reject(error.data)
        })
    })
  },
  loadMoneyBalanceByUbication ({ commit, dispatch }, form) {
    commit('LOAD_MONEYBALANCE_BY_UBICATION')

    return new Promise((resolve, reject) => {
      axios
        .get(
          Config.apiPath +
            'analytic/moneyBalanceByUbication/' +
            form.idevent +
            '/' +
            form.idubication
        )
        .then((response) => {
          if (response.data.code !== 500) {
            commit('LOAD_MONEYBALANCE_BY_UBICATION_OK', response.data.data)
            resolve(response.data.data)
          } else {
            commit('LOAD_MONEYBALANCE_BY_UBICATION_FAIL')
            reject(response.data)
          }
        })
        .catch((error) => {
          commit('LOAD_MONEY_BALANCE_BY_UBICATION_FAIL')
          reject(error.data)
        })
    })
  },
  loadMoneyBalanceByDay ({ commit, dispatch }, form) {
    commit('LOAD_MONEYBALANCE_BY_DAY')

    return new Promise((resolve, reject) => {
      axios
        .get(
          Config.apiPath +
            'analytic/moneyBalanceByDay/' +
            form.idevent +
            '/' +
            form.idday
        )
        .then((response) => {
          if (response.data.code !== 500) {
            commit('LOAD_MONEYBALANCE_BY_DAY_OK', response.data.data)
            resolve(response.data.data)
          } else {
            commit('LOAD_MONEYBALANCE_BY_DAY_FAIL')
            reject(response.data)
          }
        })
        .catch((error) => {
          commit('LOAD_MONEY_BALANCE_BY_DAY_FAIL')
          reject(error.data)
        })
    })
  },
  loadMoneyBalanceByDate ({ commit, dispatch }, form) {
    commit('LOAD_MONEYBALANCE')

    return new Promise((resolve, reject) => {
      axios
        .post(Config.apiPath + 'analytic/moneyBalance/date', form)
        .then((response) => {
          if (response.data.code !== 500) {
            commit('LOAD_MONEYBALANCE_OK', response.data.data)
            resolve(response.data.data)
          } else {
            commit('LOAD_MONEYBALANCE_FAIL')
            reject(response.data)
          }
        })
        .catch((error) => {
          commit('LOAD_MONEY_BALANCE_FAIL')
          reject(error.data)
        })
    })
  },

  loadAnalyticStation ({ commit, dispatch }, station) {
    commit('LOAD_STATION_ANALYTIC')

    return new Promise((resolve, reject) => {
      axios
        .get(Config.apiPath + 'analytic/station/' + station)
        .then((response) => {
          if (response.data.code !== 500) {
            commit('LOAD_STATION_ANALYTIC_OK', response.data.data)
            resolve(response.data.data)
          } else {
            commit('LOAD_STATION_ANALYTIC_FAIL')
            reject(response.data)
          }
        })
        .catch((error) => {
          commit('LOAD_STATION_ANALYTIC_FAIL')
          reject(error.data)
        })
    })
  },

  loadAnalyticStationTopup ({ commit, dispatch }, station) {
    commit('LOAD_STATION_ANALYTIC_TOPUP')

    return new Promise((resolve, reject) => {
      axios
        .get(Config.apiPath + 'analytic/station/topup/' + station)
        .then((response) => {
          if (response.data.code !== 500) {
            commit('LOAD_STATION_ANALYTIC_TOPUP_OK', response.data.data)
            resolve(response.data.data)
          } else {
            commit('LOAD_STATION_ANALYTIC_TOPUP_FAIL')
            reject(response.data)
          }
        })
        .catch((error) => {
          commit('LOAD_STATION_ANALYTIC_TOPUP_FAIL')
          reject(error.data)
        })
    })
  },
  loadAnalyticStationProductTopup ({ commit, dispatch }, station) {
    commit('LOAD_STATION_ANALYTIC_PRODUCT_TOPUP')

    return new Promise((resolve, reject) => {
      axios
        .get(Config.apiPath + 'analytic/station/topup/product/' + station)
        .then((response) => {
          if (response.data.code !== 500) {
            commit(
              'LOAD_STATION_ANALYTIC_PRODUCT_TOPUP_OK',
              response.data.data
            )
            resolve(response.data.data)
          } else {
            commit('LOAD_STATION_ANALYTIC_PRODUCT_TOPUP_FAIL')
            reject(response.data)
          }
        })
        .catch((error) => {
          commit('LOAD_STATION_ANALYTIC_PRODUCT_TOPUP_FAIL')
          reject(error.data)
        })
    })
  },
}

const mutations = {
  LOAD_ACTIVATIONS_OK (state, activations) {
    state.activations = activations
  },
  LOAD_MONEYBALANCE_OK (state, money) {
    state.money = money
  },
  LOAD_STATION_ANALYTIC_OK (state, station) {
    state.station = station
  },
}

export default {
  state,
  actions,
  mutations,
}
