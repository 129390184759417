import axios from 'axios'
import * as Config from '../../config'
import Cookies from 'js-cookie'

const state = {
    device:  {},
    devices: {}
}

const actions = {

    loadDevices ({commit, dispatch}) {
        commit('LOAD_DEVICES')

        return new Promise((resolve, reject) => {
            axios.get(Config.apiPath + 'device')
                .then(
                    response => {
                        if (response.data.code !== 500) {
                            commit('LOAD_DEVICES_OK', response.data.data)
                            resolve(response.data.data)
                        } else {
                            commit('LOAD_DEVICES_FAIL')
                            reject()
                        }
                    })
                .catch(error => {
                    commit('LOAD_DEVICES_FAIL')
                    reject()
                })
        })
    },
    loadDevicesByEvent ({commit, dispatch}, id) {
        commit('LOAD_DEVICES')

        return new Promise((resolve, reject) => {
            axios.get(Config.apiPath + 'device_event/filterby/id_event/' + id)
                .then(
                    response => {
                        if (response.data.code !== 500) {
                            commit('LOAD_DEVICES_OK', response.data.data)
                            resolve(response.data.data)
                        } else {
                            commit('LOAD_DEVICES_FAIL')
                            reject()
                        }
                    })
                .catch(error => {
                    commit('LOAD_DEVICES_FAIL')
                    reject()
                })
        })
    },
    loadConnectionByEvent ({commit, dispatch}, form) {
        commit('LOAD_CONNECTION_DEVICE')

        return new Promise((resolve, reject) => {
            axios.get(Config.apiPath + 'device_status/event/' + form.idevent+ '/device/'+form.iddevice)
                .then(
                    response => {
                        if (response.data.code !== 500) {
                            commit('LOAD_CONNECTION_DEVICE_OK', response.data.data)
                            resolve(response.data.data)
                        } else {
                            commit('LOAD_CONNECTION_DEVICE_FAIL')
                            reject()
                        }
                    })
                .catch(error => {
                    commit('LOAD_CONNECTION_DEVICE_FAIL')
                    reject()
                })
        })
    },
    loadSalesDevicesByEvent ({ commit, dispatch }, form) {
        commit('LOAD_SALES_DEVICES_EVENT')

        return new Promise((resolve, reject) => {
            axios.get(Config.apiPath + 'product_transaction/device/'+form.device+'/event/'+form.event )
                .then(
                    response => {
                        if (response.data.code !== 500) {
                            commit('LOAD_SALES_DEVICES_EVENT_OK', response.data.data)
                            resolve(response.data.data)
                        } else {
                            commit('LOAD_SALES_DEVICES_EVENT_FAIL')
                            reject()
                        }
                    })
                .catch(error => {
                    commit('LOAD_SALES_DEVICES_EVENT_FAIL')
                    reject()
                })
        })
    },
    loadRefundDevicesByEvent ({ commit, dispatch }, form) {
        commit('LOAD_REFUNDS_DEVICES_EVENT')

        return new Promise((resolve, reject) => {
            axios.get(Config.apiPath + 'refundtransaction/device/'+form.device+'/event/'+form.event )
                .then(
                    response => {
                        if (response.data.code !== 500) {
                            commit('LOAD_REFUNDS_DEVICES_EVENT_OK', response.data.data)
                            resolve(response.data.data)
                        } else {
                            commit('LOAD_REFUNDS_DEVICES_EVENT_FAIL')
                            reject()
                        }
                    })
                .catch(error => {
                    commit('LOAD_REFUNDS_DEVICES_EVENT_FAIL')
                    reject()
                })
        })
    },
    loadTopupDevicesByEvent ({ commit, dispatch }, form) {
        commit('LOAD_TOPUPS_DEVICES_EVENT')

        return new Promise((resolve, reject) => {
            axios.get(Config.apiPath + 'topuptransaction/device/'+form.device+'/event/'+form.event )
                .then(
                    response => {
                        if (response.data.code !== 500) {
                            commit('LOAD_TOPUPS_DEVICES_EVENT_OK', response.data.data)
                            resolve(response.data.data)
                        } else {
                            commit('LOAD_TOPUPS_DEVICES_EVENT_FAIL')
                            reject()
                        }
                    })
                .catch(error => {
                    commit('LOAD_TOPUPS_DEVICES_EVENT_FAIL')
                    reject()
                })
        })
    },
    storeDevice ({commit, dispatch}, form) {
        commit('STORE_DEVICE')

        return new Promise((resolve, reject) => {
            axios.post(Config.apiPath + 'device/save', form)
                .then(
                    response => {
                        commit('STORE_DEVICE_OK')
                        resolve(response.data)
                    })
                .catch(error => {
                    commit('STORE_DEVICE_FAIL')
                    reject(error.response.data)
                })
        })
    },
    updateDevice ({commit, dispatch}, form) {
        commit('UPDATE_PRODUCT')

        return new Promise((resolve, reject) => {
            axios.put(Config.apiPath + 'device/' + form.id, form)
                .then(
                    response => {
                        commit('UPDATE_PRODUCT_OK')
                        resolve(response.data)
                    })
                .catch(error => {
                    commit('UPDATE_PRODUCT_FAIL')
                    reject(error.response.data)
                })
        })
    },
    loadDevice ({commit, dispatch}, form) {
        commit('LOAD_DEVICE')

        return new Promise((resolve, reject) => {
            axios.get(Config.apiPath + 'device/' + form, {form})
                .then(
                    response => {
                        if (response.data.code !== 500) {
                            commit('LOAD_DEVICE_OK', response.data.data)
                            resolve(response.data.data)
                        } else {
                            commit('LOAD_DEVICE_FAIL')
                            reject()
                        }
                    })
                .catch(error => {
                    commit('LOAD_DEVICE_FAIL')
                    reject()
                })
        })
    },
    activeChangeDevice({commit, dispatch}, id) {

        return new Promise((resolve, reject) => {
          axios.post(Config.apiPath + 'device/change/active/' + id)
          .then(
            response => {
              resolve(response.data)
            })
          .catch(error => {
            reject(error.data)
          })
        })
    }

}

const mutations = {
    LOAD_DEVICE_OK (state, products) {
        state.product = products
    },
    LOAD_DEVICES_OK (state, products) {
        state.products = products
    }
}

export default {
    state,
    actions,
    mutations
}
