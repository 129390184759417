import axios from "axios";
import * as Config from "../../config";
import Cookies from "js-cookie";

const state = {
 // typeproductstation: {},
  topuptransaction: {},
  topuptransactions: {}
};

const actions = {
  loadTotalDevices({ commit, dispatch }, form) {
    commit("LOAD_TOPUPTRANSACTION");

    return new Promise((resolve, reject) => {
      axios
        .get(
          Config.apiPath + "product_transaction/totals_by_device",{params:form}
        )
        .then(response => {
          if (response.data.code !== 500) {
            commit("LOAD_TOPUPTRANSACTION_OK", response.data.data);
            resolve(response.data.data);
          } else {
            commit("LOAD_TOPUPTRANSACTION_FAIL");
            reject();
          }
        })
        .catch(error => {
          commit("LOAD_TOPUPTRANSACTION_FAIL");
          reject();
        });
    });
  },
  loadTopupTransactionsDuplicated({ commit, dispatch }, form) {
    commit("LOAD_TOPUPTRANSACTION");

    return new Promise((resolve, reject) => {
      axios
        .get(
          Config.apiPath + "topuptransaction/check_duplicates",{params:form}
        )
        .then(response => {
          if (response.data.code !== 500) {
            commit("LOAD_TOPUPTRANSACTION_OK", response.data.data);
            resolve(response.data.data);
          } else {
            commit("LOAD_TOPUPTRANSACTION_FAIL");
            reject();
          }
        })
        .catch(error => {
          commit("LOAD_TOPUPTRANSACTION_FAIL");
          reject();
        });
    });
  },
  loadProductTransactionsDuplicated({ commit, dispatch }, form) {
    commit("LOAD_TOPUPTRANSACTION");

    return new Promise((resolve, reject) => {
      axios
        .get(
          Config.apiPath + "product_transaction/check_duplicates",{params:form}
        )
        .then(response => {
          if (response.data.code !== 500) {
            commit("LOAD_TOPUPTRANSACTION_OK", response.data.data);
            resolve(response.data.data);
          } else {
            commit("LOAD_TOPUPTRANSACTION_FAIL");
            reject();
          }
        })
        .catch(error => {
          commit("LOAD_TOPUPTRANSACTION_FAIL");
          reject();
        });
    });
  },
  loadTopupTransactions({ commit, dispatch }, form) {
    commit("LOAD_TOPUPTRANSACTION");

    return new Promise((resolve, reject) => {
      axios
        .get(
          Config.apiPath + "topuptransaction/all",{params:form}
        )
        .then(response => {
          if (response.data.code !== 500) {
            commit("LOAD_TOPUPTRANSACTION_OK", response.data.data);
            resolve(response.data.data);
          } else {
            commit("LOAD_TOPUPTRANSACTION_FAIL");
            reject();
          }
        })
        .catch(error => {
          commit("LOAD_TOPUPTRANSACTION_FAIL");
          reject();
        });
    });
  },
 
};

const mutations = {
  STORE_TOPUPTRANSACTION_OK(state, topuptransaction) {
    state.topuptransaction = topuptransaction;
  
    
  },
  LOAD_TOPUPTRANSACTION_OK(state, topuptransaction) {
    state.topuptransactions = topuptransaction;
  }
};

export default {
  state,
  actions,
  mutations
};
