import axios from 'axios'
import * as Config from '../../config'
import Cookies from 'js-cookie'

const state = {
  userktag: {},
  products: {}
}

const actions = {

    loadKtagsUsers ({ commit, dispatch }, form) {
        commit('LOAD_KTAG_USER')

        return new Promise((resolve, reject) => {
          axios.get(Config.apiPath + 'userktag/filterby/event/' + form.idevent + '/id_type_user/'+form.type)
            .then(
              response => {
                if (response.data.code !== 500) {
                  commit('LOAD_KTAG_USER_OK', response.data.data)
                  resolve(response.data.data)
                } else {
                  commit('LOAD_KTAG_USER_FAIL')
                  reject()
                }
              })
            .catch(error => {
              commit('LOAD_KTAG_USER_FAIL')
              reject()
            })
        })
    },
    loadDetailKtag ({ commit, dispatch }, idktag) {
      commit('LOAD_DETAIL_KTAG')

      return new Promise((resolve, reject) => {
        axios.get(Config.apiPath + 'userktag/'+idktag)
          .then(
            response => {
              if (response.data.code !== 500) {
                commit('LOAD_DETAIL_KTAG_OK', response.data.data)
                resolve(response.data.data)
              } else {
                commit('LOAD_DETAIL_KTAG_FAIL')
                reject()
              }
            })
          .catch(error => {
            commit('LOAD_DETAIL_KTAG_FAIL')
            reject()
          })
      })
  },
  loadDetailKtagWorker ({ commit, dispatch }, idktag) {
    commit('LOAD_DETAIL_KTAG_WORKER')

    return new Promise((resolve, reject) => {
      axios.get(Config.apiPath + 'userktag/staff/work/'+idktag)
        .then(
          response => {
            if (response.data.code !== 500) {
              commit('LOAD_DETAIL_KTAG_WORKER_OK', response.data.data)
              resolve(response.data.data)
            } else {
              commit('LOAD_DETAIL_KTAG_WORKER_FAIL')
              reject()
            }
          })
        .catch(error => {
          commit('LOAD_DETAIL_KTAG_WORKER_FAIL')
          reject()
        })
    })
},
loadStaffLogs ({ commit, dispatch }, form) {
  commit('LOAD_STAFF_LOGS')

  return new Promise((resolve, reject) => {
    axios.get(Config.apiPath + 'userktag/staff/logs/'+form.idevent+'/'+form.type+'/'+form.idktag)
      .then(
        response => {
          if (response.data.code !== 500) {
            commit('LOAD_STAFF_LOGS_OK', response.data.data)
            resolve(response.data.data)
          } else {
            commit('LOAD_STAFF_LOGS_FAIL')
            reject()
          }
        })
      .catch(error => {
        commit('LOAD_STAFF_LOGS_FAIL')
        reject()
      })
  })
},
  loadBalanceDetailKtag ({ commit, dispatch }, codektag) {
    commit('LOAD_BALANCE_DETAIL_KTAG')

    return new Promise((resolve, reject) => {
      axios.get(Config.apiPath + 'userktag/balance_detail/'+codektag)
        .then(
          response => {
            if (response.data.code !== 500) {
              commit('LOAD_BALANCE_DETAIL_KTAG_OK', response.data.data)
              resolve(response.data.data)
            } else {
              commit('LOAD_BALANCE_DETAIL_KTAG_FAIL')
              reject()
            }
          })
        .catch(error => {
          commit('LOAD_BALANCE_DETAIL_KTAG_FAIL')
          reject()
        })
    })
},

  loadKtagsConexion ({ commit, dispatch }, idktag) {
    commit('LOAD_KTAG_CONEXION')

    return new Promise((resolve, reject) => {
      axios.get(Config.apiPath + 'userktag/log/'+idktag)
        .then(
          response => {
            if (response.data.code !== 500) {
              commit('LOAD_KTAG_CONEXION_OK', response.data.data)
              resolve(response.data.data)
            } else {
              commit('LOAD_KTAG_CONEXION_FAIL')
              reject()
            }
          })
        .catch(error => {
          commit('LOAD_KTAG_CONEXION_FAIL')
          reject()
        })
    })
},
  loadTopupsKtag ({ commit, dispatch }, idktag) {
    commit('LOAD_TOPUPS_KTAG')

    return new Promise((resolve, reject) => {
      axios.get(Config.apiPath + 'topuptransaction/filterby/id_user_ktag/'+idktag)
        .then(
          response => {
            if (response.data.code !== 500) {
              commit('LOAD_TOPUPS_KTAG_OK', response.data.data)
              resolve(response.data.data)
            } else {
              commit('LOAD_TOPUPS_KTAG_FAIL')
              reject()
            }
          })
        .catch(error => {
          commit('LOAD_TOPUPS_KTAG_FAIL')
          reject()
        })
    })
},

loadRefundsKtag ({ commit, dispatch }, idktag) {
  commit('LOAD_REFUNDS_KTAG')

  return new Promise((resolve, reject) => {
    axios.get(Config.apiPath + 'refundtransaction/filterby/id_user_ktag/'+idktag)
      .then(
        response => {
          if (response.data.code !== 500) {
            commit('LOAD_REFUNDS_KTAG_OK', response.data.data)
            resolve(response.data.data)
          } else {
            commit('LOAD_REFUNDS_KTAG_FAIL')
            reject()
          }
        })
      .catch(error => {
        commit('LOAD_REFUNDS_KTAG_FAIL')
        reject()
      })
  })
},

loadSalesKtag ({ commit, dispatch }, idktag) {
  commit('LOAD_SALES_KTAG')

  return new Promise((resolve, reject) => {
    axios.get(Config.apiPath + 'product_transaction/filterby/id_user_ktag/'+idktag)
      .then(
        response => {
          if (response.data.code !== 500) {
            commit('LOAD_SALES_KTAG_OK', response.data.data)
            resolve(response.data.data)
          } else {
            commit('LOAD_SALES_KTAG_FAIL')
            reject()
          }
        })
      .catch(error => {
        commit('LOAD_SALES_KTAG_FAIL')
        reject()
      })
  })
},

    loadKtagsCustomers ({ commit, dispatch }, idevent) {
      commit('LOAD_KTAG_CUSTOMER')

      return new Promise((resolve, reject) => {
        axios.get(Config.apiPath + 'userktag/customer/'+idevent)
          .then(
            response => {
              if (response.data.code !== 500) {
                commit('LOAD_KTAG_CUSTOMER_OK', response.data.data)
                resolve(response.data.data)
              } else {
                commit('LOAD_KTAG_CUSTOMER_FAIL')
                reject()
              }
            })
          .catch(error => {
            commit('LOAD_KTAG_CUSTOMER_FAIL')
            reject()
          })
      })
  },
  loadKtagsStaffs ({ commit, dispatch }, idevent) {
    commit('LOAD_KTAG_STAFF')

    return new Promise((resolve, reject) => {
      axios.get(Config.apiPath + 'userktag/staff/'+idevent)
        .then(
          response => {
            if (response.data.code !== 500) {
              commit('LOAD_KTAG_STAFF_OK', response.data.data)
              resolve(response.data.data)
            } else {
              commit('LOAD_KTAG_STAFF_FAIL')
              reject()
            }
          })
        .catch(error => {
          commit('LOAD_KTAG_STAFF_FAIL')
          reject()
        })
    })

},


}

const mutations = {
    LOAD_KTAG_USER_OK (state, userktag) {
    state.userktag = userktag
  },
  LOAD_DETAIL_KTAG_OK (state, userktag) {
    state.userktag = userktag
  },
  LOAD_TOPUPS_KTAG_OK (state, userktag) {
    state.topups = userktag
  },
  LOAD_REFUNDS_KTAG_OK (state, userktag) {
    state.refunds = userktag
  },
  LOAD_SALES_KTAG_OK (state, userktag) {
    state.sales = userktag
  },
  LOAD_KTAG_CONEXION_OK (state, userktag) {
    state.sales = userktag
  },
}

export default {
  state,
  actions,
  mutations
}
