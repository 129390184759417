import axios from 'axios'
import * as Config from '../../config'
import Cookies from "js-cookie"

const state = {
    typecustomer: {},
    customer:     {},
    customers:{}
}

const actions = {

    loadCustomers({commit, dispatch}, params) {
        commit('LOAD_CUSTOMERS')

        return new Promise((resolve, reject) => {
            axios.get(Config.apiPath + 'customer', {params})
            .then(
                response => {
                    if (response.data.code !== 500) {
                        commit('LOAD_CUSTOMERS_OK', response.data.data)
                        resolve(response.data.data)
                    } else {
                        commit('LOAD_CUSTOMERS_FAIL')
                        reject()
                    }

                })
            .catch(error => {
                commit('LOAD_CUSTOMERS_FAIL')
                reject()
            })
        })
    },

    loadCustomersEvent({commit, dispatch}, event) {
        console.log(event)
        commit('LOAD_CUSTOMERS')

        return new Promise((resolve, reject) => {
            axios.get(Config.apiPath + 'customer/event/'+event)
            .then(
                response => {
                    if (response.data.code !== 500) {
                        commit('LOAD_CUSTOMERS_OK', response.data.data)
                        resolve(response.data.data)
                    } else {
                        commit('LOAD_CUSTOMERS_FAIL')
                        reject()
                    }

                })
            .catch(error => {
                commit('LOAD_CUSTOMERS_FAIL')
                reject()
            })
        })
    },
    storeCustomer({commit, dispatch}, form) {
        commit('STORE_CUSTOMER')

        return new Promise((resolve, reject) => {
            axios.post(Config.apiPath + 'customer', form)
            .then(
                response => {

                    commit('STORE_CUSTOMER_OK', response.data.data)
                    resolve(response.data)
                })
            .catch(error => {

                commit('STORE_CUSTOMER_FAIL')
                reject(error.response.data)
            })
        })
    },
    updateCustomer({commit, dispatch}, form) {
        commit('UPDATE_CUSTOMER')

        return new Promise((resolve, reject) => {
            axios.put(Config.apiPath + 'customer/'+form.id,form)
            .then(
                response => {
                    commit('UPDATE_CUSTOMER_OK', response.data.data)
                    resolve(response.data)
                })
            .catch(error => {
                commit('UPDATE_CUSTOMER_FAIL')
                reject(error.response.data)
            })
        })
    },
    activeChangeCustomer({commit, dispatch}, id) {

        return new Promise((resolve, reject) => {
            axios.post(Config.apiPath + 'customer/change/active/' + id)
            .then(
                response => {
                    resolve(response.data)
                })
            .catch(error => {
                reject(error.data)
            })
        })
    },
    deleteCustomer({commit, dispatch}, form) {
        commit('DELETE_CUSTOMER')

        return new Promise((resolve, reject) => {

            axios.delete(Config.apiPath + 'customer/'+form.id, form)
            .then(
                response => {

                    commit('DELETE_CUSTOMER_OK', response.data.data)
                    resolve(response.data)
                })
            .catch(error => {
                commit('DELETE_CUSTOMER_FAIL')
                reject(error.response.data)
            })
        })
    },
    loadCustomer({commit, dispatch}, form) {
        commit('LOAD_CUSTOMER')

        return new Promise((resolve, reject) => {
            axios.get(Config.apiPath + 'customer/' + form, {form})
            .then(
                response => {
                    if (response.data.code !== 500) {
                        commit('LOAD_CUSTOMER_OK', response.data.data)
                        resolve(response.data.data)
                    } else {
                        commit('LOAD_CUSTOMER_FAIL')
                        reject()
                    }

                })
            .catch(error => {
                commit('LOAD_CUSTOMER_FAIL')
                reject()
            })
        })
    },
    loadCustomerByEmail({commit, dispatch}, email) {
        commit('LOAD_CUSTOMER_BY_EMAIL')

        return new Promise((resolve, reject) => {
            axios.get(Config.apiPath + 'customer/filterby/email/'+email)
            .then(
                response => {
                    if (response.data.code !== 500) {
                        commit('LOAD_CUSTOMER_BY_EMAIL_OK', response.data.data)
                        resolve(response.data.data)
                    } else {
                        commit('LOAD_CUSTOMER_BY_EMAIL_FAIL')
                        reject()
                    }

                })
            .catch(error => {
                commit('LOAD_CUSTOMER_BY_EMAIL_FAIL')
                reject()
            })
        })
    },
    loadCustomerByCode({commit, dispatch}, code) {
      commit('LOAD_CUSTOMER_BY_CODE')

      return new Promise((resolve, reject) => {
          axios.get(Config.apiPath + 'customer/filterby/code/'+code)
          .then(
              response => {
                  if (response.data.code !== 500) {
                      commit('LOAD_CUSTOMER_BY_CODE_OK', response.data.data)
                      resolve(response.data.data)
                  } else {
                      commit('LOAD_CUSTOMER_BY_CODE_FAIL')
                      reject()
                  }

              })
          .catch(error => {
              commit('LOAD_CUSTOMER_BY_CODE_FAIL')
              reject()
          })
      })
  }
}

const mutations = {

STORE_CUSTOMER_OK(state, customer) {
       // state.customer = customer;
      //  state.customer.push(customer);
},
UPDATE_CUSTOMER_OK(state, customer) {
    state.customer = customer;
},
DELETE_CUSTOMER_OK(state, customer) {
    state.customer = customer;
},
LOAD_CUSTOMERS_OK(state,customers)
{
    state.customers = customers;
},
LOAD_CUSTOMER_OK(state,customer)
{
    state.customer = customer;
},
LOAD_CUSTOMER_BY_EMAIL_OK(state,customer)
{
    state.customer = customer;
}


}

export default {
    state,
    actions,
    mutations
}
