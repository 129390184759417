import axios from 'axios'
import * as Config from '../../config'
import Cookies from 'js-cookie'

const state = {
  zonestation: {},
  zonestations: {}
}

const actions = {

    loadZonestations ({ commit, dispatch }) {
    commit('LOAD_ZONESTATIONS')

    return new Promise((resolve, reject) => {
      axios.get(Config.apiPath + 'zonestation')
      .then(
        response => {
          if (response.data.code !== 500) {
            commit('LOAD_ZONESTATIONS_OK', response.data.data)
            resolve(response.data.data)
          } else {
            commit('LOAD_ZONESTATIONS_FAIL')
            reject()
          }
        })
      .catch(error => {
        commit('LOAD_ZONESTATIONS_FAIL')
        reject()
      })
    })
  },
  storeZonestation ({ commit, dispatch }, form) {
    commit('STORE_ZONESTATION')

    return new Promise((resolve, reject) => {
      axios.post(Config.apiPath + 'zonestation/save', form)
      .then(
        response => {
          commit('STORE_ZONESTATION_OK')
          resolve(response.data)
        })
      .catch(error => {
        commit('STORE_ZONESTATION_FAIL')
        reject(error.response.data)
      })
    })
  },
  updateZonestation ({ commit, dispatch }, form) {
    commit('UPDATE_ZONESTATION')

    return new Promise((resolve, reject) => {
      axios.put(Config.apiPath + 'zonestation/' + form.id, form)
      .then(
        response => {
          commit('UPDATE_ZONESTATION_OK')
          resolve(response.data)
        })
      .catch(error => {
        commit('UPDATE_ZONESTATION_FAIL')
        reject(error.response.data)
      })
    })
  },
  deleteZonestation ({ commit, dispatch }, form) {
    commit('DELETE_ZONESTATION')

    return new Promise((resolve, reject) => {
      axios.delete(Config.apiPath + 'zonestation/delete/' + form.id, form)
      .then(
        response => {
          commit('DELETE_ZONESTATION_OK')
          resolve(response.data)
        })
      .catch(error => {
        commit('DELETE_ZONESTATION_FAIL')
        reject(error.response.data)
      })
    })
  },
  loadZonestation ({ commit, dispatch }, form) {
    commit('LOAD_ZONESTATION')

    return new Promise((resolve, reject) => {
      axios.get(Config.apiPath + 'zonestation/' + form, { form })
      .then(
        response => {
          if (response.data.code !== 500) {
            commit('LOAD_ZONESTATION_OK', response.data.data)
            resolve(response.data.data)
          } else {
            commit('LOAD_ZONESTATION_FAIL')
            reject()
          }
        })
      .catch(error => {
        commit('LOAD_ZONESTATION_FAIL')
        reject()
      })
    })
  },
  loadZonestationByCompany ({ commit, dispatch }, idcompany) {
    commit('LOAD_ZONESTATIONS_BY_COMPANY')

    return new Promise((resolve, reject) => {
      axios.get(Config.apiPath + 'zonestation/filterby/id_company/' + idcompany)
      .then(
        response => {
          if (response.data.code !== 500) {
            commit('LOAD_ZONESTATIONS_BY_COMPANY_OK', response.data.data)
            resolve(response.data.data)
          } else {
            commit('LOAD_ZONESTATIONS_BY_COMPANY_FAIL')
            reject()
          }
        })
      .catch(error => {
        commit('LOAD_ZONESTATIONS_BY_COMPANY_FAIL')
        reject()
      })
    })
  },
   loadStationsByZoneEvent({commit, dispatch}, idzoneevent) {
      commit('LOAD_STATIONS_BY_ZONE_EVENT')

      return new Promise((resolve, reject) => {
          axios.get(Config.apiPath + 'zonestation/filterby/id_zone_event/' + idzoneevent)
              .then(
                  response => {
                      if (response.data.code !== 500) {
                          commit('LOAD_STATIONS_BY_ZONE_EVENT_OK', response.data.data)
                          resolve(response.data.data)
                      } else {
                          commit('LOAD_STATIONS_BY_ZONE_EVENT_FAIL')
                          reject()
                      }
                  })
              .catch(error => {
                  commit('LOAD_STATIONS_BY_ZONE_EVENT_FAIL')
                  reject()
              })
      })
  },
  loadTypeZonestation ({ commit, dispatch }) {
    commit('LOAD_TYPE_ZONESTATION')

    return new Promise((resolve, reject) => {
      axios.get(Config.apiPath + 'type/zonestation/active')
      .then(
        response => {
          if (response.data.code !== 500) {
            commit('LOAD_TYPE_ZONESTATION_OK')
            resolve(response.data.data)
          } else {
            commit('LOAD_TYPE_ZONESTATION_FAIL')
            reject()
          }
        })
      .catch(error => {
        commit('LOAD_TYPE_ZONESTATION_FAIL')
        reject()
      })
    })
  },
  activeChangeZonestation({commit, dispatch}, id) {
    commit('CHANGUE_ZONESTATION')
    return new Promise((resolve, reject) => {
      axios.post(Config.apiPath + 'zonestation/change/active/' + id)
      .then(
        response => {
          commit('CHANGUE_ZONESTATION_OK')
          resolve(response.data)
        })
      .catch(error => {
        commit('CHANGUE_ZONESTATION_FAIL')
        reject(error.response.data)
      })
    })
  }

}

const mutations = {
  LOAD_ZONESTATION_OK (state, zonestations) {
    state.zonestation = zonestations
  },
  LOAD_ZONESTATIONS_OK (state, zonestations) {
    state.zonestations = zonestations
  },
  LOAD_ZONESTATIONS_BY_COMPANY_OK (state, zonestations) {
    state.zonestations = zonestations
  }

}

export default {
  state,
  actions,
  mutations
}
