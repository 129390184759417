import axios from 'axios'
import * as Config from '../../config'
import Cookies from 'js-cookie'

const state = {
  zoneaccess: {},
  zoneaccesss: {}
}

const actions = {

    loadZoneaccesss ({ commit, dispatch }) {
    commit('LOAD_ZONEACCESSS')

    return new Promise((resolve, reject) => {
      axios.get(Config.apiPath + 'zoneaccess')
      .then(
        response => {
          if (response.data.code !== 500) {
            commit('LOAD_ZONEACCESSS_OK', response.data.data)
            resolve(response.data.data)
          } else {
            commit('LOAD_ZONEACCESSS_FAIL')
            reject()
          }
        })
      .catch(error => {
        commit('LOAD_ZONEACCESSS_FAIL')
        reject()
      })
    })
  },
  storeZoneaccess ({ commit, dispatch }, form) {
    commit('STORE_ZONEACCESS')
    
    return new Promise((resolve, reject) => {
      axios.post(Config.apiPath + 'zoneaccess/save', form)
      .then(
        response => {
          commit('STORE_ZONEACCESS_OK')
          resolve(response.data)
        })
      .catch(error => {
        commit('STORE_ZONEACCESS_FAIL')
        reject(error.response.data)
      })
    })
  },
  updateZoneaccess ({ commit, dispatch }, form) {
    commit('UPDATE_ZONEACCESS')

    return new Promise((resolve, reject) => {
      axios.put(Config.apiPath + 'zoneaccess/' + form.id, form)
      .then(
        response => {
          commit('UPDATE_ZONEACCESS_OK')
          resolve(response.data)
        })
      .catch(error => {
        commit('UPDATE_ZONEACCESS_FAIL')
        reject(error.response.data)
      })
    })
  },
  deleteZoneaccess ({ commit, dispatch }, form) {
    commit('DELETE_ZONEACCESS')

    return new Promise((resolve, reject) => {
      axios.delete(Config.apiPath + 'zoneaccess/delete/' + form.id, form)
      .then(
        response => {
          commit('DELETE_ZONEACCESS_OK')
          resolve(response.data)
        })
      .catch(error => {
        commit('DELETE_ZONEACCESS_FAIL')
        reject(error.response.data)
      })
    })
  },
  loadZoneaccess ({ commit, dispatch }, form) {
    commit('LOAD_ZONEACCESS')

    return new Promise((resolve, reject) => {
      axios.get(Config.apiPath + 'zoneaccess/' + form, { form })
      .then(
        response => {
          if (response.data.code !== 500) {
            commit('LOAD_ZONEACCESS_OK', response.data.data)
            resolve(response.data.data)
          } else {
            commit('LOAD_ZONEACCESS_FAIL')
            reject()
          }
        })
      .catch(error => {
        commit('LOAD_ZONEACCESS_FAIL')
        reject()
      })
    })
  },
  loadZoneaccessByEvent ({ commit, dispatch }, idcompany) {
    commit('LOAD_ZONEACCESSS_BY_EVENT')

    return new Promise((resolve, reject) => {
      axios.get(Config.apiPath + 'zoneaccess/filterby/id_event/' + idcompany)
      .then(
        response => {
          if (response.data.code !== 500) {
            commit('LOAD_ZONEACCESSS_BY_EVENT_OK', response.data.data)
            resolve(response.data.data)
          } else {
            commit('LOAD_ZONEACCESSS_BY_EVENT_FAIL')
            reject()
          }
        })
      .catch(error => {
        commit('LOAD_ZONEACCESSS_BY_EVENT_FAIL')
        reject()
      })
    })
  },
  loadTypeZoneaccess ({ commit, dispatch }) {
    commit('LOAD_TYPE_ZONEACCESS')

    return new Promise((resolve, reject) => {
      axios.get(Config.apiPath + 'type/zoneaccess/active')
      .then(
        response => {
          if (response.data.code !== 500) {
            commit('LOAD_TYPE_ZONEACCESS_OK')
            resolve(response.data.data)
          } else {
            commit('LOAD_TYPE_ZONEACCESS_FAIL')
            reject()
          }
        })
      .catch(error => {
        commit('LOAD_TYPE_ZONEACCESS_FAIL')
        reject()
      })
    })
  },
  activeChangeZoneaccess({commit, dispatch}, id) {
    commit('CHANGUE_ZONEACCESS')
    return new Promise((resolve, reject) => {
      axios.post(Config.apiPath + 'zoneaccess/change/active/' + id)
      .then(
        response => {
          commit('CHANGUE_ZONEACCESS_OK')
          resolve(response.data)
        })
      .catch(error => {
        commit('CHANGUE_ZONEACCESS_FAIL')
        reject(error.response.data)
      })
    })
  }

}

const mutations = {
  LOAD_ZONEACCESS_OK (state, zoneaccesss) {
    state.zoneaccess = zoneaccesss
  },
  LOAD_ZONEACCESSS_OK (state, zoneaccesss) {
    state.zoneaccesss = zoneaccesss
  },
  LOAD_ZONEACCESSS_BY_COMPANY_OK (state, zoneaccesss) {
    state.zoneaccesss = zoneaccesss
  }
  
}

export default {
  state,
  actions,
  mutations
}
