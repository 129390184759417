import axios from 'axios'
import * as Config from '../../config'
import Cookies from "js-cookie"

const state = {
    typestaff: {},
    staff:     {},
    staffs:{}
}

const actions = {

   
    loadStaffs({commit, dispatch}, params) {
        commit('LOAD_STAFFS')

        return new Promise((resolve, reject) => {
            axios.get(Config.apiPath + 'staff', {params})
                .then(
                    response => {
                        if (response.data.code !== 500) {
                            commit('LOAD_STAFFS_OK', response.data.data)
                            resolve(response.data.data)
                        } else {
                            commit('LOAD_STAFFS_FAIL')
                            reject()
                        }

                    })
                .catch(error => {
                    commit('LOAD_STAFFS_FAIL')
                    reject()
                })
        })
    },
    loadStaffsByEvent({commit, dispatch}, form) {
        commit('LOAD_STAFFS_BY_EVENT')

        return new Promise((resolve, reject) => {
            axios.get(Config.apiPath + 'staff/filterby/id_event/' + form.id)
                .then(
                    response => {
                        if (response.data.code !== 500) {
                            commit('LOAD_STAFFS_BY_EVENT_OK', response.data.data)
                            resolve(response.data.data)
                        } else {
                            commit('LOAD_STAFFS_BY_EVENT_FAIL')
                            reject()
                        }

                    })
                .catch(error => {
                    commit('LOAD_STAFFS_BY_EVENT_FAIL')
                    reject()
                })
        })
    },
     loadStaffsByStation({commit, dispatch}, form) {
        commit('LOAD_STAFFS_BY_STATION')

        return new Promise((resolve, reject) => {
            axios.get(Config.apiPath + 'product_transaction/staff/station/' + form.id)
                .then(
                    response => {
                        if (response.data.code !== 500) {
                            commit('LOAD_STAFFS_BY_STATION_OK', response.data.data)
                            resolve(response.data.data)
                        } else {
                            commit('LOAD_STAFFS_BY_STATION_FAIL')
                            reject()
                        }

                    })
                .catch(error => {
                    commit('LOAD_STAFFS_BY_STATION_FAIL')
                    reject()
                })
        })
    },
    storeStaff({commit, dispatch}, form) {
        commit('STORE_STAFF')

        return new Promise((resolve, reject) => {
            axios.post(Config.apiPath + 'staff', form)
                .then(
                    response => {
                        
                        commit('STORE_STAFF_OK', response.data.data)                       
                        resolve(response.data)
                    })
                .catch(error => {
                   
                    commit('STORE_STAFF_FAIL')
                    reject(error.response.data)
                })
        })

    },
    updateStaff({commit, dispatch}, form) {
        commit('UPDATE_STAFF')

        return new Promise((resolve, reject) => {
            axios.put(Config.apiPath + 'staff/'+form.id,form)
                .then(
                    response => {
                        commit('UPDATE_STAFF_OK', response.data.data)                       
                        resolve(response.data)
                    })
                .catch(error => {
                    commit('UPDATE_STAFF_FAIL')
                    reject(error.response.data)
                })
        })


    },
    deleteStaff({commit, dispatch}, form) {
        commit('DELETE_STAFF')

        return new Promise((resolve, reject) => {
           
            axios.delete(Config.apiPath + 'staff/'+form.id, form)
                .then(
                    response => {
                        
                        commit('DELETE_STAFF_OK', response.data.data)                       
                        resolve(response.data)
                    })
                .catch(error => {
                    commit('DELETE_STAFF_FAIL')
                    reject(error.response.data)
                })
        })

    },
    loadStaff({commit, dispatch}, form) {
        commit('LOAD_STAFF')

        return new Promise((resolve, reject) => {
            axios.get(Config.apiPath + 'staff/' + form, {form})
                .then(
                    response => {
                        if (response.data.code !== 500) {
                            commit('LOAD_STAFF_OK', response.data.data)
                            resolve(response.data.data)
                        } else {
                            commit('LOAD_STAFF_FAIL')
                            reject()
                        }

                    })
                .catch(error => {
                    commit('LOAD_STAFF_FAIL')
                    reject()
                })
        })
    },
    activeChangeStaff({commit, dispatch}, id) {

    return new Promise((resolve, reject) => {
        axios.post(Config.apiPath + 'staff/change/active/' + id)
            .then(
                response => {
                    resolve(response.data)
                })
            .catch(error => {
                reject(error.data)
            })
    })
    }
}

const mutations = {
   
    STORE_STAFF_OK(state, staff) {
       // state.staff = staff;       
      //  state.staff.push(staff);
    },
    UPDATE_STAFF_OK(state, staff) {
        state.staff = staff;      
    },
    DELETE_STAFF_OK(state, staff) {
        state.staff = staff;      
    },
    LOAD_STAFFS_OK(state,staffs)
    {
        state.staffs = staffs;
    },
    LOAD_STAFF_OK(state,staff)
    {
        state.staff = staff;
    }

}

export default {
    state,
    actions,
    mutations
}
