import axios from 'axios'
import * as Config from '../../config'
import Cookies from 'js-cookie'

const state = {
    TypeCompany:  {},
    TypesCompany: {}
}

const actions = {

    loadTypeCompanys ({commit, dispatch}) {
        commit('LOAD_TYPES_COMPANY')

        return new Promise((resolve, reject) => {
            axios.get(Config.apiPath + 'type/company')
                .then(
                    response => {
                        if (response.data.code !== 500) {
                            commit('LOAD_TYPES_COMPANY_OK', response.data.data)
                            resolve(response.data.data)
                        } else {
                            commit('LOAD_TYPES_COMPANY_FAIL')
                            reject()
                        }
                    })
                .catch(error => {
                    commit('LOAD_TYPES_COMPANY_FAIL')
                    reject()
                })
        })
    },
    loadTypeCompanysActive ({commit, dispatch}) {
        commit('LOAD_TYPE_COMPANYS_ACTIVE')

        return new Promise((resolve, reject) => {
            axios.get(Config.apiPath + 'type/company/active')
                .then(
                    response => {
                        if (response.data.code !== 500) {
                            commit('LOAD_TYPE_COMPANYS_ACTIVE_OK', response.data.data)
                            resolve(response.data.data)
                        } else {
                            commit('LOAD_TYPE_COMPANYS_ACTIVE_FAIL')
                            reject()
                        }
                    })
                .catch(error => {
                    commit('LOAD_TYPE_COMPANYS_ACTIVE_FAIL')
                    reject()
                })
        })
    },
    storeTypeCompany ({commit, dispatch}, form) {
        commit('STORE_TYPE_COMPANY')

        return new Promise((resolve, reject) => {
            axios.post(Config.apiPath + 'type/company/save', form)
                .then(
                    response => {
                        commit('STORE_TYPE_COMPANY_OK')
                        resolve(response.data)
                    })
                .catch(error => {
                    commit('STORE_TYPE_COMPANY_FAIL')
                    reject(error.response.data)
                })
        })
    },
    updateTypeCompany ({commit, dispatch}, form) {
        commit('UPDATE_TYPE_COMPANY')

        return new Promise((resolve, reject) => {
            axios.put(Config.apiPath + 'type/company/update/' + form.id, form)
                .then(
                    response => {
                        commit('UPDATE_TYPE_COMPANY_OK')
                        resolve(response.data)
                    })
                .catch(error => {
                    commit('UPDATE_TYPE_COMPANY_FAIL')
                    reject(error.response.data)
                })
        })
    },
    deleteTypeCompany({commit, dispatch}, form) {
        commit('DELETE_TYPE_COMPANY')

        return new Promise((resolve, reject) => {
           
            axios.delete(Config.apiPath + 'type/company/delete/'+form.id, form)
                .then(
                    response => {
                        
                        commit('DELETE_TYPE_COMPANY_OK', response.data.data)                       
                        resolve(response.data)
                    })
                .catch(error => {
                    commit('DELETE_TYPE_COMPANY_FAIL')
                    reject(error.response.data)
                })
        })
    },
    loadTypeCompany ({commit, dispatch}, form) {
        commit('LOAD_TYPE_COMPANY')

        return new Promise((resolve, reject) => {
            axios.get(Config.apiPath + 'type/company/find/' + form, {form})
                .then(
                    response => {
                        if (response.data.code !== 500) {
                            commit('LOAD_TYPE_COMPANY_OK', response.data.data)
                            resolve(response.data.data)
                        } else {
                            commit('LOAD_TYPE_COMPANY_FAIL')
                            reject()
                        }
                    })
                .catch(error => {
                    commit('LOAD_TYPE_COMPANY_FAIL')
                    reject()
                })
        })
    },
    activeChangeTypeCompany({commit, dispatch}, id) {

        return new Promise((resolve, reject) => {
            axios.post(Config.apiPath + 'type/company/change/active/' + id)
            .then(
            response => {
              resolve(response.data)
            })
            .catch(error => {
            reject(error.data)
            })
        })
    }

}

const mutations = {
    LOAD_TYPE_COMPANY_OK (state, typeCompany) {
        state.typeCompany = typeCompany
    },
    LOAD_TYPES_COMPANY_OK (state, typesCompany) {
        state.typesCompany = typesCompany
    }
}

export default {
    state,
    actions,
    mutations
}
