import axios from 'axios'
import * as Config from '../../config'
import Cookies from "js-cookie"

const state = {
    audit: {},   
    audits:{}
}

const actions = {


    loadAudits({commit, dispatch}, params) {
        commit('LOAD_AUDITS')

        return new Promise((resolve, reject) => {
            axios.get(Config.apiPath + 'auditlog', {params})
            .then(
                response => {
                    if (response.data.code !== 500) {
                        commit('LOAD_AUDITS_OK', response.data.data)
                        resolve(response.data.data)
                    } else {
                        commit('LOAD_AUDITS_FAIL')
                        reject()
                    }

                })
            .catch(error => {
                commit('LOAD_AUDITS_FAIL')
                reject()
            })
        })
    },

}

const mutations = {


    LOAD_AUDITS_OK(state,audits)
{
    state.audits = audits;
},

}

export default {
    state,
    actions,
    mutations
}
