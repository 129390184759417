<template>
    <vuestic-navbar>
        <header-selector slot="selector" :isOpen.sync="valueProxy" />
        <span slot="logo">
      <!--<vuestic-icon-vuestic/>-->

            <a class="navbar-brand" href="#">
                <img class="img-fluid" style="max-width:150px" src="../../../../assets/images/logodark.png">
       
            </a>
            <a v-if="manage" v-on:click="manageEvent">
                <i class="fa fa-chevron-left"></i>  {{$t('view.company.back_event')}}
            </a>
            <a v-if="!manage && !isEmpty(company)" v-on:click="manageCompany">
                <i class="fa fa-chevron-left"></i>  {{$t('view.company.back_dashboard')}}
            </a>
    </span>
        <span slot="center" style="margin-left:10%">
            <span class="nameme" v-if="event.name">Evento: {{event.name}} - 
                <span v-if="event.code">{{event.code}}</span>
             </span>
    </span>

        <span slot="center" v-if="!manage && !isEmpty(company)">
            <span class="nameme" v-if="company.name" >Compañia: {{company.name}}
            </span>
    </span>


        <span class="nameme" v-if="me.name">Hola {{me.name}}</span>
        <language-dropdown class="col nav-item" />
        <profile-dropdown class="col nav-item">
            <img src="https://thumb.ibb.co/ccxhvL/user.jpg" />

        </profile-dropdown>
    </vuestic-navbar>

</template>

<script>
    import VuesticIconVuestic from '../../../../vuestic-theme/vuestic-components/vuestic-icon/VuesticIconVuestic'
    import VuesticNavbar from '../../../../vuestic-theme/vuestic-components/vuestic-navbar/VuesticNavbar'
    import HeaderSelector from './components/HeaderSelector'

    import LanguageDropdown from './components/dropdowns/LanguageDropdown'
    import ProfileDropdown from './components/dropdowns/ProfileDropdown'
    import NotificationDropdown from './components/dropdowns/NotificationDropdown'
    import MessageDropdown from './components/dropdowns/MessageDropdown'
    import Cookies from 'js-cookie'
    import {
        mapState,
        mapActions
    } from 'vuex'

    export default {
        name: 'app-navbar',

        components: {
            VuesticIconVuestic,
            VuesticNavbar,
            HeaderSelector,
            MessageDropdown,
            NotificationDropdown,
            LanguageDropdown,
            ProfileDropdown
        },
        props:      {
            isOpen: {
                type:     Boolean,
                required: true
            }
        },
        computed:   {
            valueProxy: {
                get() {
                    return this.isOpen
                },
                set(opened) {
                    this.$emit('toggle-menu', opened)
                },
            },
            ...mapState({
                event:   state => state.event.event,
                manage:  state => state.event.manage,
                me:      state => state.auth.me,
                company: state => state.auth.company,
            })
        },
        methods:    {
            ...mapActions([
                'addToastMessage',
                'cleaEvent',
                'loadCompanyDashboardLogout',
            ]),
            manageEvent() {
                this.cleaEvent()
                    .then(() => {
                        // this.addToastMessage({
                        //     text: 'Entrando al dashboard general',
                        //     type: 'success'
                        // })
                        this.$router.push({name: 'event'})
                    })
                    .catch(data => {
                        this.addToastMessage({
                            text: 'Ha ocurrido un error',
                            type: 'warning'
                        })
                    })
            },
            manageCompany() {
                this.loadCompanyDashboardLogout()
                    .then(() => {
                        this.$router.push({name: 'dashboard'})
                    })
                    .catch(data => {
                        this.addToastMessage({
                            text: 'Ha ocurrido un error',
                            type: 'warning'
                        })
                    })
            },
            isEmpty(obj) {
                for (var key in obj) {
                    if (obj.hasOwnProperty(key))
                        return false;
                }
                return true;
            }
        }
    }
</script>

<style>

    .nameme {
        color: #fff;
        text-align: center;
        margin-top: 15px;
    }</style>
