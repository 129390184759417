import axios from 'axios'
import * as Config from '../../config'
import Cookies from "js-cookie"

const state = {
    typecustomer: {},   
    typecustomers:{}
}

const actions = {

   
    loadTypecustomers({commit, dispatch}, params) {
        commit('LOAD_TYPE_CUSTOMERS')

        return new Promise((resolve, reject) => {
            axios.get(Config.apiPath + 'type/customer', {params})
                .then(
                    response => {
                        if (response.data.code !== 500) {
                            commit('LOAD_TYPE_CUSTOMERS_OK', response.data.data)
                            resolve(response.data.data)
                        } else {
                            commit('LOAD_TYPE_CUSTOMERS_FAIL')
                            reject()
                        }

                    })
                .catch(error => {
                    commit('LOAD_TYPE_CUSTOMERS_FAIL')
                    reject()
                })
        })
    },
    loadTypecustomersActive({commit, dispatch}, params) {
        commit('LOAD_TYPE_CUSTOMERS_ACTIVE')

        return new Promise((resolve, reject) => {
            axios.get(Config.apiPath + 'type/customer/active', {params})
                .then(
                    response => {
                        if (response.data.code !== 500) {
                           // console.log(response.data);
                            commit('LOAD_TYPE_CUSTOMERS_ACTIVE_OK', response.data.data)
                            resolve(response.data.data)
                        } else {
                            commit('LOAD_TYPE_CUSTOMERS_ACTIVE_FAIL')
                            reject()
                        }

                    })
                .catch(error => {
                    commit('LOAD_TYPE_CUSTOMERS_ACTIVE_FAIL')
                    reject()
                })
        })
    },
    storeTypecustomer({commit, dispatch}, form) {
        commit('STORE_TYPE_CUSTOMER')

        return new Promise((resolve, reject) => {
            axios.post(Config.apiPath + 'type/customer/save', form)
                .then(
                    response => {
                        
                        commit('STORE_TYPE_CUSTOMER_OK', response.data.data)                       
                        resolve(response.data)
                    })
                .catch(error => {
                   
                    commit('STORE_TYPE_CUSTOMER_FAIL')
                    reject(error.response.data)
                })
        })
    },
    updateTypecustomer({commit, dispatch}, form) {
        commit('UPDATE_TYPE_CUSTOMER')

        return new Promise((resolve, reject) => {
            axios.put(Config.apiPath + 'type/customer/update/'+form.id,form)
                .then(
                    response => {
                        commit('UPDATE_TYPE_CUSTOMER_OK', response.data.data)                       
                        resolve(response.data)
                    })
                .catch(error => {
                    commit('UPDATE_TYPE_CUSTOMER_FAIL')
                    reject(error.response.data)
                })
        })
    },
    deleteTypecustomer({commit, dispatch}, form) {
        commit('DELETE_TYPE_CUSTOMER')

        return new Promise((resolve, reject) => {
           
            axios.delete(Config.apiPath + 'type/customer/delete/'+form.id, form)
                .then(
                    response => {
                        
                        commit('DELETE_TYPE_CUSTOMER_OK', response.data.data)                       
                        resolve(response.data)
                    })
                .catch(error => {
                    commit('DELETE_TYPE_CUSTOMER_FAIL')
                    reject(error.response.data)
                })
        })
    },
    loadTypecustomer({commit, dispatch}, form) {
        commit('LOAD_TYPE_CUSTOMER')

        return new Promise((resolve, reject) => {
            axios.get(Config.apiPath + 'type/customer/find/' + form, {form})
                .then(
                    response => {
                        if (response.data.code !== 500) {
                            commit('LOAD_TYPE_CUSTOMER_OK', response.data.data)
                            resolve(response.data.data)
                        } else {
                            commit('LOAD_TYPE_CUSTOMER_FAIL')
                            reject()
                        }

                    })
                .catch(error => {
                    commit('LOAD_TYPE_CUSTOMER_FAIL')
                    reject()
                })
        })
    },

    activeChangeTypeCustomer({commit, dispatch}, id) {

        return new Promise((resolve, reject) => {
          axios.post(Config.apiPath + 'type/customer/change/active/' + id)
          .then(
            response => {
              resolve(response.data)
          })
          .catch(error => {
            reject(error.data)
        })
      })
    }
}

const mutations = {
   
    STORE_TYPE_CUSTOMER_OK(state, typecustomer) {
       // state.CUSTOMER = CUSTOMER;       
      //  state.CUSTOMER.push(CUSTOMER);
    },
    UPDATE_TYPE_CUSTOMER_OK(state, typecustomer) {
        state.typecustomer = typecustomer;      
    },
    DELETE_TYPE_CUSTOMER_OK(state, typecustomer) {
        state.typecustomer = typecustomer;      
    },
    LOAD_TYPE_CUSTOMERS_OK(state,data)
    {
        state.typecustomers = data;
    },
    LOAD_TYPE_CUSTOMERS_ACTIVE_OK(state,data)
    {
        state.typecustomers = data;
    },
    LOAD_TYPE_CUSTOMER_OK(state,typecustomer)
    {
        state.typecustomer = typecustomer;
    }

}

export default {
    state,
    actions,
    mutations
}
