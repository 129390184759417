import axios from 'axios'
import * as Config from '../../config'
import Cookies from 'js-cookie'

const state = {
  membership: {},
  memberships: {}
}

const actions = {

    loadMemberships ({ commit, dispatch }) {
    commit('LOAD_MEMBERSHIPS')

    return new Promise((resolve, reject) => {
      axios.get(Config.apiPath + 'membership')
      .then(
        response => {
          if (response.data.code !== 500) {
            commit('LOAD_MEMBERSHIPS_OK', response.data.data)
            resolve(response.data.data)
          } else {
            commit('LOAD_MEMBERSHIPS_FAIL')
            reject()
          }
        })
      .catch(error => {
        commit('LOAD_MEMBERSHIPS_FAIL')
        reject()
      })
    })
  },
  storeMembership ({ commit, dispatch }, form) {
    commit('STORE_MEMBERSHIP')

    return new Promise((resolve, reject) => {
      axios.post(Config.apiPath + 'membership/save', form)
      .then(
        response => {
          commit('STORE_MEMBERSHIP_OK')
          resolve(response.data)
        })
      .catch(error => {
        commit('STORE_MEMBERSHIP_FAIL')
        reject(error.response.data)
      })
    })
  },
  updateMembership ({ commit, dispatch }, form) {
    commit('UPDATE_MEMBERSHIP')

    return new Promise((resolve, reject) => {
      axios.put(Config.apiPath + 'membership/' + form.id, form)
      .then(
        response => {
          commit('UPDATE_MEMBERSHIP_OK')
          resolve(response.data)
        })
      .catch(error => {
        commit('UPDATE_MEMBERSHIP_FAIL')
        reject(error.response.data)
      })
    })
  },
  deleteMembership ({ commit, dispatch }, form) {
    commit('DELETE_MEMBERSHIP')

    return new Promise((resolve, reject) => {
      axios.delete(Config.apiPath + 'membership/delete/' + form.id, form)
      .then(
        response => {
          commit('DELETE_MEMBERSHIP_OK')
          resolve(response.data)
        })
      .catch(error => {
        commit('DELETE_MEMBERSHIP_FAIL')
        reject(error.response.data)
      })
    })
  },
  loadMembership ({ commit, dispatch }, form) {
    commit('LOAD_MEMBERSHIP')

    return new Promise((resolve, reject) => {
      axios.get(Config.apiPath + 'membership/' + form, { form })
      .then(
        response => {
          if (response.data.code !== 500) {
            commit('LOAD_MEMBERSHIP_OK', response.data.data)
            resolve(response.data.data)
          } else {
            commit('LOAD_MEMBERSHIP_FAIL')
            reject()
          }
        })
      .catch(error => {
        commit('LOAD_MEMBERSHIP_FAIL')
        reject()
      })
    })
  },
  loadMembershipsByEvent ({ commit, dispatch }, idevent) {
    commit('LOAD_MEMBERSHIPS_BY_EVENT')

    return new Promise((resolve, reject) => {
      axios.get(Config.apiPath + 'membership/filterby/id_event/' + idevent)
      .then(
        response => {
          if (response.data.code !== 500) {
            commit('LOAD_MEMBERSHIPS_BY_EVENT_OK', response.data.data)
            resolve(response.data.data)
          } else {
            commit('LOAD_MEMBERSHIPS_BY_EVENT_FAIL')
            reject()
          }
        })
      .catch(error => {
        commit('LOAD_MEMBERSHIPS_BY_EVENT_FAIL')
        reject()
      })
    })
  },
  loadTypeMembership ({ commit, dispatch }) {
    commit('LOAD_TYPE_MEMBERSHIP')

    return new Promise((resolve, reject) => {
      axios.get(Config.apiPath + 'type/membership/active')
      .then(
        response => {
          if (response.data.code !== 500) {
            commit('LOAD_TYPE_MEMBERSHIP_OK')
            resolve(response.data.data)
          } else {
            commit('LOAD_TYPE_MEMBERSHIP_FAIL')
            reject()
          }
        })
      .catch(error => {
        commit('LOAD_TYPE_MEMBERSHIP_FAIL')
        reject()
      })
    })
  },
  activeChangeMembership({commit, dispatch}, id) {
    
    return new Promise((resolve, reject) => {
      axios.post(Config.apiPath + 'membership/change/active/' + id)
      .then(
        response => {
          resolve(response.data)
        })
      .catch(error => {
        reject(error.data)
      })
    })
  }

}

const mutations = {
  LOAD_MEMBERSHIP_OK (state, memberships) {
    state.membership = memberships
  },
  LOAD_MEMBERSHIPS_OK (state, memberships) {
    state.memberships = memberships
  },
  LOAD_MEMBERSHIPS_BY_COMPANY_OK (state, memberships) {
    state.memberships = memberships
  }
  
}

export default {
  state,
  actions,
  mutations
}
