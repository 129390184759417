import { makeMutations } from "../helpers";

const state = {
  isLoading: true
};

const actions = {
  stopLoading({ commit }) {
    commit("STOP_LOADING");
  }
};

const mutations = {
  ...makeMutations(
    [
      //TOPUPS
      "LOAD_TOPUPTRANSACTION",
       //TOPUPS
       "LOAD_REFUNDTRANSACTION",
      //AUDITS
      "LOAD_AUDITS",
      "CHECK_LOGIN",
      "LOGIN",
      "LOGOUT",
      "REGISTER",
      "UPDATE_PROFILE",
      "LOAD_USERS",
      "LOAD_USER",
      "UPDATE_USER",
      "DELETE_USER",
      "SET_LOADING",
      // STATION
      "LOAD_SALES_STATIONS",
      "LOAD_SALES_STATIONS_DETAIL",
      "LOAD_STATION",
      "COPY_STATION",
      "LOAD_STATIONS",
      "LOAD_PRODUCTS_STATIONS",
      "LOAD_DEVICES_STATIONS",
      "LOAD_STATIONS_BY_EVENT",
      "LOAD_STATIONS_ACTIVE",
      "LOAD_STATIONS_BY_CATEGORY_VENDOR",
      "LOAD_STATIONS_BY_CATEGORY_TOPUP",
      "LOAD_STATIONS_BY_CATEGORY_PRODUCT_FREE",
      "STORE_STATION",
      "UPDATE_STATION",
      "DELETE_STATION",
      //ZONE STATIONS
      'LOAD_STATIONS_BY_ZONE_EVENT',
      //UBICATION
      "LOAD_UBICATIONS",
      "LOAD_UBICATION",
      "LOAD_UBICATIONS_BY_EVENT",
      "STORE_UBICATION",
      "UPDATE_UBICATION",
      "DELETE_UBICATION",
      //DAY
      "LOAD_DAYS",
      "LOAD_DAY",
      "LOAD_DAYS_BY_EVENT",
      "STORE_DAY",
      "UPDATE_DAY",
      "DELETE_DAY",
      // STAFF
      "LOAD_STAFF",
      "LOAD_STAFFS",
      "LOAD_STAFFS_BY_EVENT",
      "LOAD_STAFFS_BY_STATION",
      "LOAD_STAFFS_ACTIVE",
      "LOAD_STAFF_LOGS",
      "STORE_STAFF",
      "UPDATE_STAFF",
      "DELETE_STAFF",

      // PRODUCTSTATION
      "LOAD_PRODUCT_BY_STATION",
      "LOAD_PRODUCTS_BY_STATION",
      "LOAD_PRODUCTS_BY_STATION_ACTIVE",
      "STORE_PRODUCT_BY_STATION",
      "UPDATE_PRODUCT_BY_STATION",
      "DELETE_PRODUCT_BY_STATION",
      // CUSTOMER
      "LOAD_CUSTOMER",
      "LOAD_CUSTOMER_BY_EMAIL",
      "LOAD_CUSTOMER_BY_CODE",
      "LOAD_CUSTOMERS",
      "STORE_CUSTOMER",
      "UPDATE_CUSTOMER",
      "DELETE_CUSTOMER",
      // COMPANY
      "LOAD_COMPANY",
      "LOAD_COMPANY_DASHBOARD",
      "LOAD_TYPE_COMPANIES",
      "LOAD_COMPANIES",
      "STORE_COMPANY",
      "UPDATE_COMPANY",
      "DELETE_COMPANY",
      // PRODUCT
      "LOAD_PRODUCTS",
      "LOAD_PRODUCT",
      "LOAD_PRODUCTS_BY_COMPANY",
      "STORE_PRODUCT",
      "UPDATE_PRODUCT",
      "LOAD_TYPE_PRODUCT",
      // DEVICE
      "LOAD_DEVICES",
      "LOAD_DEVICE",
      "LOAD_CONNECTION_DEVICE",
      "STORE_DEVICE",
      "UPDATE_DEVICE",
      "LOAD_REFUNDS_DEVICES",
      "LOAD_TOPUPS_DEVICES",
      // EVENT
      "LOAD_TIMEZONES",
      "LOAD_TYPE_EVENTS",
      "LOAD_EVENT",
      "LOAD_EVENTS",
      "LOAD_NAME_EVENT",
      "STORE_EVENT",
      "UPDATE_EVENT",
      "UPDATE_PASSWORD_EVENT",
      "UPDATE_STATUS_EVENT",
      "DELETE_EVENT",
      "LOAD_SALES_DEVICES_EVENT",
      "LOAD_EVENT_ANALITIC_OK",
      "LOAD_EVENT_ANALITIC_FAIL",
      "LOAD_EVENT_ANALITIC",

      //TYPES MASTERS

      // TYPE COMPANY
      "LOAD_TYPES_COMPANY",
      "LOAD_TYPE_COMPANY",
      "LOAD_TYPE_COMPANYS_ACTIVE",
      "STORE_TYPE_COMPANY",
      "UPDATE_TYPE_COMPANY",
      "DELETE_TYPE_COMPANY",

      // TYPE CREDIT
      "LOAD_TYPES_CREDIT",
      "LOAD_TYPE_CREDIT",
      "LOAD_TYPE_CREDITS_ACTIVE",
      "STORE_TYPE_CREDIT",
      "UPDATE_TYPE_CREDIT",
      "DELETE_TYPE_CREDIT",

      // TYPECUSTOMER
      "LOAD_TYPE_CUSTOMER",
      "LOAD_TYPE_CUSTOMERS",
      "LOAD_TYPE_CUSTOMERS_ACTIVE",
      "STORE_TYPE_CUSTOMER",
      "UPDATE_TYPE_CUSTOMER",
      "DELETE_TYPE_CUSTOMER",

      // TYPEEVENT
      "LOAD_TYPE_EVENT",
      "LOAD_TYPE_EVENTS",
      "LOAD_TYPE_EVENTS_ACTIVE",
      "STORE_TYPE_EVENT",
      "UPDATE_TYPE_EVENT",
      "DELETE_TYPE_EVENT",

      // TYPEKTAG
      "LOAD_TYPE_KTAG",
      "LOAD_TYPE_KTAGS",
      "LOAD_TYPE_KTAGS_ACTIVE",
      "STORE_TYPE_KTAG",
      "UPDATE_TYPE_KTAG",
      "DELETE_TYPE_KTAG",

      // TYPEPAY
      "LOAD_TYPE_PAY",
      "LOAD_TYPE_PAYS",
      "LOAD_TYPE_PAYS_ACTIVE",
      "STORE_TYPE_PAY",
      "UPDATE_TYPE_PAY",
      "DELETE_TYPE_PAY",

      // TYPE PRODUCT
      "LOAD_TYPE_PRODUCT",
      "LOAD_TYPE_PRODUCTS",
      "LOAD_TYPE_PRODUCTS_ACTIVE",
      "STORE_TYPE_PRODUCT",
      "UPDATE_TYPE_PRODUCT",
      "DELETE_TYPE_PRODUCT",

      // TYPE STAFF
      "LOAD_TYPE_STAFF",
      "LOAD_TYPE_STAFFS",
      "LOAD_TYPE_STAFFS_ACTIVE",
      "STORE_TYPE_STAFF",
      "UPDATE_TYPE_STAFF",
      "DELETE_TYPE_STAFF",

      // TYPESTATION
      "LOAD_TYPE_STATION",
      "LOAD_TYPE_STATIONS",
      "LOAD_CATEGORY_STATIONS",
      "LOAD_TYPE_STATIONS_ACTIVE",
      "STORE_TYPE_STATION",
      "UPDATE_TYPE_STATION",
      "DELETE_TYPE_STATION",

      // CURRENCIES
      "LOAD_CURRENCIES",
      "LOAD_TEAMS",
      "LOAD_TEAM",
      "UPDATE_TEAM",
      "DELETE_TEAM",

      //USER
      "UPDATE_USER_NAME",
      "UPDATE_USER_PASSWORD",
      "UPDATE_USER_PASSWORD_ADMIN",
      "UPDATE_USER_PASSWORD_ADMIN_OK",
      "UPDATE_USER_PASSWORD_ADMIN_FAIL",

      //KTAG
      "LOAD_KTAG_USER",
      "LOAD_KTAG_CONEXION",
      "LOAD_DETAIL_KTAG",
      "LOAD_DETAIL_KTAG_WORKER",
      "LOAD_BALANCE_DETAIL_KTAG",
      "LOAD_TOPUPS_KTAG",
      "LOAD_REFUNDS_KTAG",
      "LOAD_SALES_KTAG",
      "LOAD_TOPUP_ANALYTIC_STATIONS",
      //ANALYTICS
      "LOAD_ACTIVATIONS",
      "LOAD_MONEYBALANCE",
      "LOAD_MONEYBALANCE_BY_UBICATION",
      "LOAD_MONEYBALANCE_BY_DAY",
      "LOAD_STATION_ANALYTIC",
      "LOAD_STATION_ANALYTIC_TOPUP",
      "LOAD_STATION_ANALYTIC_PRODUCT_TOPUP",
      "LOAD_UNSPENTCREDITS",
      "LOAD_KTAG_CUSTOMER",
      "LOAD_KTAG_STAFF",
      "LOAD_MONEY_PERHOUR",
      "LOAD_DASHBOARD_EVENT_RESUME",

      //CONFIGS
      "LOAD_CONFIG_STATION",
      "STORE_CONFIG_STATION",
      "UPDATE_CONFIG_STATION",

      //---- ZONE ACCESS ----
       //LOG_ACCESS
       "LOAD_LOGACCESS",
       "LOAD_LOGACCESSS",
       "LOAD_LOGACCESSS_ACTIVE",
       "LOAD_LOGACCESSS_BY_EVENT",
       "STORE_LOGACCESS",
       "UPDATE_LOGACCESS",
       "DELETE_LOGACCESS",
       "CHANGUE_LOGACCESSS",
        //LOG_STATION
        "LOAD_LOGSTATION",
        "LOAD_LOGSTATIONS",
        "LOAD_LOGSTATIONS_ACTIVE",
        "LOAD_LOGSTATIONS_BY_EVENT",
        "STORE_LOGSTATION",
        "UPDATE_LOGSTATION",
        "DELETE_LOGSTATION",
        "CHANGUE_LOGSTATIONS",
      //MEMBERSHIPS
      "LOAD_MEMBERSHIP",
      "LOAD_MEMBERSHIPS",
      "LOAD_MEMBERSHIPS_ACTIVE",
      "LOAD_MEMBERSHIPS_BY_EVENT",
      "STORE_MEMBERSHIP",
      "UPDATE_MEMBERSHIP",
      "DELETE_MEMBERSHIP",
      "CHANGUE_MEMBERSHIPS",

      //ZONEACCESS
      "LOAD_ZONEACCESS",
      "LOAD_ZONEACCESSS",
      "LOAD_ZONEACCESSS_BY_EVENT",
      "LOAD_ZONEACCESS_ACTIVE",
      "STORE_ZONEACCESS",
      "UPDATE_ZONEACCESS",
      "CHANGUE_ZONEACCESS",
      //ZONEEVENT
      "LOAD_ZONEEVENT",
      "LOAD_ZONEEVENT_ACTIVE",
      "LOAD_ZONEEVENTS_BY_EVENT",
      "STORE_ZONEEVENT",
      "UPDATE_ZONEEVENT",
      "CHANGUE_ZONEEVENT",
      "DELETE_ZONEEVENT",
       //WORKTEAM
       "LOAD_WORKTEAM",
       "LOAD_WORKTEAM_ACTIVE",
       "LOAD_WORKTEAMS_BY_EVENT",
       "STORE_WORKTEAM",
       "UPDATE_WORKTEAM",
       "CHANGUE_WORKTEAM",
       "DELETE_WORKTEAM",
      //ACCESS LOG
      "LOAD_ACCESS_LOG",
      "LOAD_EXCEL"



    ],
    state => {
      state.isLoading = true;
    }
  ),

  ...makeMutations(
    [
      //AUDITS
      "LOAD_EXCEL_OK",
      "LOAD_EXCEL_FAIL",
      "LOAD_AUDITS_OK",
      "LOAD_AUDITS_FAIL",
      "STOP_LOADING",
      "CHECK_LOGIN_OK",
      "LOGOUT_OK",
      "CHECK_LOGIN_SPA",
      "CHECK_LOGIN_FAIL",
      "LOGIN_OK",
      "LOGIN_FAIL",
      "REGISTER_OK",
      "REGISTER_FAIL",
      "UPDATE_PROFILE_OK",
      "UPDATE_PROFILE_FAIL",
      "LOAD_USERS_OK",
      "LOAD_USERS_FAIL",
      "LOAD_USER_OK",
      "LOAD_USER_FAIL",
      "UPDATE_USER_OK",
      "UPDATE_USER_FAIL",
      "DELETE_USER_OK",
      "DELETE_USER_FAIL",
      // STATION
      "LOAD_SALES_STATIONS_OK",
      "LOAD_SALES_STATIONS_DETAIL_OK",
      "LOAD_SALES_STATIONS_DETAIL_FAIL",
      "LOAD_STATION_OK",
      "COPY_STATION_OK",
      "LOAD_PRODUCTS_STATIONS_OK",
      "LOAD_PRODUCTS_STATIONS_FAIL",
      "LOAD_DEVICES_STATIONS_OK",
      "LOAD_DEVICES_STATIONS_FAIL",
      "LOAD_STATIONS_BY_EVENT_OK",
      "LOAD_STATIONS_BY_EVENT_FAIL",
      "LOAD_STATIONS_BY_CATEGORY_VENDOR_OK",
      "LOAD_STATIONS_BY_CATEGORY_VENDOR_FAIL",
      "LOAD_STATIONS_BY_CATEGORY_TOPUP_OK",
      "LOAD_STATIONS_BY_CATEGORY_TOPUP_FAIL",
      "LOAD_STATIONS_BY_CATEGORY_PRODUCT_FREE_OK",
      "LOAD_STATIONS_BY_CATEGORY_PRODUCT_FREE_FAIL",
      "LOAD_STATIONS_OK",
      "LOAD_STATION_FAIL",
      "COPY_STATION_FAIL",
      "LOAD_STATIONS_FAIL",
      "LOAD_STATIONS_ACTIVE_OK",
      "LOAD_STATIONS_FAIL_OK",

      //LOAD STATIONS
      'LOAD_STATIONS_BY_ZONE_EVENT_OK',
      'LOAD_STATIONS_BY_ZONE_EVENT_FAIL',

      "STORE_STATION_OK",
      "STORE_STATION_FAIL",
      "UPDATE_STATION_OK",
      "UPDATE_STATION_FAIL",
      "DELETE_STATION_OK",
      "DELETE_STATION_FAIL",
      //UBICATION
      "LOAD_UBICATIONS_OK",
      "LOAD_UBICATIONS_FAIL",
      "LOAD_UBICATIONS_ACTIVE_OK",
      "LOAD_UBICATIONS_ACTIVE_FAIL",
      "LOAD_UBICATION_OK",
      "LOAD_UBICATION_FAIL",
      "STORE_UBICATION_OK",
      "STORE_UBICATION_FAIL",
      "UPDATE_UBICATION_OK",
      "UPDATE_UBICATION_FAIL",
      "DELETE_UBICATION_OK",
      "DELETE_UBICATION_FAIL",
      "LOAD_UBICATIONS_BY_EVENT_OK",
      "LOAD_UBICATIONS_BY_EVENT_FAIL",
       //DAY
       "LOAD_DAYS_OK",
       "LOAD_DAYS_FAIL",
       "LOAD_DAYS_ACTIVE_OK",
       "LOAD_DAYS_ACTIVE_FAIL",
       "LOAD_DAY_OK",
       "LOAD_DAY_FAIL",
       "STORE_DAY_OK",
       "STORE_DAY_FAIL",
       "UPDATE_DAY_OK",
       "UPDATE_DAY_FAIL",
       "DELETE_DAY_OK",
       "DELETE_DAY_FAIL",
       "LOAD_DAYS_BY_EVENT_OK",
       "LOAD_DAYS_BY_EVENT_FAIL",
      // PRODUCTSTATION
      "LOAD_PRODUCT_BY_STATION_OK",
      "LOAD_PRODUCT_BY_STATION_FAIL",
      "LOAD_PRODUCTS_BY_STATION_OK",
      "LOAD_PRODUCTS_BY_STATION_FAIL",
      "LOAD_PRODUCTS_BY_STATION_ACTIVE_OK",
      "LOAD_PRODUCTS_BY_STATION_ACTIVE_FAIL",
      "STORE_PRODUCT_BY_STATION_OK",
      "STORE_PRODUCT_BY_STATION_FAIL",
      "UPDATE_PRODUCT_BY_STATION_OK",
      "UPDATE_PRODUCT_BY_STATION_FAIL",
      "DELETE_PRODUCT_BY_STATION_OK",
      "DELETE_PRODUCT_BY_STATION_FAIL",
      //TOPUP
      "LOAD_TOPUPTRANSACTION_OK",
      "LOAD_TOPUPTRANSACTION_FAIL",
      //REFUND
      "LOAD_REFUNDTRANSACTION_OK",
      "LOAD_REFUNDTRANSACTION_FAIL",
      // STAFF
      "LOAD_STAFF_OK",
      "LOAD_STAFFS_OK",
      "LOAD_STAFFS_BY_EVENT_OK",
      "LOAD_STAFFS_BY_EVENT_FAIL",
      "LOAD_STAFFS_BY_STATION_OK",
      "LOAD_STAFFS_BY_STATION_FAIL",
      "LOAD_STAFF_FAIL",
      "LOAD_STAFFS_FAIL",
      "LOAD_STAFF_LOGS_OK",
      "LOAD_STAFF_LOGS_FAIL",
      "STORE_STAFF_OK",
      "STORE_STAFF_FAIL",
      "UPDATE_STAFF_OK",
      "UPDATE_STAFF_FAIL",
      "DELETE_STAFF_OK",
      "DELETE_STAFF_FAIL",
      // CUSTOMER
      "LOAD_CUSTOMER_OK",
      "LOAD_CUSTOMERS_OK",
      "LOAD_CUSTOMER_FAIL",
      "LOAD_CUSTOMERS_FAIL",
      "LOAD_CUSTOMER_BY_EMAIL_OK",
      "LOAD_CUSTOMER_BY_EMAIL_FAIL",
      "LOAD_CUSTOMER_BY_CODE_OK",
      "LOAD_CUSTOMER_BY_CODE_FAIL",
      "STORE_CUSTOMER_OK",
      "STORE_CUSTOMER_FAIL",
      "UPDATE_CUSTOMER_OK",
      "UPDATE_CUSTOMER_FAIL",
      "DELETE_CUSTOMER_OK",
      "DELETE_CUSTOMER_FAIL",
      // COMPANY
      "LOAD_COMPANY_OK",
      "LOAD_TYPE_COMPANIES_OK",
      "LOAD_COMPANY_DASHBOARD_OK",
      "LOAD_COMPANIES_OK",
      "LOAD_COMPANY_FAIL",
      "LOAD_COMPANIES_FAIL",
      "LOAD_TYPE_COMPANIES_FAIL",
      "LOAD_COMPANY_DASHBOARD_FAIL",
      "STORE_COMPANY_OK",
      "STORE_COMPANY_FAIL",
      "UPDATE_COMPANY_OK",
      "UPDATE_COMPANY_FAIL",
      "DELETE_COMPANY_OK",
      "DELETE_COMPANY_FAIL",
      // PRODUCT
      "LOAD_PRODUCTS_OK",
      "LOAD_PRODUCTS_FAIL",
      "STORE_PRODUCT_OK",
      "STORE_PRODUCT_FAIL",
      "UPDATE_PRODUCT_OK",
      "UPDATE_PRODUCT_FAIL",
      "LOAD_PRODUCT_OK",
      "LOAD_PRODUCT_FAIL",
      "LOAD_TYPE_PRODUCT_OK",
      "LOAD_TYPE_PRODUCT_FAIL",
      "LOAD_PRODUCTS_BY_COMPANY_OK",
      "LOAD_PRODUCTS_BY_COMPANY_FAIL",

      // DEVICE
      "LOAD_DEVICES_OK",
      "LOAD_DEVICES_FAIL",
      "LOAD_CONNECTION_DEVICE_OK",
      "LOAD_CONNECTION_DEVICE_FAIL",
      "LOAD_REFUNDS_DEVICES_OK",
      "LOAD_REFUNDS_DEVICES_FAIL",
      "LOAD_TOPUPS_DEVICES_OK",
      "LOAD_TOPUPS_DEVICES_FAIL",
      "STORE_DEVICE_OK",
      "STORE_DEVICE_FAIL",
      "UPDATE_DEVICE_OK",
      "UPDATE_DEVICE_FAIL",
      "LOAD_DEVICE_OK",
      "LOAD_DEVICE_FAIL",

      // EVENT
      "LOAD_TIMEZONES_OK",
      "LOAD_TYPE_EVENTS_OK",
      "LOAD_TIMEZONES_FAIL",
      "LOAD_EVENT_OK",
      "LOAD_NAME_EVENT_OK",
      "LOAD_NAME_EVENT_FAIL",
      "LOAD_EVENTS_OK",
      "LOAD_EVENT_CONFIG_OK",
      "LOAD_EVENT_CONFIG_FAIL",
      "LOAD_EVENT_FAIL",
      "LOAD_EVENTS_FAIL",
      "LOAD_EVENT_ANALITIC_OK",
      "LOAD_EVENT_ANALITIC_FAIL",
     
      "LOAD_TYPE_EVENTS_FAIL",
      "STORE_EVENT_OK",
      "STORE_EVENT_FAIL",
      "UPDATE_EVENT_OK",
      "UPDATE_EVENT_FAIL",
      "UPDATE_PASSWORD_EVENT_OK",
      "UPDATE_PASSWORD_EVENT_FAIL",
      "UPDATE_STATUS_EVENT_OK",
      "UPDATE_STATUS_EVENT_FAIL",
      "DELETE_EVENT_OK",
      "DELETE_EVENT_FAIL",
      "LOAD_SALES_DEVICES_EVENT_OK",
      "LOAD_SALES_DEVICES_EVENT_FAIL",

      //TYPES MASTER

      // TYPE COMPANY
      "LOAD_TYPES_COMPANY_OK",
      "LOAD_TYPES_COMPANY_FAIL",
      "LOAD_TYPE_COMPANY_ACTIVE_OK",
      "LOAD_TYPE_COMPANYS_ACTIVE_OK",
      "STORE_TYPE_COMPANY_OK",
      "STORE_TYPE_COMPANY_FAIL",
      "UPDATE_TYPE_COMPANY_OK",
      "UPDATE_TYPE_COMPANY_FAIL",
      "DELETE_TYPE_COMPANY_OK",
      "DELETE_TYPE_COMPANY_FAIL",
      "LOAD_TYPE_COMPANY_OK",
      "LOAD_TYPE_COMPANY_FAIL",
      // TYPE CREDITS
      "LOAD_TYPE_CREDIT_OK",
      "LOAD_TYPE_CREDITS_OK",
      "LOAD_TYPE_CREDITS_ACTIVE_OK",
      "LOAD_TYPE_CREDIT_FAIL",
      "LOAD_TYPE_CREDITS_FAIL",
      "LOAD_TYPE_CREDITS_ACTIVE_FAIL",
      "STORE_TYPE_CREDIT_OK",
      "STORE_TYPE_CREDIT_FAIL",
      "UPDATE_TYPE_CREDIT_OK",
      "UPDATE_TYPE_CREDIT_FAIL",
      "DELETE_TYPE_CREDIT_OK",
      "DELETE_TYPE_CREDIT_FAIL",
      // TYPE CUSTOMER
      "LOAD_TYPE_CUSTOMER_OK",
      "LOAD_TYPE_CUSTOMERS_OK",
      "LOAD_TYPE_CUSTOMERS_ACTIVE_OK",
      "LOAD_TYPE_CUSTOMER_FAIL",
      "LOAD_TYPE_CUSTOMERS_FAIL",
      "LOAD_TYPE_CUSTOMERS_ACTIVE_FAIL",
      "STORE_TYPE_CUSTOMER_OK",
      "STORE_TYPE_CUSTOMER_FAIL",
      "UPDATE_TYPE_CUSTOMER_OK",
      "UPDATE_TYPE_CUSTOMER_FAIL",
      "DELETE_TYPE_CUSTOMER_OK",
      "DELETE_TYPE_CUSTOMER_FAIL",
      // TYPE EVENT
      "LOAD_TYPE_EVENT_OK",
      "LOAD_TYPE_EVENTS_OK",
      "LOAD_TYPE_EVENTS_ACTIVE_OK",
      "LOAD_TYPE_EVENT_FAIL",
      "LOAD_TYPE_EVENTS_FAIL",
      "LOAD_TYPE_EVENTS_ACTIVE_FAIL",
      "STORE_TYPE_EVENT_OK",
      "STORE_TYPE_EVENT_FAIL",
      "UPDATE_TYPE_EVENT_OK",
      "UPDATE_TYPE_EVENT_FAIL",
      "DELETE_TYPE_EVENT_OK",
      "DELETE_TYPE_EVENT_FAIL",
      // TYPE KTAG
      "LOAD_TYPE_KTAG_OK",
      "LOAD_TYPE_KTAGS_OK",
      "LOAD_TYPE_KTAGS_ACTIVE_OK",
      "LOAD_TYPE_KTAG_FAIL",
      "LOAD_TYPE_KTAGS_FAIL",
      "LOAD_TYPE_KTAGS_ACTIVE_FAIL",
      "STORE_TYPE_KTAG_OK",
      "STORE_TYPE_KTAG_FAIL",
      "UPDATE_TYPE_KTAG_OK",
      "UPDATE_TYPE_KTAG_FAIL",
      "DELETE_TYPE_KTAG_OK",
      "DELETE_TYPE_KTAG_FAIL",
      // TYPE PAY
      "LOAD_TYPE_PAY_OK",
      "LOAD_TYPE_PAYS_OK",
      "LOAD_TYPE_PAYS_ACTIVE_OK",
      "LOAD_TYPE_PAY_FAIL",
      "LOAD_TYPE_PAYS_FAIL",
      "LOAD_TYPE_PAYS_ACTIVE_FAIL",
      "STORE_TYPE_PAY_OK",
      "STORE_TYPE_PAY_FAIL",
      "UPDATE_TYPE_PAY_OK",
      "UPDATE_TYPE_PAY_FAIL",
      "DELETE_TYPE_PAY_OK",
      "DELETE_TYPE_PAY_FAIL",
      // TYPE PRODUCT
      "LOAD_TYPE_PRODUCTS_OK",
      "LOAD_TYPE_PRODUCTS_FAIL",
      "LOAD_TYPE_PRODUCTS_ACTIVE_OK",
      "LOAD_TYPE_PRODUCTS_ACTIVE_FAIL",
      "LOAD_TYPE_PRODUCT_OK",
      "LOAD_TYPE_PRODUCT_FAIL",
      "STORE_TYPE_PRODUCT_OK",
      "STORE_TYPE_PRODUCT_FAIL",
      "UPDATE_TYPE_PRODUCT_OK",
      "UPDATE_TYPE_PRODUCT_FAIL",
      "DELETE_TYPE_STAFF_OK",
      "DELETE_TYPE_STAFF_FAIL",
      // TYPE STAFF
      "LOAD_TYPE_STAFF_OK",
      "LOAD_TYPE_STAFFS_OK",
      "LOAD_TYPE_STAFFS_ACTIVE_OK",
      "LOAD_TYPE_STAFF_FAIL",
      "LOAD_TYPE_STAFFS_FAIL",
      "LOAD_TYPE_STAFFS_ACTIVE_FAIL",
      "STORE_TYPE_STAFF_OK",
      "STORE_TYPE_STAFF_FAIL",
      "UPDATE_TYPE_STAFF_OK",
      "UPDATE_TYPE_STAFF_FAIL",
      "DELETE_TYPE_STAFF_OK",
      "DELETE_TYPE_STAFF_FAIL",
      // TYPE STATION
      "LOAD_TYPE_STATION_OK",
      "LOAD_TYPE_STATIONS_OK",
      "LOAD_CATEGORY_STATIONS_OK",
      "LOAD_CATEGORY_STATIONS_FAIL",
      "LOAD_TYPE_STATIONS_ACTIVE_OK",
      "LOAD_TYPE_STATIONS_ACTIVE_FAIL",
      "LOAD_TYPE_STATION_FAIL",
      "LOAD_TYPE_STATIONS_FAIL",
      "STORE_TYPE_STATION_OK",
      "STORE_TYPE_STATION_FAIL",
      "UPDATE_TYPE_STATION_OK",
      "UPDATE_TYPE_STATION_FAIL",
      "DELETE_TYPE_STATION_OK",
      "DELETE_TYPE_STATION_FAIL",
      "LOAD_CURRENCIES_OK",
      "LOAD_CURRENCIES_FAIL",

       //USER
       "UPDATE_USER_NAME_OK",
       "UPDATE_USER_PASSWORD_OK",
       "UPDATE_USER_NAME_FAIL",
       "UPDATE_USER_PASSWORD_FAIL",
       "UPDATE_USER_PASSWORD_ADMIN",
       "UPDATE_USER_PASSWORD_ADMIN_OK",
       "UPDATE_USER_PASSWORD_ADMIN_FAIL",

       //KTAG
       "LOAD_KTAG_USER_OK",
       "LOAD_KTAG_USER_FAIL",
       "LOAD_DETAIL_KTAG_FAIL",
       "LOAD_DETAIL_KTAG_OK",
       "LOAD_DETAIL_KTAG_WORKER_FAIL",
       "LOAD_DETAIL_KTAG_WORKER_OK",
       "LOAD_BALANCE_DETAIL_KTAG_FAIL",
       "LOAD_BALANCE_DETAIL_KTAG_OK",
       "LOAD_TOPUPS_KTAG_OK",
      "LOAD_REFUNDS_KTAG_OK",
      "LOAD_SALES_KTAG_OK",
      "LOAD_TOPUPS_KTAG_FAIL",
      "LOAD_REFUNDS_KTAG_FAIL",
      "LOAD_SALES_KTAG_FAIL",
      "LOAD_TOPUP_ANALYTIC_STATIONS_OK",
      "LOAD_TOPUP_ANALYTIC_STATIONS_FAIL",
       //ANALYTICS_OK
      "LOAD_ACTIVATIONS_OK",
      "LOAD_MONEYBALANCE_OK",
      "LOAD_MONEYBALANCE_BY_UBICATION_OK",
      "LOAD_MONEYBALANCE_BY_DAY_OK",
      "LOAD_STATION_ANALYTIC_OK",
      "LOAD_STATION_ANALYTIC_TOPUP_OK",
      "LOAD_STATION_ANALYTIC_PRODUCT_TOPUP_OK",
      "LOAD_STATION_ANALYTIC_PRODUCT_TOPUP_FAIL",
      "LOAD_UNSPENTCREDITS_OK",
      "LOAD_MONEY_PERHOUR_OK",
      "LOAD_DASHBOARD_EVENT_RESUME_OK",
      "LOAD_DASHBOARD_EVENT_RESUME_FAIL",

      //ANALYTICS_FAIL
      "LOAD_ACTIVATIONS_FAIL",
      "LOAD_MONEYBALANCE_FAIL",
      "LOAD_MONEYBALANCE_BY_UBICATION_FAIL",
      "LOAD_MONEYBALANCE_BY_DAY_FAIL",
      "LOAD_STATION_ANALYTIC_TOPUP_FAIL",
      "LOAD_UNSPENTCREDITS_FAIL",
      "LOAD_KTAG_CUSTOMER_OK",
      "LOAD_KTAG_STAFF_OK",
      "LOAD_KTAG_CUSTOMER_FAIL",
      "LOAD_KTAG_STAFF_FAIL",
      "LOAD_KTAG_CONEXION_OK",
      "LOAD_KTAG_CONEXION_FAIL",
      "LOAD_MONEY_PERHOUR_FAIL",

      //CONFIG STATION
      "LOAD_CONFIG_STATION_OK",
      "STORE_CONFIG_STATION_OK",
      "UPDATE_CONFIG_STATION_OK",

      "LOAD_CONFIG_STATION_FAIL",
      "STORE_CONFIG_STATION_FAIL",
      "UPDATE_CONFIG_STATION_FAIL",
       //---- ZONE ACCESS OK----
       //LOG ACCESS

      "LOAD_LOGACCESS_OK",
      "LOAD_LOGACCESSS_OK",
      "LOAD_LOGACCESSS_ACTIVE_OK",
      "LOAD_LOGACCESSS_BY_EVENT_OK",
      "STORE_LOGACCESS_OK",
      "UPDATE_LOGACCESS_OK",
      "CHANGUE_LOGACCESSS_OK",
      "DELETE_LOGACCESS_OK",
      //MEMBERSHIPS OK
      "LOAD_MEMBERSHIP_OK",
      "LOAD_MEMBERSHIPS_OK",
      "LOAD_MEMBERSHIPS_ACTIVE_OK",
      "STORE_MEMBERSHIP_OK",
      "UPDATE_MEMBERSHIP_OK",
      "CHANGUE_MEMBERSHIPS_OK",
      "DELETE_MEMBERSHIP_OK",
      //ZONEACCESS_OK
      "LOAD_ZONEACCESSS_OK",
      "LOAD_ZONEACCESS_OK",
      "LOAD_ZONEACCESS_ACTIVE_OK",
      "LOAD_ZONEACCESSS_BY_EVENT_OK",
      "STORE_ZONEACCESS_OK",
      "UPDATE_ZONEACCESS_OK",
      "CHANGUE_ZONEACCESS_OK",
       //ZONESTATION_OK
       "LOAD_ZONESTATIONS_OK",
       "LOAD_ZONESTATION_OK",
       "LOAD_ZONESTATION_ACTIVE_OK",
       "STORE_ZONESTATION_OK",
       "UPDATE_ZONESTATION_OK",
       "CHANGUE_ZONESTATION_OK",
       "DELETE_ZONESTATION_OK",
      //ZONEEVENT_OK
      "LOAD_ZONEEVENT_OK",
      "LOAD_ZONEEVENT_ACTIVE_OK",
      "LOAD_ZONEEVENTS_BY_EVENT_OK",
      "LOAD_MEMBERSHIPS_BY_EVENT_OK",
      "STORE_ZONEEVENT_OK",
      "UPDATE_ZONEEVENT_OK",
      "DELETE_ZONEEVENT_OK",
      "CHANGUE_ZONEEVENT_OK",
      //ACCESS LOG_OK
      "LOAD_ACCESS_LOG_OK",
       //---- ZONE ACCESS FAIL ----
       "LOAD_LOGACCESS_FAIL",
      "LOAD_LOGACCESSS_FAIL",
      "LOAD_LOGACCESSS_ACTIVE_FAIL",
      "LOAD_LOGACCESSS_BY_EVENT_FAIL",
        //---- ZONE STATION FAIL ----
        "LOAD_LOGSTATION_FAIL",
        "LOAD_LOGSTATIONS_FAIL",
        "LOAD_LOGSTATIONS_ACTIVE_FAIL",
        "LOAD_LOGSTATIONS_BY_EVENT_FAIL",
      //MEMBERSHIPS_FAIL
      "LOAD_MEMBERSHIP_FAIL",
      "LOAD_MEMBERSHIPS_FAIL",
      "LOAD_MEMBERSHIPS_ACTIVE_FAIL",
      "STORE_MEMBERSHIP_FAIL",
      "UPDATE_MEMBERSHIP_FAIL",
      "CHANGUE_MEMBERSHIPS_FAIL",
      "DELETE_MEMBERSHIP_FAIL",
      //ZONEACCESS_FAIL
      "LOAD_ZONEACCESS_FAIL",
      "LOAD_ZONEACCESSS_FAIL",
      "LOAD_ZONEACCESS_ACTIVE_FAIL",
      "LOAD_ZONEACCESSS_BY_EVENT_FAIL",
      "STORE_ZONEACCESS_FAIL",
      "UPDATE_ZONEACCESS_FAIL",
      "CHANGUE_ZONEACCESS_FAIL",
      //ZONESTATION_FAIL
       "LOAD_ZONESTATIONS_FAIL",
       "LOAD_ZONESTATION_FAIL",
       "LOAD_ZONESTATION_FAIL_OK",
       "STORE_ZONESTATION_FAIL",
       "UPDATE_ZONESTATION_FAIL",
       "CHANGUE_ZONESTATION_FAIL",
       "DELETE_ZONESTATION_FAIL",
      //ZONEEVENT
      "LOAD_ZONEEVENT_FAIL",
      "LOAD_ZONEEVENT_ACTIVE_FAIL",
      "LOAD_ZONEEVENTS_BY_EVENT_FAIL",
      "LOAD_MEMBERSHIPS_BY_EVENT_FAIL",
      "STORE_ZONEEVENT_FAIL",
      "UPDATE_ZONEEVENT_FAIL",
      "DELETE_ZONEEVENT_OK",
      "CHANGUE_ZONEEVENT_FAIL",


      //ZONESTATION_OK
      "LOAD_WORKTEAMS_OK",
      "LOAD_WORKTEAM_OK",
      "LOAD_WORKTEAM_ACTIVE_OK",
      "STORE_WORKTEAM_OK",
      "UPDATE_WORKTEAM_OK",
      "CHANGUE_WORKTEAM_OK",
      "DELETE_WORKTEAM_OK",
      //WORKTEAM
      "LOAD_WORKTEAM_FAIL",
      "LOAD_WORKTEAM_ACTIVE_FAIL",
      "LOAD_WORKTEAMS_BY_EVENT_FAIL",
      "LOAD_MEMBERSHIPS_BY_EVENT_FAIL",
      "STORE_WORKTEAM_FAIL",
      "UPDATE_WORKTEAM_FAIL",
      "DELETE_WORKTEAM_OK",
      "CHANGUE_WORKTEAM_FAIL",
      //ACCESS LOG_FAIL
      "LOAD_ACCESS_LOG_FAIL"
    ],
    state => {
      state.isLoading = false;
    }
  )
};

export default {
  state,
  actions,
  mutations
};
