import Vue from 'vue'
import Vuex from 'vuex'
import VuexI18n from 'vuex-i18n' // load vuex i18n module
import VuexPersist from 'vuex-persist'
import Cookies from 'js-cookie'
import createLogger from 'vuex/dist/logger'

// modules
import general from './modules/general'
import app from './modules/app'
import auth from './modules/auth'
import team from './modules/team'
import user from './modules/user'
import product from './modules/product'
import device from './modules/device'
import toast from './modules/toast'
import * as getters from './getters'
// PAGES
import station from './modules/station'
import productstation from './modules/productstation'
import event from './modules/event'
import company from './modules/company'
import staff from './modules/staff'
import customer from './modules/customer'
import currency from './modules/currency'

//MASTERS
import topuptransaction from './modules/topuptransaction'
import refundtransaction from './modules/refundtransaction'
import typecompany from './modules/typecompany'
import typecredit from './modules/typecredit'
import typecustomer from './modules/typecustomer'
import typeevent from './modules/typeevent'
import typektag from './modules/typektag'
import typepay from './modules/typepay'
import typeproduct from './modules/typeproduct'
import typestaff from './modules/typestaff'
import typestation from './modules/typestation'
import ktag from './modules/ktag'
import audit from './modules/audit'
import analytic from './modules/analytic'
//ACCESS
import logaccess from './modules/logaccess'
import membership from './modules/membership'
import zoneaccess from './modules/zoneaccess'
import zoneevent from './modules/zoneevent'
import zonestation from './modules/zonestation'
import ubication from './modules/ubication'
import day from './modules/day'
import workteam from './modules/workteam'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

const vuexCookie = new VuexPersist({
    key:          'store',
    restoreState: (key, storage) => Cookies.getJSON(key),
    saveState:    (key, state, storage) => Cookies.set(key, state, {
        expires: 365
    }),
    modules:      ['auth', 'route'], // only save user module
    // filter: (mutation) => (mutation.type == 'logIn' || mutation.type == 'logOut')
})

const store = new Vuex.Store({
    strict:  debug,
    plugins: debug ? [createLogger(), vuexCookie.plugin] : [vuexCookie.plugin],
    getters,
    modules: {
        general,
        app,
        auth,
        toast,
        station,
        productstation,
        topuptransaction,
        refundtransaction,
        team,
        user,
        product,
        device,
        event,
        company,
        staff,
        customer,
        currency,
         //MASTER
        typecompany,
        typecredit,
        typecustomer,
        typeevent,
        typektag,
        typepay,
        typeproduct,
        typestaff,
        typestation,
        ktag,
        audit,
        analytic,
        //ACCESS
        logaccess,
        membership,
        zoneaccess,
        zoneevent,
        zonestation,
        ubication,
        day,
        workteam


    }
})

Vue.use(VuexI18n.plugin, store)

export default store
