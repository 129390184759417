import axios from "axios";
import * as Config from "../../config";
import Cookies from "js-cookie";

const state = {
  workteam: {},
  workteams: {}
};

const actions = {
  loadWorkteams({ commit, dispatch }, form) {
    commit("LOAD_WORKTEAM");

    return new Promise((resolve, reject) => {
      axios
      .get(Config.apiPath + "workteam/all",{params:form})
      .then(response => {
        if (response.data.code !== 500) {
          commit("LOAD_WORKTEAM_OK", response.data.data);
          resolve(response.data.data);
        } else {
          commit("LOAD_WORKTEAM_FAIL");
          reject();
        }
      })
      .catch(error => {
        commit("LOAD_WORKTEAM_FAIL");
        reject();
      });
    });
  },
  storeWorkteam({ commit, dispatch }, form) {
    commit("STORE_WORKTEAM");

    return new Promise((resolve, reject) => {
      axios
      .post(Config.apiPath + "workteam/save", form)
      .then(response => {
        commit("STORE_WORKTEAM_OK");
        resolve(response.data);
      })
      .catch(error => {
        commit("STORE_TWORKTEAM_FAIL");
        reject(error.response.data);
      });
    });
  },
  updateWorkteam({ commit, dispatch }, form) {
    commit("UPDATE_WORKTEAM");

    return new Promise((resolve, reject) => {
      axios
      .put(Config.apiPath + "workteam/update/" + form.id, form)
      .then(response => {

        commit("UPDATE_WORKTEAM_OK");
        resolve(response.data);
      })
      .catch(error => {
        commit("UPDATE_WORKTEAM_FAIL");
        reject(error.response.data);
      });
    });
  },
  updatePasswordAdminWorkteam({ commit, dispatch }, form) {
    commit("UPDATE_WORKTEAM_PASSWORD_ADMIN");

    return new Promise((resolve, reject) => {
      axios
      .put(Config.apiPath + "workteam/update_admin_password/" + form.id, form)
      .then(response => {
        commit("UPDATE_WORKTEAM_PASSWORD_ADMIN_OK");
        resolve(response.data);
      })
      .catch(error => {
        commit("UPDATE_WORKTEAM_PASSWORD_ADMIN_FAIL");
        reject(error.response.data);
      });
    });
  },
  deleteWorkteam({ commit, dispatch }, form) {
    commit("DELETE_WORKTEAM");

    return new Promise((resolve, reject) => {
      axios
      .delete(Config.apiPath + "workteam/" + form.id, form)
      .then(response => {
        commit("DELETE_WORKTEAM_OK");
        resolve(response.data);
      })
      .catch(error => {
        commit("DELETE_WORKTEAM_FAIL");
        reject(error.response.data);
      });
    });
  },
  loadWorkteam({ commit, dispatch }, form) {
    commit("LOAD_WORKTEAM");

    return new Promise((resolve, reject) => {
      axios
      .get(Config.apiPath + "workteam/" + form, { form })
      .then(response => {
        if (response.data.code !== 500) {
          commit("LOAD_WORKTEAM_OK", response.data.data);
          resolve(response.data.data);
        } else {
          commit("LOAD_WORKTEAM_FAIL");
          reject();
        }
      })
      .catch(error => {
        commit("LOAD_TYPE_WORKTEAM_FAIL");
        reject();
      });
    });
  },
  loadRolesWorkTeam({ commit, dispatch },form) {
    //commit('LOAD_WORKTEAM')

    return new Promise((resolve, reject) => {
      axios
      .get(Config.apiPath + "rol/workteam/all",{params:form})
      .then(response => {
        if (response.data.code !== 500) {
            //commit('LOAD_WORKTEAM_OK', response.data.data)
            resolve(response.data.data);
          } else {
            //commit('LOAD_WORKTEAM_FAIL')
            reject();
          }
        })
      .catch(error => {
          //commit('LOAD_TYPE_WORKTEAM_FAIL')
          reject();
        });
    });
  },
  updateName({ commit, dispatch }, form) {
    commit("UPDATE_WORKTEAM_NAME");

    return new Promise((resolve, reject) => {
      axios
      .put(Config.apiPath + "workteam/update_name/" + form.id, form)
      .then(response => {

        commit("UPDATE_WORKTEAM_NAME_OK");
        resolve(response.data);
      })
      .catch(error => {
        commit("UPDATE_WORKTEAM_NAME_FAIL");
        reject(error.response.data);
      });
    });
  },
  updatePassword({ commit, dispatch }, form) {
    commit("UPDATE_WORKTEAM_PASSWORD");

    return new Promise((resolve, reject) => {
      axios.put(Config.apiPath + "workteam/update_password/" + form.id, form)
      .then(response => {
        commit("UPDATE_WORKTEAM_PASSWORD_OK");
        resolve(response.data)
      })
      .catch(error => {
        commit("UPDATE_WORKTEAM_PASSWORD_FAIL");
        reject(error.response.data)
      });
    });
  },
  activeChangeWorkteams({commit, dispatch}, id) {

    return new Promise((resolve, reject) => {
      axios.post(Config.apiPath + 'workteam/change/active/' + id)
      .then(
        response => {
          resolve(response.data)
        })
      .catch(error => {
        reject(error.data)
      })
    })
  }

};

const mutations = {
  UPDATE_WORKTEAM_OK(state, workteam) {
    state.workteam = workteam;
  },
  UPDATE_WORKTEAM_NAME_OK(state, workteam) {
    state.workteam = workteam;
  },  
  WORKTEAM(state, workteam) {
    state.workteam = workteam;
  },
  LOAD_WORKTEAM_OK(state, workteams) {
    state.workteams = workteams;
  },
  LOAD_WORKTEAMS_OK(state, workteam) {
    state.workteam = workteam;
  }
};

export default {
  state,
  actions,
  mutations
};
