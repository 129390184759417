<template>
    <div id="app" class="app">
        <toast></toast>
        <loader></loader>
        <transition name="fade" mode="out-in">
            <router-view></router-view>
        </transition>
    </div>


</template>

<script>
    import toast from './components/partials/Toast.vue'
    import loader from './components/partials/Loading.vue'

    export default {
        name:       'app',
        components: {
            loader,
            toast

        }
    }
</script>
<style lang="scss">
    @import "sass/main";

    body {
        height: 100%;
        #app {
            height: 100%;
        }
    }
</style>

