import axios from 'axios'
import * as Config from '../../config'
import Cookies from 'js-cookie'

const state = {
  dayevent: {},
  dayevents: {}
}

const actions = {

    loadDayEvents ({ commit, dispatch }) {
    commit('LOAD_DAYS')

    return new Promise((resolve, reject) => {
      axios.get(Config.apiPath + 'dayevent/active')
      .then(
        response => {
          if (response.data.code !== 500) {
            commit('LOAD_DAYS_OK', response.data.data)
            resolve(response.data.data)
          } else {
            commit('LOAD_DAYS_FAIL')
            reject()
          }
        })
      .catch(error => {
        commit('LOAD_DAYS_FAIL')
        reject()
      })
    })
  },
  storeDayEvent ({ commit, dispatch }, form) {
    commit('STORE_DAY')

    return new Promise((resolve, reject) => {
      axios.post(Config.apiPath + 'dayevent', form)
      .then(
        response => {
          commit('STORE_DAY_OK')
          resolve(response.data)
        })
      .catch(error => {
        commit('STORE_DAY_FAIL')
        reject(error.response.data)
      })
    })
  },
  updateDayEvent ({ commit, dispatch }, form) {
    commit('UPDATE_DAY')

    return new Promise((resolve, reject) => {
      axios.put(Config.apiPath + 'dayevent/' + form.id, form)
      .then(
        response => {
          commit('UPDATE_DAY_OK')
          resolve(response.data)
        })
      .catch(error => {
        commit('UPDATE_DAY_FAIL')
        reject(error.response.data)
      })
    })
  },
  deleteDayEvent ({ commit, dispatch }, form) {
    commit('DELETE_DAY')

    return new Promise((resolve, reject) => {
      axios.delete(Config.apiPath + 'dayevent/delete/' + form.id, form)
      .then(
        response => {
          commit('DELETE_DAY_OK')
          resolve(response.data)
        })
      .catch(error => {
        commit('DELETE_DAY_FAIL')
        reject(error.response.data)
      })
    })
  },
  loadDayEvent ({ commit, dispatch }, form) {
    commit('LOAD_DAY')

    return new Promise((resolve, reject) => {
      axios.get(Config.apiPath + 'dayevent/' + form, { form })
      .then(
        response => {
          if (response.data.code !== 500) {
            commit('LOAD_DAY_OK', response.data.data)
            resolve(response.data.data)
          } else {
            commit('LOAD_DAY_FAIL')
            reject()
          }
        })
      .catch(error => {
        commit('LOAD_DAY_FAIL')
        reject()
      })
    })
  },
  loadDayEventByEvent ({ commit, dispatch }, idevent) {
    commit('LOAD_DAYS_BY_EVENT')

    return new Promise((resolve, reject) => {
      axios.get(Config.apiPath + 'dayevent/filterby/id_event/' + idevent)
      .then(
        response => {
          if (response.data.code !== 500) {
            commit('LOAD_DAYS_BY_EVENT_OK', response.data.data)
            resolve(response.data.data)
          } else {
            commit('LOAD_DAYS_BY_EVENT_FAIL')
            reject()
          }
        })
      .catch(error => {
        commit('LOAD_DAYS_BY_EVENT_FAIL')
        reject()
      })
    })
  },
  loadTypeDayEvent ({ commit, dispatch }) {
    commit('LOAD_TYPE_DAY')

    return new Promise((resolve, reject) => {
      axios.get(Config.apiPath + 'type/dayevent/active')
      .then(
        response => {
          if (response.data.code !== 500) {
            commit('LOAD_TYPE_DAY_OK')
            resolve(response.data.data)
          } else {
            commit('LOAD_TYPE_DAY_FAIL')
            reject()
          }
        })
      .catch(error => {
        commit('LOAD_TYPE_DAY_FAIL')
        reject()
      })
    })
  },
  activeChangeDayEvent({commit, dispatch}, id) {
    commit('CHANGUE_DAY')
    return new Promise((resolve, reject) => {
      axios.post(Config.apiPath + 'dayevent/change/active/' + id)
      .then(
        response => {
          commit('CHANGUE_DAY_OK')
          resolve(response.data)
        })
      .catch(error => {
        commit('CHANGUE_DAY_FAIL')
        reject(error.response.data)
      })
    })
  }

}

const mutations = {
  LOAD_DAY_OK (state, dayevents) {
    state.dayevent = dayevents
  },
  LOAD_DAYS_OK (state, dayevents) {
    state.dayevents = dayevents
  },
  LOAD_DAYS_BY_COMPANY_OK (state, dayevents) {
    state.dayevents = dayevents
  }

}

export default {
  state,
  actions,
  mutations
}
