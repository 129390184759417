import axios from 'axios'
import * as Config from '../../config'
import Cookies from 'js-cookie'

const state = {
    typeevent: {},
    events:    {},
    event:     Cookies.getJSON('event') || {},
    manage:    Cookies.get('manage') === 'true' ? true : false || false,
}

const actions = {

    loadTypeEvents({commit, dispatch}, params) {
        commit('LOAD_TYPE_EVENTS')

        return new Promise((resolve, reject) => {
            axios.get(Config.apiPath + 'type/event', {params})
                .then(
                    response => {
                        if (response.data.code !== 500) {
                            commit('LOAD_TYPE_EVENTS_OK', response.data.data)
                            resolve(response.data.data)
                        } else {
                            commit('LOAD_TYPE_EVENTS_FAIL')
                            reject(response.data)
                        }
                    })
                .catch(error => {
                    commit('LOAD_TYPE_EVENTS_FAIL')
                    reject(error.data)
                })
        })
    },
    loadNameEvent({commit, dispatch}, code) {
        commit('LOAD_TYPE_EVENTS')

        return new Promise((resolve, reject) => {
            axios.get(Config.apiPath + 'event/name/'+code)
                .then(
                    response => {
                        if (response.data.code !== 500) {
                            commit('LOAD_NAME_EVENT_OK', response.data.data)
                            resolve(response.data.data)
                        } else {
                            commit('LOAD_NAME_EVENT_FAIL')
                            reject(response.data)
                        }
                    })
                .catch(error => {
                    commit('LOAD_NAME_EVENT_FAIL')
                    reject(error.data)
                })
        })
    },
    loadEvents({commit, dispatch}, form) {
        commit('LOAD_EVENTS')

        return new Promise((resolve, reject) => {
            axios.get(Config.apiPath + 'event/status/'+form.status+'/company/' + form.company)
                .then(
                    response => {
                        if (response.data.code !== 500) {
                            commit('LOAD_EVENTS_OK', response.data.data)
                            resolve(response.data.data)
                        } else {
                            commit('LOAD_EVENTS_FAIL')
                            reject(response.data)
                        }
                    })
                .catch(error => {
                    commit('LOAD_EVENTS_FAIL')
                    reject(error.data)
                })
        })
    },
    storeEvent({commit, dispatch}, form) {
        commit('STORE_EVENT')

        return new Promise((resolve, reject) => {
            axios.post(Config.apiPath + 'event', form)
                .then(
                    response => {
                        commit('STORE_EVENT_OK')
                        resolve(response.data)
                    })
                .catch(error => {
                    commit('STORE_EVENT_FAIL')
                    reject(error.data)
                })
        })
    },
    updateEvent({commit, dispatch}, form) {
        commit('UPDATE_EVENT')

        return new Promise((resolve, reject) => {
            axios.put(Config.apiPath + 'event/' + form.id, form)
                .then(
                    response => {

                        commit('UPDATE_EVENT_OK', response.data.data)
                        resolve(response.data)
                    })
                .catch(error => {
                    commit('UPDATE_EVENT_FAIL')
                    reject(error.data)
                })
        })
    },
    updatePasswordEvent({commit, dispatch}, form) {
        commit('UPDATE_PASSWORD_EVENT')

        return new Promise((resolve, reject) => {
            axios.put(Config.apiPath + 'event/password/' + form.id, form)
                .then(
                    response => {

                        commit('UPDATE_PASSWORD_EVENT_OK', response.data.data)
                        resolve(response.data)
                    })
                .catch(error => {
                    commit('UPDATE_PASSWORD_EVENT_FAIL')
                    reject(error.data)
                })
        })
    },
    updateStatusEvent({commit, dispatch}, form) {
        commit('UPDATE_STATUS_EVENT')

        return new Promise((resolve, reject) => {
            axios.put(Config.apiPath + 'event/status/' + form.id, form)
                .then(
                    response => {

                        commit('UPDATE_STATUS_EVENT_OK', response.data.data)
                        resolve(response.data)
                    })
                .catch(error => {
                    commit('UPDATE_STATUS_EVENT_FAIL')
                    reject(error.data)
                })
        })
    },
    activeChangeEvent({commit, dispatch}, id) {

    return new Promise((resolve, reject) => {
        axios.post(Config.apiPath + 'event/change/active/' + id)
            .then(
                response => {
                    resolve(response.data)
                })
            .catch(error => {
                reject(error.data)
            })
    })
    },
    deleteEvent({commit, dispatch}, form) {
        commit('DELETE_EVENT')

        return new Promise((resolve, reject) => {
            axios.delete(Config.apiPath + 'event/' + form.id, form)
                .then(
                    response => {

                        commit('DELETE_EVENT_OK', response.data.data)
                        resolve()
                    })
                .catch(error => {
                    commit('DELETE_EVENT_FAIL')
                    reject(error.response.data)
                })
        })
    },
    loadEvent({commit, rootState}, form) {
        commit('LOAD_EVENT')
        return new Promise((resolve, reject) => {
            axios.get(Config.apiPath + 'event/' + form.id, {form})
                .then(
                    response => {
                        if (response.data.code !== 500) {
                            commit('LOAD_EVENT_OK', response.data.data)
                            resolve(response.data)
                        } else {
                            commit('LOAD_EVENT_FAIL')
                            reject(response.data)
                        }
                    })
                .catch(error => {
                    commit('LOAD_EVENT_FAIL')
                    reject(error.data)
                })
        })
    },
    loadEventConfig({commit, rootState}, form) {
        commit('LOAD_EVENT')
        return new Promise((resolve, reject) => {
            axios.get(Config.apiPath + 'event/' + form.id, {form})
                .then(
                    response => {
                        if (response.data.code !== 500) {
                            commit('LOAD_EVENT_CONFIG_OK')
                            resolve(response.data.data)
                        } else {
                            commit('LOAD_EVENT_CONFIG_FAIL')
                            reject(response.data)
                        }
                    })
                .catch(error => {
                    commit('LOAD_EVENT_CONFIG_FAIL')
                    reject(error.data)
                })
        })
    },
    cleaEvent({commit, dispatch}, form) {
        commit('CLEAN_EVENT_OK')
    },
    loadTimezones({commit, dispatch}, params) {
        commit('LOAD_TIMEZONES')

        return new Promise((resolve, reject) => {
            axios.get(Config.apiPath + 'timezone', {params})
                .then(
                    response => {
                        if (response.data.code !== 500) {
                            commit('LOAD_TIMEZONES_OK', response.data.data)
                            resolve(response.data.data)
                        } else {
                            commit('LOAD_TIMEZONES_FAIL')
                            reject()
                        }
                    })
                .catch(error => {
                    commit('LOAD_TIMEZONES_FAIL')
                    reject()
                })
        })
    },
    activeChange({commit, dispatch}, id) {

        return new Promise((resolve, reject) => {
            axios.post(Config.apiPath + 'device_event/change/active/' + id)
                .then(
                    response => {
                        // commit('STORE_EVENT_OK')
                        resolve(response.data)
                    })
                .catch(error => {
                    // commit('STORE_EVENT_FAIL')
                    reject(error.data)
                })
        })
    }

}

const mutations = {
    LOAD_TYPE_EVENTS_OK(state, typeevent) {
        state.typeevent = typeevent
    },
    // STORE_EVENT_OK (state, event) {
    //   state.event = event
    //   state.event.push(event)
    // },
    // UPDATE_EVENT_OK (state, event) {
    //   state.event = event
    // },
    // DELETE_EVENT_OK (state, event) {
    //   state.event = event
    // },
     UPDATE_STATUS_EVENT_OK (state, event) {
       state.event = event
     },
    LOAD_EVENTS_OK(state, events) {
        // state.events = events
    },
    LOAD_NAME_EVENT_OK(state, events) {
         state.eventname = events
    },

    LOAD_EVENT_OK(state, event) {
        state.event  = event
        state.manage = true
        Cookies.set('event', event)
        Cookies.set('manage', true)
    },
    CLEAN_EVENT_OK(state, event) {
        state.event  = {}
        state.manage = false
        Cookies.set('event', {})
        Cookies.set('manage', false)
    },
    LOAD_TIMEZONES_OK(state, timezones) {
        // state.timezones = timezones
    }

}

export default {
    state,
    actions,
    mutations
}
