<template>
    <!--<loading :active.sync="isLoading" :can-cancel="true" :on-cancel="onCancel" :is-full-page="fullPage"></loading>-->
    <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
</template>

<script>
    import {mapState, mapActions} from 'vuex'
    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';
    export default {
        name:       'loader',
        components: {
            Loading
        },
        data() {
            return {
                fullPage: true
            }
        },

        computed: {
            ...mapState({
                isLoading: state => state.general.isLoading,
            })
        },

        methods: {
            ...mapActions([
                'stopLoading',
            ])
        }
    }
</script>

<style>
    .spinner {
        margin: 100px auto;
        width: 40px;
        height: 40px;
        position: relative;
    }
    
    .cube1, .cube2 {
        background-color: #333;
        width: 30px;
        height: 30px;
        position: absolute;
        top: 0;
        left: 0;
        -webkit-animation: sk-cubemove 1.8s infinite ease-in-out;
        animation: sk-cubemove 1.8s infinite ease-in-out;
    }
    
    .cube2 {
        -webkit-animation-delay: -0.9s;
        animation-delay: -0.9s;
    }
    
    @-webkit-keyframes sk-cubemove {
        25% {
            -webkit-transform: translateX(42px) rotate(-90deg) scale(0.5)
        }
        50% {
            -webkit-transform: translateX(42px) translateY(42px) rotate(-180deg)
        }
        75% {
            -webkit-transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5)
        }
        100% {
            -webkit-transform: rotate(-360deg)
        }
    }
    
    @keyframes sk-cubemove {
        25% {
            transform: translateX(42px) rotate(-90deg) scale(0.5);
            -webkit-transform: translateX(42px) rotate(-90deg) scale(0.5);
        }
        50% {
            transform: translateX(42px) translateY(42px) rotate(-179deg);
            -webkit-transform: translateX(42px) translateY(42px) rotate(-179deg);
        }
        50.1% {
            transform: translateX(42px) translateY(42px) rotate(-180deg);
            -webkit-transform: translateX(42px) translateY(42px) rotate(-180deg);
        }
        75% {
            transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5);
            -webkit-transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5);
        }
        100% {
            transform: rotate(-360deg);
            -webkit-transform: rotate(-360deg);
        }
    }
</style>
