import axios from 'axios'
import * as Config from '../../config'

const state = {
    currencies: {},
}

const actions = {

    loadCurrencies({commit, dispatch}, params) {
        commit('LOAD_CURRENCIES')

        return new Promise((resolve, reject) => {
            axios.get(Config.apiPath + 'currencies', {params})
                .then(
                    response => {
                        if (response.data.code !== 500) {
                            commit('LOAD_CURRENCIES_OK', response.data.data)
                            resolve(response.data.data)
                        } else {
                            commit('LOAD_CURRENCIES_FAIL')
                            reject(response.data)
                        }
                    })
                .catch(error => {
                    commit('LOAD_CURRENCIES_FAIL')
                    reject(error.data)
                })
        })
    }

}

const mutations = {
    LOAD_CURRENCIES_OK(state, currencies) {
        state.currencies = currencies
    },

}

export default {
    state,
    actions,
    mutations
}
