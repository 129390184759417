var url_domain = window.location.host;
var config_url = '';
var config_url3 = '';
if (url_domain == 'localhost:8080') {
    config_url = 'http://127.0.0.1:8000/1.0/';
  // config_url = 'https://apikashtest.myticktap.com/1.0/';

} else if (url_domain == 'admineventos.ticktaps.com') {
    config_url = 'https://apikashtest.myticktap.com/1.0/';
} else if (url_domain == 'admineventostest.ticktaps.com') {
    config_url = 'https://apikashtest.myticktap.com/1.0/';
}
else if (url_domain == 'cashless.ticktaps.com') {
    config_url = 'https://apikash2.myticktap.com/1.0/';
config_url3 = 'https://apicash.protaps.app/api/1.0/';	
}
console.log('Backend Conected: ' + config_url)
export const apiPath = config_url;
console.log('Backend Connected 3:' + config_url3)
export const apiPath3 = config_url3;
