export default {
  tableFields: [

    {
      name: 'name',
      sortField: 'name',
      title: 'Nombre',
      width: '20%'
    },
   /* {
      name: 'number',
      sortField: 'number',
      title: 'Num',
    //  width: '34%'
    },*/
    {
      name: 'ubication.name',
      sortField: 'location',
      title: 'Ubicación',
      //width: '34%'
    },
    {
      name: 'day_event.name',
      sortField: 'location',
      title: 'Día',
      //width: '34%'
    },
    {
      name: 'item',
      sortField: 'item',
      title: 'Productos',
      //width: '34%'
    },
    {
      name: 'type_station.name',
      title: 'Type Station',
      title: 'Tipo',
     // width: '14%'
    },
    {
      name: 'total',
      sortField: 'location',
      title: 'Ventas',
      //width: '34%'
    },
  /*  {
      name: 'sub_total_1',
      sortField: 'location',
      title: 'Virtuales',
      //width: '34%'
    },
    {
      name: 'sub_total_2',
      sortField: 'location',
      title: 'Standard',
      //width: '34%'
    },*/
    {
      name: 'order',
      sortField: 'order',
      title: 'Orden',
      //width: '34%'
    },
   /* {
      name:       '__slot:active',
      title:      'Active',
      titleClass: "center aligned",
      dataClass:  "center aligned",
    //  width:      "15%",
  },*/
  {
      name:       '__slot:actions',
      title:      'Acciones',
      titleClass: "center aligned",
      dataClass:  "center aligned",
      width:      "30%",
  }

  ],
  sortFunctions: {
    'name': function (item1, item2) {
      return item1 >= item2 ? 1 : -1
    },
    'location': function (item1, item2) {
      return item1 >= item2 ? 1 : -1
    }
  }
}
