/**
 * Base api path
 *
 * @type {string}
 */
const role_teams = {
    ADMIN:  1,
    COORDINATOR:   2,
    ASSISTANTS:  3,
    OWNER: 4,
    ALL: 5,
}

export default Object.freeze(role_teams)